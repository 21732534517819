import { makeStyles, Theme } from '@material-ui/core'
import SentimentDissatisfied from '@material-ui/icons/SentimentDissatisfied'
import { useReducer, useRef } from 'react'
import { useAudio } from 'react-use'

import Box from '@base/Box'
import Button from '@base/Button'
import Typography from '@base/Typography'
import { useTranslation } from '@src/i18n'
import getLogger from '@src/Logger'

import PlayButton from './audioOutputTest/PlayButton'

type Props = {
  onCancel: () => void
  onPassed: () => void
  onError: () => void
  onDataUpdate: (data: any) => void
}

type StepData = {
  playClicked: boolean
  playCount: number
  clickedTryAgain: boolean
  success: boolean
  error?: string
}

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
  button: {
    [breakpoints.up('sm')]: {
      minWidth: 220,
    },
  },
  sentimentIcon: {
    fontSize: 60,
    [breakpoints.up('md')]: {
      fontSize: 100,
    },
  },
}))

const AudioOutputTest = ({ onCancel, onPassed, onError, onDataUpdate }: Props) => {
  const { t } = useTranslation()
  const classes = useStyles({})
  const stepData = useRef<StepData>({
    playCount: 0,
    playClicked: false,
    clickedTryAgain: false,
    success: false,
  })
  const [audio, { paused }, { play, pause, seek }] = useAudio({
    loop: true,
    src: '/static/audio/ringing.mp3',
    onPlay: () => {
      getLogger().info(`AudioOutputTest – start playing`)
      stepData.current = {
        ...stepData.current,
        playCount: stepData.current.playCount + 1,
      }
      onDataUpdate(stepData.current)
    },
    onPause: () => {
      getLogger().info(`AudioOutputTest – stop playing`)
    },
  })
  const [{ hasIssue, playClicked }, setState] = useReducer((s, a) => ({ ...s, ...a }), {
    hasIssue: false,
    playClicked: false,
  })

  const handleOutput = () => {
    handleStop()
    setState({ hasIssue: false })
    stepData.current = {
      ...stepData.current,
      success: true,
    }
    onDataUpdate(stepData.current)
    onPassed()
  }

  const handleNoOutput = () => {
    handleStop()
    setState({ hasIssue: true })
    stepData.current = {
      ...stepData.current,
      error: 'NO_OUTPUT',
    }
    onDataUpdate(stepData.current)
    onError()
  }

  const handleToggle = () => {
    if (paused) {
      handlePlay()
      stepData.current = {
        ...stepData.current,
        playClicked: true,
      }
      onDataUpdate(stepData.current)
      setState({ playClicked: true })
    } else {
      handleStop()
    }
  }

  const handlePlay = () => {
    const $play = play()
    $play && $play.catch(err => getLogger().warn({ err }, `AudioOutputTest – not played`))
  }

  const handleStop = () => {
    pause()
    seek(0)
  }

  const handleTryAgain = () => {
    stepData.current = {
      ...stepData.current,
      clickedTryAgain: true,
    }
    onDataUpdate(stepData.current)
    setState({ hasIssue: false, playClicked: false })
  }

  return (
    <Box textAlign='center'>
      {!hasIssue ? (
        <>
          <Box mb={2}>
            <PlayButton play={!paused} onClick={handleToggle} />
          </Box>
          {playClicked ? (
            <>
              <Box mb={3}>
                <Typography align='center' variant='body1' weight={600}>
                  {t('permissionTest.audioOutputTest.subtitle')}
                </Typography>
              </Box>
              <Box mb={2}>
                <Button
                  className={classes.button}
                  color='primary'
                  onClick={handleOutput}
                  size='small'
                  variant='contained'
                >
                  {t('permissionTest.audioOutputTest.outputBtn')}
                </Button>
              </Box>
              <Button
                className={classes.button}
                color='inherit'
                onClick={handleNoOutput}
                size='small'
                variant='contained'
              >
                {t('permissionTest.audioOutputTest.noOutputBtn')}
              </Button>
            </>
          ) : (
            <Button className={classes.button} color='primary' onClick={handleToggle} size='small' variant='contained'>
              {t('permissionTest.audioOutputTest.playBtn')}
            </Button>
          )}
        </>
      ) : (
        <>
          <SentimentDissatisfied className={classes.sentimentIcon} />
          <Box textAlign='left' mb={2}>
            <Typography>{t('permissionTest.audioOutputTest.help.instructionsTitle')}</Typography>
            <Typography component='ol'>
              {t<any, string[]>('permissionTest.audioOutputTest.help.instructions', {
                defaultValue: [],
                returnObjects: true,
              }).map((instruction, i) => (
                <li key={i}>{instruction}</li>
              ))}
            </Typography>
          </Box>
          <Box mb={2}>
            <Button
              className={classes.button}
              color='primary'
              onClick={handleTryAgain}
              size='small'
              variant='contained'
            >
              {t('permissionTest.audioOutputTest.replayBtn')}
            </Button>
          </Box>
          <Button className={classes.button} color='inherit' onClick={onCancel} size='small' variant='contained'>
            {t('permissionTest.audioOutputTest.cancelBtn')}
          </Button>
        </>
      )}
      {audio}
    </Box>
  )
}

export default AudioOutputTest
