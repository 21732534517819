import { format } from 'date-fns'

import Box from '@base/Box'
import Typography from '@base/Typography'
import { ScheduledCall } from '@gql/__generated__'
import { useTranslation } from '@src/i18n'
import useDateFnsLocale from '@hooks/useDateFnsLocale'
import CompanyCoverage from '@components/common/company/Coverage'

import Duration from '../../../scheduling/Duration'
import { formatLocalInterval } from '@helpers/dateTime'
import Loading from '@base/Loading'

type Props = {
  duration: number
  durationOption: string
  loading: boolean
  tutorName: string
  setDuration: (duration: number) => void
  scheduledCall: Pick<ScheduledCall, 'start'> | null
  scheduledCallId: string | null
}

const OpenConversation = ({
  duration,
  durationOption,
  loading,
  scheduledCall,
  scheduledCallId,
  setDuration,
  tutorName,
}: Props) => {
  const { i18n, t } = useTranslation()
  const locale = useDateFnsLocale()
  const showScheduleCall = (scheduledCallId && loading) || scheduledCall

  return (
    <>
      {showScheduleCall && (
        <>
          <Box mb={2}>
            <Typography variant='body1'>{t('callInvite.modalSteps.Details.openConversation')}</Typography>
          </Box>
          {loading && <Loading BoxProps={{ alignItems: 'center', flexGrow: 1 }} />}
          {!loading && (
            <Box flexGrow={1} width='100%'>
              <Box display='flex' justifyContent='space-between'>
                <Typography variant='body1' align='justify'>
                  {t('callInvite.modalSteps.Details.scheduledCall.title')}:
                </Typography>
                <Typography variant='body1' align='justify' weight={600}>
                  {format(new Date(scheduledCall.start), 'HH:mm', { locale })}
                </Typography>
              </Box>
              <Box width='100%' display='flex' justifyContent='space-between' mb={2}>
                <Typography variant='body1' align='justify'>
                  {t('callInvite.modalSteps.Summary.length')}:
                </Typography>
                <Typography variant='body1' align='justify' weight={600}>
                  {formatLocalInterval(i18n, duration)}
                </Typography>
              </Box>
            </Box>
          )}
        </>
      )}
      {!showScheduleCall && (
        <>
          <Typography variant='body1'>{t('callInvite.modalSteps.Details.info', { name: tutorName })}</Typography>
          <Box pt={3} pb={2.5} maxWidth={290} mx='auto'>
            <Duration duration={duration} onSetDuration={setDuration} />
          </Box>
          {durationOption && (
            <Box flexGrow={1}>
              <Typography variant='body1' weight={600} color='textSecondary'>
                {durationOption}
              </Typography>
              <CompanyCoverage />
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default OpenConversation
