import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { getMediaConstraints, getUserMedia, MediaStreamError } from '@lib/media/MediaStream'
import { isVideoCallSupported } from '@helpers/browser'
import { removeItem, getItemTtl, setItemTtl } from '@helpers/localStorage'
import analytics from '@src/Analytics'
import getLogger from '@src/Logger'
import { PermissionsState } from '@store/modules/calling/Types'
import { snackbarOperations } from '@store/modules/Snackbar'
import { useTranslation } from '@src/i18n'

import StepperDialog from './permissionTest/StepperDialog'
import NotSupportedDialog from './permissionTest/NotSupportedDialog'
import NotGrantedDialog from './permissionTest/NotGrantedDialog'

type Props = {
  onClose: () => void
  onPassed: () => void
  showOnGranted?: boolean
}

export const LOCAL_STORAGE_KEY = 'permissionTestPassed'

type LocalStorageCheck = boolean

const PermissionTest = ({ onClose, onPassed, showOnGranted }: Props) => {
  const { t } = useTranslation()
  const logger = getLogger()
  const [permissions, setPermissions] = useState<PermissionsState>('unknown')
  const [error, setError] = useState<MediaStreamError>(null)
  const [permissionsPassed, setPermissionsPassed] = useState<boolean>(null)
  const [supported, setSupported] = useState<boolean>(null)
  const [audio, setAudio] = useState<MediaStream>(null)
  const [video, setVideo] = useState<MediaStream>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    setPermissionsPassed(!!getItemTtl<LocalStorageCheck>(LOCAL_STORAGE_KEY))
    setSupported(isVideoCallSupported())
  }, [])

  useEffect(() => {
    if (!supported) {
      return
    }

    const checkPromise = checkPermissions()
    return () => {
      checkPromise
        .then(mediaStream => {
          mediaStream && stopMediaStream(mediaStream)
        })
        .catch(err => getLogger().error({ err }, 'Error when stopping MediaStream'))
    }
  }, [supported])

  const stopMediaStream = (mediaStream: MediaStream) => {
    mediaStream.getTracks().forEach(track => {
      track.stop()
    })
  }

  const checkPermissions = async (): Promise<MediaStream | null> => {
    let waiting
    try {
      analytics.pushEvent('permission-test-started')
      // User room default resolution
      const constraints = getMediaConstraints({ width: 500, height: 500 })
      waiting = setTimeout(() => setPermissions('waiting'), 400) // wait a little for permissions to resolve
      logger.info({ obj: { constraints } }, 'Permission request occurred in permission test')
      const result = await getUserMedia(constraints)
      clearTimeout(waiting)
      setPermissions('granted')
      const mediaStream = result.stream
      logger.info({ obj: { constraints: result.constraints } }, 'Permission granted in permission test')

      // Do not continue with test when it already passed before and `showOnGranted` option is disabled
      if (!!getItemTtl(LOCAL_STORAGE_KEY) && !showOnGranted) {
        stopMediaStream(mediaStream)
        analytics.pushEvent('permission-test-success')
        logger.info({ obj: { constraints: result.constraints } }, 'Permission test already passed.')
        onPassed && onPassed()
        return null
      }

      setVideo(new MediaStream(mediaStream.getVideoTracks()))
      setAudio(new MediaStream(mediaStream.getAudioTracks()))
      return mediaStream
    } catch (error) {
      clearTimeout(waiting)
      setPermissions('denied')
      setError(error.name)
      logger.warn(
        {
          err: error,
          obj: { constraints: error.constraints, name: error.name, message: error.message, error: error.toString() },
        },
        'Permission rejected in permission test',
      )
      handleError('PermissionRequest')
    }

    return null
  }

  const handleError = (stepId: string) => {
    removeItem(LOCAL_STORAGE_KEY)
    analytics.pushEvent('permission-test-failed', { stepId })
  }

  const handlePassed = () => {
    analytics.pushEvent('permission-test-success')
    logger.info('User successfully passed permission test')
    setItemTtl<LocalStorageCheck>(LOCAL_STORAGE_KEY, true, 3600)
    dispatch(snackbarOperations.open(t('permissionTest.passed.message'), 'success'))
    onPassed && onPassed()
  }

  // Browser doesn't support video calls. Do not continue with any tests.
  if (supported === false) {
    return <NotSupportedDialog key='notSupported' onClose={onClose} />
  }

  if (permissions === 'waiting' || permissions === 'denied') {
    return <NotGrantedDialog key={permissions} error={error} permissions={permissions} onClose={onClose} />
  }

  // On permission granted show dialog only when passed for the first time or when "showOnGranted" is set
  if (permissions === 'granted' && (!permissionsPassed || showOnGranted)) {
    return (
      <StepperDialog
        key={permissions}
        audio={audio}
        onClose={onClose}
        onError={handleError}
        onPassed={handlePassed}
        video={video}
      />
    )
  }

  return null
}

export default PermissionTest
