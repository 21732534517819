import { connect } from 'react-redux'
import React from 'react'
import Grid from '@material-ui/core/Grid'

import { useTranslation } from '@src/i18n'
import Typography from '@base/Typography'
import Box from '@base/Box'
import Button from '@base/Button'
import Container from '@base/Container'
import Link from '@base/Link'
import links from '@api/links'
import { userSelectors } from '@store/modules/User'
import CoverBanner from '@pages/index/CoverBanner'

const Cover = () => {
  const { t } = useTranslation()

  return (
    <Container
      component='section'
      // Fit content to lower viewport on mobile
      pt={{ xs: 2, md: 5 }}
      pb={6}
      textAlign={{ xs: 'center', md: 'left' }}
      innerProps={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1200,
        justifyContent: 'center',
        position: 'relative',
      }}
      position={'relative'}
    >
      <Grid container alignItems='center' justifyContent='space-between' spacing={2}>
        <Grid item xs={12} md={6}>
          <Box textAlign={{ xs: 'center', md: 'left' }}>
            <Box maxWidth={{ xs: 'none', md: 543 }}>
              <Typography variant='h2' component='h1'>
                {t('terapio:homepage.intro.title')}
              </Typography>
            </Box>
            <Box my={3} maxWidth={500} mx={{ xs: 'auto', md: 0 }} color='#665E52'>
              <Typography variant='subtitle2' color='inherit'>
                {t('terapio:homepage.intro.description')}
              </Typography>
            </Box>

            <Box display={{ xs: 'block', md: 'none' }} mx='auto' my={3}>
              <CoverBanner />
            </Box>
            <Grid container alignItems='center' justifyContent='space-between' spacing={3}>
              <Grid item xs={12} md={12}>
                <Box textAlign={{ xs: 'center', md: 'left' }}>
                  <Link fullWidth {...links.signup}>
                    <Button
                      color='primary'
                      variant='contained'
                      size='medium'
                      style={{ paddingLeft: '24px', paddingRight: '24px' }}
                    >
                      {t('terapio:homepage.intro.button')}
                    </Button>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box display={{ xs: 'none', md: 'flex' }} alignItems='center'>
            <CoverBanner />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

const mapStateToProps = state => {
  const userState = userSelectors.getSelf(state)

  return {
    logged: userSelectors.isLoggedIn(userState),
  }
}

const CoverMapped = connect(mapStateToProps, null)(Cover)

export default CoverMapped
