import { useTranslation, Trans } from '@src/i18n'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import Box from '@base/Box'
import Typography from '@base/Typography'
import cameraBlocked from '@static/icons/camera-blocked.svg'
import { MediaStreamError } from '@lib/media/MediaStream'
import { PermissionsState } from '@store/modules/calling/Types'

const useStyles = makeStyles({
  loader: {
    strokeLinecap: 'round',
  },
})

type Props = {
  // Render this component until permissions granted
  kind: Omit<PermissionsState, 'granted'>
  error: MediaStreamError
}

const NotGranted = ({ kind = 'unknown', error }: Props) => {
  const { t } = useTranslation()
  const classes = useStyles({})
  const theme: Theme = useTheme()

  return (
    <>
      {(kind === 'unknown' || kind === 'waiting') && (
        <Box position='relative' display='inline-block' mb={3.5} color={theme.palette.customCol.mercury}>
          <CircularProgress size={108} variant='static' value={100} color='inherit' />
          <Box position='absolute' top={0} left={0}>
            <CircularProgress
              size={108}
              classes={{
                svg: classes.loader,
              }}
            />
          </Box>
        </Box>
      )}
      <Typography variant='h5' weight={600}>
        {t([`permissionTest.${kind}.${error}.title`, `permissionTest.${kind}.title`])}
      </Typography>
      <Box mt={2.5} mb={1} mx='auto'>
        <Typography variant='body1'>
          <Trans
            i18nKey={[`permissionTest.${kind}.${error}.info`, `permissionTest.${kind}.info`]}
            components={[<img key='icon' src={cameraBlocked} alt={t('permissionTest.denied.imageTitle')} />]}
          />
        </Typography>
      </Box>
    </>
  )
}

export default NotGranted
