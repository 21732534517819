import { isSafari } from '@helpers/browser'
import getLogger from '@src/Logger'

class UserMediaError extends Error {
  constraints: Constraints
  origError: Error

  constructor(message: string, origError: Error, constraints: Constraints) {
    super(message)
    this.name = origError.name
    this.stack = origError.stack
    this.origError = origError
    this.constraints = constraints
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#Exceptions
export type MediaStreamError =
  | 'AbortError'
  | 'NotAllowedError'
  | 'NotFoundError'
  | 'NotReadableError'
  | 'OverconstrainedError'
  | 'SecurityError'
  | 'TypeError'

export const enumerateDevices = () => {
  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    getLogger().warn('EnumerateDevices() not supported.')
    return
  }

  return navigator.mediaDevices.enumerateDevices().then(devices => {
    getLogger().warn({ obj: { devices } }, 'List of available devices')
  })
}

export const logUserMediaSeparated = () =>
  Promise.all([getBaseMedia(getVideoContraints()), getBaseMedia(getAudioContraints())].map(p => p.catch(e => e))).then(
    results => {
      getLogger().warn({ obj: { video: results[0] } }, 'Video')
      getLogger().warn({ obj: { audio: results[1] } }, 'Audio')
    },
  )

/**
 * Perform getUserMedia() with constraints fallback
 */
export const getUserMedia = (constraints: Constraints): Promise<{ constraints: Constraints; stream: MediaStream }> => {
  return new Promise((resolve, reject) => {
    return getBaseMedia(constraints)
      .then(stream => resolve({ constraints, stream }))
      .catch(err => {
        getLogger().warn({ err, obj: { constraints } }, 'Permission denied, trying fallback...')
        // if constraints rejected try with basic constraints
        const basicConstraints = getBasicContraints()
        return getBaseMedia(basicConstraints)
          .then(stream => resolve({ constraints: basicConstraints, stream }))
          .catch(origError => {
            // show all available devices
            enumerateDevices().catch(error =>
              getLogger().warn({ err: error }, 'Error getting list of available devices'),
            )
            // check audio and video separately to see which one is denied
            logUserMediaSeparated().catch(error =>
              getLogger().warn({ err: error }, 'Error when logging list of user media'),
            )
            reject(new UserMediaError(origError.message, origError, basicConstraints))
          })
      })
  })
}

/**
 * Inspired by https://github.com/twilio/twilio-webrtc.js/blob/master/lib/getusermedia.js
 * Lib @twilio/webrtc is not TS-typed yet
 */
export function getBaseMedia(constraints: Constraints): Promise<MediaStream> {
  return new Promise(function getUserMediaPromise(resolve, reject) {
    _getUserMedia(constraints, resolve, reject)
  })
}

function _getUserMedia(constraints, onSuccess, onFailure) {
  if (typeof window !== 'undefined' && typeof navigator !== 'undefined') {
    if (typeof navigator.mediaDevices === 'object' && typeof navigator.mediaDevices.getUserMedia === 'function') {
      navigator.mediaDevices.getUserMedia(constraints).then(onSuccess, onFailure)
      return
    } else if (typeof navigator.webkitGetUserMedia === 'function') {
      navigator.webkitGetUserMedia(constraints, onSuccess, onFailure)
      return
    } else if (typeof navigator.mozGetUserMedia === 'function') {
      navigator.mozGetUserMedia(constraints, onSuccess, onFailure)
      return
    }
  }
  onFailure(new Error('getUserMedia is not supported'))
}

type Constraints = {
  audio?: boolean
  video?:
    | boolean
    | { width: { min: number; ideal: number }; height: { min: number; ideal: number }; facingMode?: string }
}

export const getMediaConstraints = ({ width, height, facingMode = true }) => {
  const safari = isSafari()

  const constraints: Constraints = {
    audio: true,
    video: safari
      ? true
      : {
          width: { min: 320, ideal: width },
          height: { min: 320, ideal: height },
        },
  }
  if (facingMode && typeof constraints.video === 'object') constraints.video.facingMode = 'user'
  return constraints
}

export const getBasicContraints = () => ({ audio: true, video: true })

export const getVideoContraints = () => ({ video: true })
export const getAudioContraints = () => ({ audio: true })
