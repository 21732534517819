import { format } from 'date-fns'
import { ShiftDatesAndSlotsQuery } from '@gql/__generated__'

export const getSlotsByLocalTimezone = (
  dates: ShiftDatesAndSlotsQuery['dates'] | null,
): Map<string, { tm: string; id: string }[]> => {
  const sortedMap = new Map()

  dates.forEach(dt => {
    dt.slots.forEach(slot => {
      const k = format(new Date(slot.start), 'y/MM/dd')
      const previous = sortedMap.get(k) || []
      sortedMap.set(k, [...previous, { tm: format(new Date(slot.start), 'HH:mm'), id: slot.shiftId }])
    })
  })

  return sortedMap
}
