import gql from 'graphql-tag'
import UserPackage from '@tutor/scheduling/selection/UserPackageFragment.api'

export default gql`
  query GetPackageLesson {
    userPackages: getPurchasedPackages {
      ...UserPackage
    }
  }
  ${UserPackage}
`
