import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'

import Box from '@base/Box'
import Typography from '@base/Typography'

const useStyles = makeStyles(({ breakpoints }) => ({
  pill: {
    display: 'inline-block',
    borderRadius: 20,
    margin: 5,
    [breakpoints.down('xs')]: {
      margin: 3,
      height: 30,
    },
  },
}))

const MedallionSkeleton = () => {
  const classes = useStyles({})

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <Box flexGrow={1}>
        <Box display='inline-block' position='relative' mb={1.875}>
          <Box
            width={22}
            height={22}
            borderRadius='100%'
            border='3px solid #fff'
            position='absolute'
            top={-1}
            right={-1}
            bgcolor='#fff'
            zIndex={1}
          >
            <Skeleton variant='circle' width='100%' height='100%' />
          </Box>
          <Skeleton variant='circle' width={104} height={104} />
          <Box
            width={40}
            height={40}
            borderRadius='100%'
            position='absolute'
            bottom={-20}
            left='calc(50% - 20px)'
            bgcolor='#fff'
            zIndex={1}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Skeleton variant='rect' width={20} height={16} />
          </Box>
        </Box>
        <Box mt={1.25} mx='auto' width='50%'>
          <Typography variant='subtitle1' weight={600} align='center'>
            <Skeleton />
          </Typography>
        </Box>
        <Box width='100%' mt={1}>
          <Skeleton />
          <Skeleton />
        </Box>
        <Box marginLeft={{ xs: -0.375, sm: -0.625 }} mt={1.25}>
          <Skeleton width={100} height={40} className={classes.pill} />
          <Skeleton width={100} height={40} className={classes.pill} />
        </Box>
      </Box>
      <Box mt={3}>
        <Skeleton width={150} height={40} className={classes.pill} />
      </Box>
    </Box>
  )
}

export default MedallionSkeleton
