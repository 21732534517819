import gql from 'graphql-tag'

const InviteUpdateFragment = gql`
  fragment InviteUpdate on Invite {
    id
    roomId
    room {
      id
      callInviteStatus
    }
  }
`

export default InviteUpdateFragment
