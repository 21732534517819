import Box from '@base/Box'
import { RequireAtLeastOne } from '@lib/typeUtils'

import OpenCall from './selection/OpenCall'
import Lesson from './selection/Lesson'
import Selection from './Selection'
import { TutorPriceType } from '@gql/__generated__'

type BaseProps = {
  tutorId?: string
  lessonId?: string
  onActivate: () => void
  onSelected: () => void
  onSkip?: () => void
  setLesson: (lesson: string) => void
  selected?: boolean
  setDuration?: (duration: number) => void
}

type Props = RequireAtLeastOne<BaseProps, 'lessonId' | 'tutorId'>

const PickType = ({ lessonId, onActivate, onSelected, onSkip, tutorId, setLesson, selected, setDuration }: Props) => {
  const handleSelectedType = (lessId: string = null, duration: number = null) => {
    setLesson(lessId)
    setDuration(duration)
    onSelected()
  }

  if (selected) {
    return (
      <Box onClick={onActivate}>
        {lessonId ? (
          <Lesson highlighted lessonId={lessonId} onSelected={handleSelectedType} />
        ) : (
          <OpenCall
            highlighted
            onSelected={handleSelectedType}
            tutorId={tutorId}
            tutorPriceType={TutorPriceType.ScheduledTutorPrice}
          />
        )}
      </Box>
    )
  }

  return (
    <Selection
      onSkip={onSkip}
      onSelected={handleSelectedType}
      tutorId={tutorId}
      tutorPriceType={TutorPriceType.ScheduledTutorPrice}
    />
  )
}

export default PickType
