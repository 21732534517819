import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import cx from 'classnames'
import React from 'react'
import { useTranslation } from '@src/i18n'
import { TutorItemFragment } from '@gql/__generated__'

const useStyles = makeStyles(({ palette }) => ({
  topic: {
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    height: 32,
    borderRadius: 20,
    backgroundColor: palette.primary.light,
    color: palette.primary.main,
    fontWeight: 600,
    fontSize: 14,
    margin: 5,
  },
  topicIcon: {
    marginRight: 4,
    marginLeft: -4,
    marginTop: -1,
    color: 'inherit',
    cursor: 'pointer',
  },
}))

type Props = {
  topics: TutorItemFragment['topics']
}

const Topics = ({ topics }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      {topics.map(({ name }, index) => (
        <Chip
          key={index}
          label={t(`homepage.tutorsOnline.experience.${name}`)}
          classes={{
            root: cx(classes.topic),
            icon: classes.topicIcon,
          }}
        />
      ))}
    </>
  )
}

export default Topics
