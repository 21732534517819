import { useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@base/Box'
import TutorAvatar from '@common/TutorAvatar'
import Loading from '@base/Loading'
import Error from '@base/NetworkError'
import Typography from '@base/Typography'
import {
  SchedulePackageTutorsQuery,
  SchedulePackageTutorsQueryVariables,
  TutorAvatarFragment,
} from '@gql/__generated__'
import { useTranslation } from '@src/i18n'

import QUERY from '../SchedulePackageTutorsQuery.api'

const useStyles = makeStyles(({ palette }) => ({
  hover: {
    transition: 'border-color .3s',
    cursor: 'pointer',
    border: '1px solid transparent',
    borderRadius: 4,
    '&:hover': {
      borderColor: palette.primary.main,
    },
  },
}))

type Props = {
  lessonId: string
  onSelected: (tutor: TutorAvatarFragment) => void
}

const TutorSelect = ({ lessonId, onSelected }: Props) => {
  const { t } = useTranslation()
  const classes = useStyles({})
  const { data, loading, error } = useQuery<SchedulePackageTutorsQuery, SchedulePackageTutorsQueryVariables>(QUERY, {
    variables: { lessonId },
    fetchPolicy: 'network-only',
  })
  const tutors = data?.tutors || []
  if (loading) return <Loading />
  if (error) return <Error />

  if (!tutors.length) return <Typography variant='body2'>{t('callInvite.scheduling.steps.Tutor.noTutors')}</Typography>

  return (
    <Box display='flex'>
      {tutors.map(tutor => (
        <Box key={tutor.id} onClick={() => onSelected(tutor)} className={classes.hover}>
          <TutorAvatar tutor={tutor} showStatus={false} boxProps={{ mx: 1, py: 1.5 }} />
        </Box>
      ))}
    </Box>
  )
}

export default TutorSelect
