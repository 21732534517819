import StepButton from '@material-ui/core/StepButton'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Step, { StepProps } from '@material-ui/core/Step'
import { makeStyles, Theme } from '@material-ui/core'
import { ReactNode } from 'react'
import Box from '@base/Box'

const useStyles = makeStyles(({ palette, typography }: Theme) => ({
  label: {
    fontSize: typography.body1.fontSize,
    fontWeight: 600,
    '&$labelCompleted': {
      color: palette.primary.main,
      fontWeight: 600,
    },
    '&$labelActive': {
      fontWeight: 600,
    },
  },
  labelCompleted: {},
  labelActive: {},
  icon: {
    '&$iconCompleted': {
      color: palette.primary.main,
    },
  },
  iconCompleted: {},
  button: {
    marginTop: 0,
    paddingTop: 10,
    paddingBottom: 10,
  },
}))

type Props = StepProps & {
  isCompleted: boolean
  children?: ReactNode
  title: ReactNode
  onActivate?: () => void
}

const StepperStep = ({ isCompleted, children, title, onActivate, ...props }: Props) => {
  const classes = useStyles({})

  return (
    <>
      <Step expanded={isCompleted} {...props} completed={isCompleted}>
        <StepButton onClick={onActivate} completed={isCompleted} className={classes.button}>
          <StepLabel
            classes={{ active: classes.labelActive, label: classes.label, completed: classes.labelCompleted }}
            StepIconProps={{ classes: { root: classes.icon, completed: classes.iconCompleted } }}
          >
            {title}
          </StepLabel>
        </StepButton>
        <StepContent>
          <Box mb={{ xs: 2, md: 2.5 }}>{children}</Box>
        </StepContent>
      </Step>
    </>
  )
}

export default StepperStep
