import { useTranslation } from '@src/i18n'
import Typography from '@base/Typography'
import Box from '@base/Box'
import { GetAvailableLessonsQuery } from '@gql/__generated__'

type Props = {
  packageName: string
  thisLesson: GetAvailableLessonsQuery['userPackages'][number]['package']['lessons'][number]
  lessonsCount: number
}

const Details = ({ packageName, thisLesson, lessonsCount }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <Box mb={3}>
        <Typography variant='subtitle2' align='left'>
          {t('callInvite.modalSteps.package.course')}: {t(`packages:list.${packageName}.name`)}
        </Typography>
      </Box>
      <Typography align='left'>
        {t('callInvite.modalSteps.package.lesson')}:{' '}
        {`${thisLesson.no} ${t('callInvite.modalSteps.package.from')} ${lessonsCount}`}
      </Typography>
    </>
  )
}

export default Details
