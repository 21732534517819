import Typography from '@base/Typography'
import Box from '@base/Box'
import { useTranslation } from '@src/i18n'
import { useDualPrice } from '@hooks/useExchangeRate'
import { CheckCreditResultStatus } from '@gql/__generated__'

type Props = {
  amount: number
  status: CheckCreditResultStatus
}

const TopUpMessage = ({ amount, status }: Props) => {
  const { t } = useTranslation()
  const dualPrice = useDualPrice(amount)

  return (
    <Box mb={1}>
      <Typography weight={600}>
        {t(`callInvite.scheduling.steps.Finish.lowCredit.${status}`)}{' '}
        {t(`callInvite.scheduling.steps.Finish.lowCredit.topUp`, { amount: dualPrice })}
      </Typography>
    </Box>
  )
}

export default TopUpMessage
