import { makeStyles, Theme, useTheme } from '@material-ui/core'
import { useEffect, useState, useRef } from 'react'

import Box from '@base/Box'
import Button from '@base/Button'
import Indicator from '@base/Indicator'
import Typography from '@base/Typography'
import Well from '@base/Well'
import audioAnalyzer from '@helpers/audioAnalyzer'
import { useTranslation } from '@src/i18n'
import getLogger from '@src/Logger'

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
  button: {
    minWidth: 220,
  },
  sentimentIcon: {
    fontSize: 60,
    [breakpoints.up('md')]: {
      fontSize: 100,
    },
  },
}))

type Props = {
  audio: MediaStream
  onCancel: () => void
  onContinue: () => void
  onPassed: () => void
  onError: () => void
  onDataUpdate: (data: any) => void
}

type StepData = {
  maxLevel: number
  success: boolean
  error?: string
}

type InputIssue = 'NOT_SUPPORTED' | 'NO_INPUT'

const AudioInputTest = ({ audio, onCancel, onContinue, onError, onPassed, onDataUpdate }: Props) => {
  const theme = useTheme<Theme>()
  const classes = useStyles({})
  const [inputLevel, setInputLevel] = useState(0)
  const [issue, setIssue] = useState<InputIssue>(null)
  const [passed, setPassed] = useState(false)
  const [timeElapsed, setTimeElapsed] = useState(false)
  const { t } = useTranslation()
  const stepData = useRef<StepData>({
    maxLevel: 0,
    success: false,
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeElapsed(true)
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (!audio) {
      return
    }

    const stopAnalyser = audioAnalyzer(audio, handleInput, handleNotSupported, handleNoInput)
    return () => stopAnalyser()
  }, [audio])

  useEffect(() => {
    // Mark test passed when there is audio input (level > 0) and given time has elapsed
    // (so user see any interaction, not immediately passed test)
    if (!passed && timeElapsed && inputLevel > 0) {
      setPassed(true)
      getLogger().info({ obj: { inputLevel } }, 'User passed audio input test')
    }
  }, [inputLevel, timeElapsed, passed])

  useEffect(() => {
    passed && handlePassed()
  }, [passed])

  const handleInput = (input: number) => {
    stepData.current = {
      ...stepData.current,
      maxLevel: Math.max(stepData.current.maxLevel, input),
    }
    onDataUpdate(stepData.current)
    setInputLevel(input)
  }

  const handlePassed = () => {
    setIssue(null)
    stepData.current = {
      ...stepData.current,
      success: true,
    }
    onDataUpdate(stepData.current)
    onPassed()
  }

  const handleNoInput = () => {
    setIssue('NO_INPUT')
    stepData.current = {
      ...stepData.current,
      error: 'NO_INPUT',
    }
    onDataUpdate(stepData.current)
    onError()
  }

  const handleNotSupported = () => {
    setIssue('NOT_SUPPORTED')
    stepData.current = {
      ...stepData.current,
      error: 'NOT_SUPPORTED',
    }
    onDataUpdate(stepData.current)
    onError()
  }

  const handleTryAgain = () => {
    setIssue(null)
  }

  return (
    <Box textAlign='center'>
      {!issue ? (
        <>
          <Well variant='alabaster' mb={2}>
            {t('permissionTest.microphoneTest.talkInstruction')}
          </Well>
          <Well variant='alabaster' px={1.5} py={1} mb={2}>
            <Indicator value={inputLevel} />
          </Well>
          <Box mb={2} color={passed ? theme.palette.primary.main : theme.palette.error.main}>
            <Typography variant='inherit' weight={600}>
              {timeElapsed ? t(`permissionTest.testing.mic.${passed ? 'sucess' : 'error'}`) : ' '}
            </Typography>
          </Box>

          <Button
            className={classes.button}
            color={passed ? 'primary' : 'inherit'}
            variant='contained'
            onClick={passed ? onContinue : handleNoInput}
            size='small'
          >
            {passed ? t('permissionTest.testing.button.success') : t('permissionTest.testing.button.help')}
          </Button>
        </>
      ) : (
        <>
          {issue === 'NOT_SUPPORTED' && (
            <Box textAlign='left' mb={2}>
              <Typography>{t('permissionTest.microphoneTest.notSupported')}</Typography>
            </Box>
          )}
          {issue === 'NO_INPUT' && (
            <>
              <Box textAlign='left' mb={2}>
                <Typography>{t('permissionTest.microphoneTest.help.instructionsTitle')}</Typography>
                <Typography component='ol'>
                  {t<any, string[]>('permissionTest.microphoneTest.help.instructions', {
                    defaultValue: [],
                    returnObjects: true,
                  }).map((instruction, i) => (
                    <li key={i}>{instruction}</li>
                  ))}
                </Typography>
              </Box>
              <Box mb={2}>
                <Button
                  className={classes.button}
                  color='primary'
                  onClick={handleTryAgain}
                  size='small'
                  variant='contained'
                >
                  {t('permissionTest.microphoneTest.btnRetry')}
                </Button>
              </Box>
            </>
          )}
          <Box>
            <Button className={classes.button} color='inherit' onClick={onCancel} size='small' variant='contained'>
              {t('permissionTest.microphoneTest.cancel')}
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}

export default AudioInputTest
