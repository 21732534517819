import React, { ComponentProps } from 'react'
import { Trans } from '@src/i18n'
import { Omit } from '@material-ui/core'

import Typography from '@base/Typography'
import Link from '@base/Link'
import Box from '@base/Box'

type Props = Omit<ComponentProps<typeof Typography>, 'children'> & {
  invite?: boolean
}

const GDPRCheck = ({ invite = false, ...props }: Props) => {
  const terms = (
    <Link
      key='terms'
      external={true}
      weight={700}
      underline
      variant='inherit'
      href='https://terap.io/static/files/VUP_pilot_1.1.pdf'
    />
  )
  const privacy = (
    <Link
      key='privacy'
      external={true}
      weight={700}
      underline
      variant='inherit'
      href='https://terap.io/privacy'
      {...props}
    />
  )

  return (
    <Box py={2}>
      <Typography variant='body2' color='textSecondary' weight={600} {...props}>
        {invite ? (
          <Trans i18nKey='auth.terms.agreed' components={[terms, privacy]} />
        ) : (
          <Trans i18nKey='auth.terms.mustAgree' components={[terms, privacy]} />
        )}
      </Typography>
    </Box>
  )
}

export default GDPRCheck
