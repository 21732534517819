import React, { ReactNode } from 'react'
import { default as ImportedHead } from 'next/head'
import getConfig from 'next/config'

type Props = {
  title: string
  description?: string
  children?: ReactNode
  asPath?: string
}

const { BASE_URL } = getConfig().publicRuntimeConfig

const Head = ({ title, description, children, asPath }: Props) => {
  const titleText = `Terap.io - ${title}`

  return (
    <ImportedHead>
      <title>{titleText}</title>
      <meta key='og:title' property='og:title' content={titleText} />
      <meta key='og:image' property='og:image' content={'https://cdn2.terap.io/img/logos/og-terapio.png'} />
      {asPath && <meta key='og:url' property='og:url' content={`${BASE_URL}${asPath}`} />}
      <meta name='description' content={description} />
      <meta key='og:description' property='og:description' content={description} />
      {children}
    </ImportedHead>
  )
}

export default Head
