import gql from 'graphql-tag'

import InviteParticipant from '../InviteParticipantFragment.api'

export const FragmentName = 'SentInvite'

const SentInvite = gql`
  fragment SentInvite on Invite {
    id
    roomId
    sender
    target
    room {
      id
      callInviteStatus
      createdAt
      duration
      lesson {
        id
      }
      participants {
        profileId
        ...InviteParticipant
      }
    }
  }

  ${InviteParticipant}
`

export default SentInvite
