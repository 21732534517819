import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@base/Box'
import Typography from '@base/Typography'

type Props = {
  children: string
}

const useStyles = makeStyles(() => ({
  motto: {
    textAlign: 'center',
    color: '#333333',
    fontSize: '14px',
    lineHeight: '22px',
    // fallback for non webkit
    height: '66px',
    overflow: 'hidden',
    // webkit multiline three dots
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  },
}))

const Motto = ({ children }: Props) => {
  const classes = useStyles()

  return (
    <Box flexGrow={1} mb={2}>
      <Typography className={classes.motto} color='textSecondary' component='div' variant='caption'>
        {children}
      </Typography>
    </Box>
  )
}

export default Motto
