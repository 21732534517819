import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ToggleButtonGroup, { ToggleButtonGroupProps } from '@material-ui/lab/ToggleButtonGroup'
import MuiToggleButton, { ToggleButtonProps } from '@material-ui/lab/ToggleButton'
import cx from 'classnames'

const useStyles = makeStyles(({ palette }) => ({
  vertical: {
    flexDirection: 'column',
    '& .MuiToggleButton-root': {
      '&:not(:first-child)': {
        marginTop: -1,
        borderTopColor: 'transparent',
        '&:hover': {
          borderTopColor: 'inherit',
        },
      },
      '&:first-child': {
        borderRadius: 0,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      },
      '&:last-child': {
        borderRadius: 0,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
      },
    },
  },
  highlighted: {
    '&, &:hover': {
      color: palette.text.primary,
      backgroundColor: 'transparent',
      border: 'none',
    },
  },
}))

type Props = ToggleButtonGroupProps & {
  children: ReactNode
  vertical?: boolean
}

const ToggleButtons = ({ children, vertical, ...props }: Props) => {
  const classes = useStyles({})

  return (
    <ToggleButtonGroup className={cx({ [classes.vertical]: vertical })} {...props}>
      {children}
    </ToggleButtonGroup>
  )
}

type BtnProps = ToggleButtonProps & {
  children: ReactNode
  highlighted?: boolean
}

export const ToggleButton = ({ children, highlighted, ...props }: BtnProps) => {
  const classes = useStyles({})

  return (
    <MuiToggleButton className={cx({ [classes.highlighted]: highlighted })} {...props}>
      {children}
    </MuiToggleButton>
  )
}

export default ToggleButtons
