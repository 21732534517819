import gql from 'graphql-tag'

import TutorItem from '@gql/fragments/TutorItemFragment.api'

export default gql`
  # We suppose those data are already present in Apollo cache
  # so query resolves immediately

  query tutorModalPreviewBySlug($slug: String!) {
    tutor: getTutorBySlug(slug: $slug) {
      ...TutorItem
    }
  }

  ${TutorItem}
`
