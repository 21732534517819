import { ComponentType, useEffect } from 'react'
import { connect } from 'react-redux'

import links from '@api/links'
import redirect from '@helpers/redirect'
import getLogger from '@src/Logger'
import { userSelectors, Role } from '@store/modules/User'

type WithAuthOptions = {
  allowedRoles?: Role[]
  isAllowed?: (userRoles: Role[]) => boolean
  redirectUrl?: string
}

type ReduxStateProps = ReturnType<typeof mapStateToProps>

const mapStateToProps = state => {
  const userState = userSelectors.getSelf(state)

  return {
    userRoles: userSelectors.getRoles(userState),
  }
}

function withAuth<WrappedProps = {}>({ allowedRoles = [Role.User], isAllowed, redirectUrl }: WithAuthOptions = {}) {
  return (WrappedComponent): ComponentType<WrappedProps> => {
    const isAllowedImpl =
      isAllowed ||
      ((userRoles: Role[]) => {
        const allowed = userRoles && !!allowedRoles.filter(role => userRoles.indexOf(role) >= 0).length

        if (!allowed) {
          getLogger().info({ obj: { allowedRoles, roles: userRoles } }, 'User not allowed. Redirecting to homepage')
        }

        return allowed
      })

    const handleNotAllowed = ctx => {
      redirect(ctx, redirectUrl || links.home)
    }

    const WithAuth = ({ userRoles, ...props }: ReduxStateProps) => {
      const allowed = isAllowedImpl(userRoles)

      // Check changes in "allowed" during component life-cycle (client side)
      useEffect(() => {
        if (!allowed) {
          handleNotAllowed(null)
        }
      }, [allowed])

      if (!allowed) {
        return null
      }

      return <WrappedComponent {...props} />
    }

    const WithAuthConnected = connect<ReduxStateProps, {}>(mapStateToProps)(WithAuth)

    // @ts-ignore
    WithAuthConnected.getInitialProps = async ctx => {
      const state = ctx.store.getState()
      const userState = userSelectors.getSelf(state)
      const userRoles = userSelectors.getRoles(userState)

      // Check current user before page is initially rendered (server/client side)
      if (!isAllowedImpl(userRoles)) {
        handleNotAllowed(ctx)
        return {}
      }

      let initialProps = {}
      if (WrappedComponent.getInitialProps) {
        initialProps = await WrappedComponent.getInitialProps(ctx)
      }

      return initialProps
    }

    return WithAuthConnected
  }
}

export default withAuth
