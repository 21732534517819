import gql from 'graphql-tag'

const TutorModalSentInvites = gql`
  query TutorModalSentInvites($isStudent: Boolean!) {
    finishedInvites @client {
      id
    }
    pendingInvites: getInvitesFixed @include(if: $isStudent) {
      id
    }
  }
`

export default TutorModalSentInvites
