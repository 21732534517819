import { useEffect } from 'react'
import getConfig from 'next/config'
import Box from '@base/Box'
import Typography from '@base/Typography'
import { ScheduledCall, TutorItemFragment, TutorModalDetailQuery, TutorPriceType } from '@gql/__generated__'
import { exchangeMakeNiceFormat, makePrice } from '@helpers/price'
import useExchangeRate from '@hooks/useExchangeRate'
import { useTranslation } from '@src/i18n'
import analytics from '@src/Analytics'
import Selection from '@tutor/scheduling/Selection'
import Details from './order/Details'
import Summary from './order/Summary'
import ScheduleCall from './order/ScheduleCall'
import Buttons from '../tutorModal/Buttons'
import SendInviteButton from './order/SendInviteButton'
import ContinueButton from './order/ContinueButton'
import { Step } from '../TutorModal'
import { getRateByType } from '@service/Tutor'

const { BOOKING } = getConfig().publicRuntimeConfig

type Props = {
  step: Step
  minStep: Step
  tutor: TutorItemFragment
  prices: TutorModalDetailQuery['tutor']['prices'] | null
  isLoggedIn: boolean
  lessonId: string
  loading: boolean
  goToNext: () => void
  goBack: () => void
  onClose: () => void
  setInviteError: any
  scheduledCall: Pick<ScheduledCall, 'id' | 'start'> | null
  scheduledCallId: string | null
  refetch: () => void
  duration: number
  setDuration: (dur: number) => void
  setLesson: (lesson: string) => void
}

const Order = ({
  minStep,
  step,
  tutor,
  prices,
  lessonId,
  loading,
  goToNext,
  goBack,
  onClose,
  setInviteError,
  scheduledCall,
  scheduledCallId,
  refetch,
  duration,
  setDuration,
  setLesson,
}: Props) => {
  const { t } = useTranslation()
  const [exRate, currency] = useExchangeRate()
  const isOnline = true

  const pushAnalytics = () => {
    if (step === Step.Details) {
      analytics.pushEvent('tutor/modal/duration')
    } else if (step === Step.Summary) {
      analytics.pushEvent('addToCart', {
        tutorName: tutor.user.firstName,
        ecommerce: {
          add: {
            products: [
              {
                name: tutor.user.firstName,
                id: tutor.id,
                category: 'call_EN',
                quantity: analytics.getCallUnits(duration),
              },
            ],
          },
        },
      })
    }
  }

  useEffect(() => pushAnalytics(), [step])

  const getBasePrice = (durationTime: number): number => {
    if (!prices) return 0

    return Number(getRateByType(prices, TutorPriceType.InstantTutorPrice, durationTime * 1000).price.amount.toFixed(2))
  }

  const durationOption = (durationTime: number): string => {
    if (!durationTime) return null
    const basePrice = getBasePrice(durationTime)
    return exchangeMakeNiceFormat(makePrice(basePrice), exRate, currency)
  }

  const handleSelectedType = (lessId: string = null, dur: number = null) => {
    setLesson(lessId)
    setDuration(dur)
    goToNext()
  }

  return (
    <>
      {step !== Step.Info && (
        <Box width='100%' textAlign='center' display='flex' flexDirection='column' alignItems='center' pt={4}>
          {step === Step.Selection && (
            <>
              {!lessonId && (
                <Typography variant='subtitle1' weight={600}>
                  {t('callInvite.modalSteps.Selection.title')}
                </Typography>
              )}
              <Box mt={{ xs: 2, md: 4 }} alignSelf='stretch'>
                <Selection
                  tutorId={tutor.id}
                  tutorPriceType={TutorPriceType.InstantTutorPrice}
                  onSelected={handleSelectedType}
                  onSkip={goToNext}
                />
              </Box>
            </>
          )}
          {step === Step.Details && (
            <Details
              tutorName={tutor.user.firstName}
              duration={duration}
              setDuration={setDuration}
              durationOption={durationOption(duration)}
              lessonId={lessonId}
              loading={loading}
              scheduledCall={scheduledCall}
              scheduledCallId={scheduledCallId}
            />
          )}
          {step === Step.Summary && (
            <Summary
              tutorName={tutor.user.firstName}
              duration={duration}
              setDuration={setDuration}
              durationOption={durationOption(duration)}
              lessonId={lessonId}
            />
          )}
        </Box>
      )}
      {isOnline && step !== Step.Selection && (
        <Buttons minStep={minStep} step={step} goBack={goBack}>
          {step === Step.Details && <ContinueButton goToNextStep={goToNext} />}
          {step === Step.Summary && (
            <SendInviteButton
              tutor={tutor}
              duration={duration}
              lessonId={lessonId}
              onClose={onClose}
              setInviteError={setInviteError}
              refetch={refetch}
              scheduledCall={scheduledCall}
            />
          )}
        </Buttons>
      )}
      {BOOKING && step === Step.Info && <ScheduleCall tutor={tutor} />}
    </>
  )
}

export default Order
