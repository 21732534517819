import { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import getConfig from 'next/config'

import { useTranslation } from '@src/i18n'
import { LessonOptionQuery, LessonOptionQueryVariables } from '@gql/__generated__'
import Loading from '@base/Loading'
import Error from '@base/NetworkError'

import Option from './Option'
import QUERY from './lesson/Query.api'

const { CALL_UNIT_LENGTH } = getConfig().publicRuntimeConfig

type Props = {
  lessonId: string
  selected?: boolean
  highlighted?: boolean
  onSelected: (lessonId: string, duration: number) => void
}

const Lesson = ({ lessonId, selected, highlighted, onSelected }: Props) => {
  const { t } = useTranslation()
  const { data, loading, error } = useQuery<LessonOptionQuery, LessonOptionQueryVariables>(QUERY, { variables: { lessonId } })
  const lesson = data?.lesson
  const { lessons, name } = lesson?.package || {}

  const handleSelected = () => {
    onSelected(lessonId, lesson.units * CALL_UNIT_LENGTH)
  }

  useEffect(() => {
    if (!data) return
    highlighted && handleSelected()
  }, [data])

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <Option
      title={t('callInvite.modalSteps.package.course')}
      selected={selected}
      value={lesson.id}
      highlighted={highlighted}
      onClick={handleSelected}
      data-testid='lesson'
    >
      {t('callInvite.modalSteps.package.courseDetails', {
        name: t(`packages:list.${name}.name`),
        callNo: lesson.no,
        callCount: lessons.length,
      })}
    </Option>
  )
}

export default Lesson
