import Arrow from '@material-ui/icons/ArrowDropUp'
import { Theme, useTheme } from '@material-ui/core'

import Box from '@base/Box'

type Props = {
  variant?: 'error' | 'info' | 'success' | 'warning'
  value: number
}

const Indicator = ({ variant = 'info', value }: Props) => {
  const theme = useTheme<Theme>()
  const val = Math.max(0, Math.min(value, 100))

  let color
  switch (variant) {
    case 'error':
      color = theme.palette.error.main
      break
    case 'success':
      color = theme.palette.customCol.jungle
      break
    case 'warning':
      color = theme.palette.customCol.sunglow
      break
    default:
      color = theme.palette.primary.main
  }

  return (
    <Box
      mt={1}
      mb={1.25}
      position='relative'
      height={14}
      style={{
        // Using single position color stops. Double-position color stops not supported in Safari < 12
        // https://developer.mozilla.org/en-US/docs/Web/CSS/repeating-linear-gradient#Browser_compatibility
        background: `repeating-linear-gradient(to right, ${theme.palette.customCol.silver} 0, ${theme.palette.customCol.silver} 7px, transparent 7px, transparent 17px)`,
      }}
    >
      <Box
        position='absolute'
        top={0}
        left={0}
        height={14}
        width={`${val}%`}
        style={{
          // Using single position color stops. Double-position color stops not supported in Safari < 12
          // https://developer.mozilla.org/en-US/docs/Web/CSS/repeating-linear-gradient#Browser_compatibility
          background: `repeating-linear-gradient(to right, ${color} 0, ${color} 7px, transparent 7px, transparent 17px)`,
        }}
      />
      <Box position='absolute' top={10} padding={0} ml={-1.5} left={`${val}%`}>
        <Arrow color='primary' />
      </Box>
    </Box>
  )
}

export default Indicator
