import CircularProgress from '@material-ui/core/CircularProgress'

import Box from '@base/Box'

type Props = {
  loading: boolean
}

const Loading = ({ loading }: Props) => {
  if (!loading) return null

  return (
    <Box
      alignItems='center'
      display='flex'
      minHeight={200}
      minWidth={200}
      justifyContent='center'
      height='100%'
      width='100%'
    >
      <CircularProgress color='inherit' size={40} thickness={5} />
    </Box>
  )
}

export default Loading
