import gql from 'graphql-tag'

import InviteId from './InviteIdFragment.api'

const InvitesQuery = gql`
  query AllInvites {
    finishedInvites @client {
      ...InviteId
    }
    pendingInvites: getInvitesFixed {
      ...InviteId
    }
  }

  ${InviteId}
`

export default InvitesQuery
