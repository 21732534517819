import React, { useMemo, memo } from 'react'
import { useRouter } from 'next/router'
import { useInView } from 'react-intersection-observer'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'
import { UrlObject } from 'url'

import Box from '@base/Box'
import { Tutor } from '@gql/__generated__'

import Medallion, { Props as MedallionProps } from '@components/tutor/tutorItem/tutorBox/Medallion'

type WrapperProps = {
  tutor: MedallionProps['tutor'] & Pick<Tutor, 'slug'>
  localSrc?: boolean
}

export type Props = WrapperProps & {
  link: {
    href: UrlObject,
    as: string
  }
}

const useStyles = makeStyles(({ breakpoints }) => ({
  icon: {
    fontSize: 20,
    marginRight: 8,
  },
  button: {
    padding: 0,
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.26)',
    [breakpoints.up('md')]: {
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.14)',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'initial',
  },
}))

const TutorItem: React.FC<Props> = memo(({ link, tutor, localSrc }) => {
  const [ref, inView] = useInView({ threshold: 0 })
  const classes = useStyles({})

  return (
    <Box
      ref={ref}
      minWidth={240}
      m={1}
      width='100%'
      maxWidth={360}
      minHeight={300}
      display='flex'
      data-testid='tutorItem'
    >
      {inView ? (
        <Box
          bgcolor='#fff'
          px={{ xs: 2.5, sm: 3.75 }}
          py={3.75}
          width='100%'
          position='relative'
          display='flex'
          flexDirection='column'
          /* border='1px solid #E4E7EB' */
          className={classes.link}
          data-testid='tutor'
        >
          <Box flex={1}>
            <Medallion tutor={tutor} link={link} localSrc={localSrc} />
          </Box>
        </Box>
      ) : (
        <Skeleton variant='rect' width='100%' height='100%' />
      )}
    </Box>
  )
})

const TutorItemWrapper: React.FC<WrapperProps> = ({ tutor, localSrc }) => {
  const { pathname, query } = useRouter()
  const link = useMemo(() => {
    if (!tutor?.slug) {
      return null
    }

    return {
      href: { pathname, query: { ...query, slug: tutor.slug, tutorId: tutor.id, scroll: false } },
      as: `${pathname === '/' ? '' : pathname}/t/${tutor.slug}`,
    }
  }, [pathname, tutor.slug, tutor.id])

  return <TutorItem link={link} tutor={tutor} localSrc={localSrc} />
}

export default TutorItemWrapper
