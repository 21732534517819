import { makeStyles, Theme } from '@material-ui/core'
import SentimentDissatisfied from '@material-ui/icons/SentimentDissatisfied'
import React, { useEffect, useState } from 'react'

import Dialog from '@base/Dialog'
import Typography from '@base/Typography'
import { detectBrowser } from '@helpers/browser'
import { useTranslation } from '@src/i18n'
import Box from '@base/Box'
import Button from '@base/Button'

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
  button: {
    minWidth: 220,
  },
  sentimentIcon: {
    fontSize: 60,
    [breakpoints.up('md')]: {
      fontSize: 100,
    },
  },
}))

type Props = {
  onClose: () => void
}

const NotSupportedDialog = ({ onClose }: Props) => {
  const classes = useStyles({})
  const { t } = useTranslation()
  const [browser, setBrowser] = useState(null)

  useEffect(() => {
    setBrowser(detectBrowser())
  }, [])

  return (
    <Dialog
      data-testid='notSupportedDialog'
      open
      onClose={onClose}
      aria-labelledby={t('permissionTest.UNSUPPORTED_BROWSER.title')}
    >
      <Box maxWidth={450} mx={{ xs: 2, sm: 4 }} my={{ xs: 5 }} textAlign='center'>
        <Box mb={2}>
          <Typography color='error' variant='h5' weight={600}>
            {t('permissionTest.UNSUPPORTED_BROWSER.title')}
          </Typography>
        </Box>
        <Box mb={2}>
          <SentimentDissatisfied className={classes.sentimentIcon} />
        </Box>
        <Box mb={2}>
          <Typography variant='body1'>
            {t([
              `permissionTest.UNSUPPORTED_BROWSER.message.${browser && browser.name}`,
              'permissionTest.UNSUPPORTED_BROWSER.message.default',
            ])}
          </Typography>
        </Box>
        <Button className={classes.button} color='inherit' onClick={onClose} size='small' variant='contained'>
          {t('permissionTest.UNSUPPORTED_BROWSER.cancelBtn')}
        </Button>
      </Box>
    </Dialog>
  )
}

export default NotSupportedDialog
