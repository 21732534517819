import cx from 'classnames'
import Grid from '@material-ui/core/Grid'
import { makeStyles, Theme } from '@material-ui/core/styles'
import links from '@api/links'

import { useTranslation } from '@src/i18n'
import Typography from '@base/Typography'
import Box from '@base/Box'
import Container from '@base/Container'
import Link from '@base/Link'
import deer from './howDoesItWork/deer.svg'
import dolphin from './howDoesItWork/dolphin.svg'
import eye from './howDoesItWork/eye.svg'

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  container: {
    flexWrap: 'wrap',
    [breakpoints.up('md')]: {
      flexWrap: 'no-wrap',
    },
  },
  item: {
    width: '100%',
    marginTop: '4rem',
    position: 'relative',
    [breakpoints.up('md')]: {
      maxWidth: '25%',
    },
  },
  background: {
    height: '9rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundSize: 'contain',
  },
  dolphin: {
    backgroundImage: `url('${dolphin}')`,
  },
  eye: {
    backgroundImage: `url('${eye}')`,
  },
  deer: {
    backgroundImage: `url('${deer}')`,
  },
}))

const HowDoesItWork = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const h1 = t('terapio:homepage.howDoesItWork.title')

  return (
    <Container
      component='section'
      // Fit content to lower viewport on mobile
      py={6}
      innerProps={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1200,
        justifyContent: 'center',
        position: 'relative',
      }}
      position={'relative'}
    >
      <Box textAlign={'center'}>
        <Typography variant='h3' component='h2'>
          <div dangerouslySetInnerHTML={{ __html: h1 }} />
        </Typography>
      </Box>
      <Grid container alignItems='center' justifyContent='space-between' className={classes.container}>
        <Box className={classes.item} textAlign={'center'}>
          <div className={cx(classes.background, classes.dolphin)} />
          <Typography variant='body1' color='textSecondary'>
            <Link fullWidth underline {...links.signup}>
              {t('terapio:homepage.howDoesItWork.box1Link')}
            </Link>
            {t('terapio:homepage.howDoesItWork.box1')}
          </Typography>
        </Box>
        <Box className={classes.item} textAlign={'center'}>
          <div className={cx(classes.background, classes.eye)} />
          <Typography variant='body1' color='textSecondary'>
            {t('terapio:homepage.howDoesItWork.box2')}
            <Link fullWidth underline href={links.onboarding}>
              {t('terapio:homepage.howDoesItWork.box2Link')}
            </Link>
          </Typography>
        </Box>
        <Box className={classes.item} textAlign={'center'}>
          <div className={cx(classes.background, classes.deer)} />
          <Typography variant='body1' color='textSecondary'>
            {t('terapio:homepage.howDoesItWork.box3')}
          </Typography>
        </Box>
      </Grid>
    </Container>
  )
}
export default HowDoesItWork
