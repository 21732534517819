import { ReactNode, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { hideScrollbar } from '@lib/theme/mixins'

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    position: 'relative',
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    userSelect: 'none',
    cursor: 'pointer',
    ...hideScrollbar(),

    [breakpoints.up('md')]: {
      background:
        'linear-gradient(to right, #fff, #fff), linear-gradient(to right, #fff, #fff), linear-gradient(to right, rgba(0,0,0,.25), transparent), linear-gradient(to left, rgba(0,0,0,.25), transparent)',
      backgroundAttachment: 'local, local, scroll, scroll',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left center, right center, left center, right center',
      backgroundSize: '20px 100%, 20px 100%, 20px 100%, 20px 100%',
    },
  },
}))

type Props = {
  children: ReactNode
}

export const HorizontalScroll = ({ children, ...props }: Props) => {
  const classes = useStyles({})
  const container = useRef(null)
  let startX
  const scrollLeft = useRef(null)

  const handleMouseDown = e => {
    container.current.classList.add('active')
    startX = e.pageX - container.current.offsetLeft
    scrollLeft.current = container.current.scrollLeft
  }
  const handleMouseLeave = () => {
    container.current.classList.remove('active')
  }
  const handleMouseUp = () => {
    container.current.classList.remove('active')
  }
  const handleMouseMove = e => {
    if (!container.current.classList.contains('active')) return
    e.preventDefault()
    const x = e.pageX - container.current.offsetLeft
    const walk = (x - startX) * 2 // scroll-fast
    container.current.scrollLeft = scrollLeft.current - walk
  }

  return (
    <div
      role='list'
      ref={container}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      className={classes.root}
      {...props}
    >
      {children}
    </div>
  )
}

export default HorizontalScroll
