import { CallInviteStatus } from '@gql/__generated__'
import { compareIso8601Dates } from '@helpers/dateTime'
import { ArrayElementType } from '@lib/typeUtils'

type InviteWithId = {
  roomId: string
  __typename: 'Invite'
}

type SortableInvite = {
  room: {
    createdAt: string
  }
}

export type SortableIncomingInvite = {
  room: {
    createdAt: string
    isScheduled: boolean
  }
}

type InviteWithParticipants = {
  room: {
    participants: {
      profile: {
        id: string
      }
    }[]
  }
}

type InviteWithStatus = {
  room: {
    callInviteStatus: CallInviteStatus
  }
}

const findIndexByRoomId = (invites: InviteWithId[], roomId: string) => {
  return invites.findIndex(invite => invite.roomId === roomId)
}

export const addToArray = (invites: InviteWithId[], roomId: string): InviteWithId[] => {
  if (!!~findIndexByRoomId(invites, roomId)) {
    return invites
  }

  return [
    ...invites,
    // Cache data are normalized, so adding minimum subset of normalized object
    // is good enough
    {
      roomId: roomId,
      __typename: 'Invite',
    },
  ]
}

export const deduplicateInvites = <Invite extends InviteWithId>(invites: Invite[]): Invite[] => {
  const uniqueIds = []
  return invites.filter(invite => {
    const preserve = !~uniqueIds.indexOf(invite.roomId)
    uniqueIds.push(invite.roomId)
    return preserve
  })
}

export const removeFromArray = (invites: InviteWithId[], roomId: string): InviteWithId[] => {
  if (!~findIndexByRoomId(invites, roomId)) {
    return invites
  }

  return [...invites.filter(invite => invite.roomId !== roomId)]
}

export const filterInvitesByStatus = <Invite extends InviteWithStatus>(
  invites: Invite[],
  status: CallInviteStatus,
): Invite[] => invites.filter(invite => invite.room.callInviteStatus === status)

export const getOldestInvite = <Invite extends SortableInvite>(invites: Invite[]): Invite => {
  return sortInvitesByCreatedAt(invites, 'asc')[0] || null
}

/**
 * Filters out Invite participants with given profileIds and returns participant which is not current user.
 */
export const getParticipantProfile = <Invite extends InviteWithParticipants>(
  invite: Invite,
  currentProfileIds: string[],
): ArrayElementType<Invite['room']['participants']>['profile'] => {
  const participantProfiles = invite.room.participants.filter(
    participant => !~currentProfileIds.indexOf(participant.profile.id),
  )

  if (participantProfiles.length !== 1) {
    throw new Error(
      'Invalid Invite participants. We assume that 1 participant is current user and 1 participant is counterpart user.',
    )
  }

  return participantProfiles[0].profile
}

/**
 * Sort invites by datetime-created
 */
export const sortInvitesByCreatedAt = <Invite extends SortableInvite>(
  invites: Invite[],
  order: 'asc' | 'desc' = 'desc',
): Invite[] =>
  [...invites].sort((a, b) => {
    const comp = compareIso8601Dates(a.room.createdAt, b.room.createdAt)
    return order === 'desc' ? -comp : comp
  })

/**
 * Sort invites:
 * - scheduled invites first by createdAt ASC
 * - rest of invites by createdAt ASC
 */
export const sortIncomingInvites = <Invite extends SortableIncomingInvite>(invites: Invite[]): Invite[] =>
  [...invites].sort((a, b) => {
    // 1. criterion – by isScheduled DESC
    const aScheduled = Number(a.room.isScheduled)
    const bScheduled = Number(b.room.isScheduled)
    const scheduledInvitesComp = aScheduled > bScheduled ? -1 : aScheduled < bScheduled ? 1 : 0
    // 2. criterion – by createdAt ASC
    const dateComp = compareIso8601Dates(a.room.createdAt, b.room.createdAt)

    return 10 * scheduledInvitesComp + dateComp
  })

type LegacyVariant = 'busy' | 'cancelled' | 'expired' | 'sent' | 'received' | 'rejected'

export const getInviteVariant = (invite: InviteWithStatus, isIncoming: boolean): LegacyVariant => {
  const {
    room: { callInviteStatus: status },
  } = invite

  switch (status) {
    case CallInviteStatus.InviteCanceled:
      return 'cancelled'
    case CallInviteStatus.InviteExpired:
      return 'expired'
    case CallInviteStatus.InviteRejected:
      return 'rejected'
    case CallInviteStatus.InviteBusy:
      return 'busy'
  }

  return isIncoming ? 'received' : 'sent'
}
