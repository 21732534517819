import { useTranslation } from '@src/i18n'
import Box from '@base/Box'
import Typography from '@base/Typography'
import Times from './pickLength/Times'

type Props = {
  onSetShift?: (shiftId: string) => void
  times: any[]
  onSetDateTime: (Date) => void
  selectedDateTime: Date
}

const PickTime = ({ onSetShift, times, selectedDateTime, onSetDateTime }: Props) => {
  const { t } = useTranslation()

  return (
    <Box textAlign='center'>
      {(times || times.length > 1) && (
        <Times
          times={times}
          selectedDateTime={selectedDateTime}
          onSetDateTime={onSetDateTime}
          onSetShift={onSetShift}
        />
      )}
      {(!times || times.length < 1) && (
        <Typography variant='body1' data-testid='noDates'>
          {t('callInvite.scheduling.steps.Date.noDateSelected')}
        </Typography>
      )}
    </Box>
  )
}

export default PickTime
