import gql from 'graphql-tag'

export default gql`
  query LessonOption($lessonId: String!) {
    lesson: getLesson(id: $lessonId) {
      id
      units
      no
      package {
        id
        name
        lessons {
          id
        }
      }
    }
  }
`
