import gql from 'graphql-tag'
import UserPackage from './UserPackageFragment.api'

export default gql`
  query GetAvailableLessons($tutorId: String!) {
    userPackages: getPurchasedPackages {
      ...UserPackage
    }
    tutor: getTutor(id: $tutorId) {
      id
      packages {
        id
        name
        lessons {
          id
        }
      }
    }
  }
  ${UserPackage}
`
