import { makeStyles } from '@material-ui/core'
import PlayArrow from '@material-ui/icons/PlayArrow'
import React, { useEffect, useRef, useState } from 'react'

import Box from '@base/Box'
import AspectRatioBox from '@base/AspectRatioBox'
import Fab from '@base/Fab'
import getLogger from '@src/Logger'
import Button from '@base/Button'
import Typography from '@base/Typography'
import { useTranslation } from '@src/i18n'

const useStyles = makeStyles({
  container: {
    maxWidth: '100%',
    transform: 'scaleX(-1)',
  },
  button: {
    minWidth: 220,
  },
  playButton: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
})

type Props = {
  onCancel: () => void
  onPassed: () => void
  onError: () => void
  video: MediaStream
  onDataUpdate: (data: any) => void
}

type StepData = {
  manualPlayClick: boolean
  success: boolean
  error?: string
}

const VideoInputTest = ({ onCancel, onPassed, onError, onDataUpdate, video }: Props) => {
  const classes = useStyles({})
  const { t } = useTranslation()
  const [hasIssue, setHasIssue] = useState<boolean>(false)
  const [playing, setPlaying] = useState<boolean>(false)
  const container = useRef<HTMLVideoElement>(null)
  const stepData = useRef<StepData>({
    manualPlayClick: false,
    success: false,
  })

  useEffect(() => {
    if (!video || hasIssue || !container.current) {
      return
    }

    container.current.addEventListener('loadedmetadata', handleLoaded)
    container.current.addEventListener('play', handlePlay)
    container.current.addEventListener('pause', handlePause)
    container.current.srcObject = video
    return () => {
      if (!container.current) {
        return
      }
      container.current.removeEventListener('loadedmetadata', handleLoaded)
      container.current.removeEventListener('play', handlePlay)
      container.current.removeEventListener('pause', handlePause)
    }
  }, [hasIssue, video])

  const handleLoaded = ev => {
    const $play = ev.currentTarget.play()
    // Older browsers doesn't return promise
    // See https://developers.google.com/web/updates/2016/03/play-returns-promise
    $play &&
      $play
        .then(() => getLogger().info('Permission test – video playback started'))
        .catch(err => getLogger().warn({ err }, 'Permission test – video playback failed'))
  }

  const handleInput = () => {
    setHasIssue(false)
    stepData.current = {
      ...stepData.current,
      success: true,
    }
    onDataUpdate(stepData.current)
    onPassed()
  }

  const handleNoInput = () => {
    setHasIssue(true)
    stepData.current = {
      ...stepData.current,
      error: 'NO_INPUT',
    }
    onDataUpdate(stepData.current)
    onError()
  }

  const handlePlayBtn = () => {
    stepData.current = {
      ...stepData.current,
      manualPlayClick: true,
    }
    onDataUpdate(stepData.current)

    if (container.current) {
      const $play = container.current.play()

      // Older browsers doesn't return promise
      // See https://developers.google.com/web/updates/2016/03/play-returns-promise
      $play &&
        $play
          .then(() => getLogger().info('Video input test – video played'))
          .catch(err => getLogger().warn({ err }, 'Video input test – video not played'))
    }
  }

  const handlePlay = () => {
    container.current && setPlaying(true)
  }

  const handlePause = () => {
    container.current && setPlaying(false)
  }

  return (
    <Box textAlign='center'>
      {!hasIssue ? (
        <>
          <Box bgcolor='#000' maxWidth={350} mb={2} mx='auto' position='relative'>
            <AspectRatioBox className={classes.container} ratio={1}>
              <video data-testid='video' ref={container} playsInline muted width='100%' height='100%' />
            </AspectRatioBox>
            {/* When browser's content policy doesn't allow autoplay, show button */}
            {!playing && (
              <Fab className={classes.playButton} color='primary' onClick={handlePlayBtn}>
                <PlayArrow />
              </Fab>
            )}
          </Box>
          <Box mb={2}>
            <Button className={classes.button} color='primary' onClick={handleInput} size='small' variant='contained'>
              {t('permissionTest.videoInputTest.outputBtn')}
            </Button>
          </Box>
          <Button className={classes.button} color='inherit' onClick={handleNoInput} size='small' variant='contained'>
            {t('permissionTest.videoInputTest.noOutputBtn')}
          </Button>
        </>
      ) : (
        <>
          <Box textAlign='left' mb={2}>
            <Typography>{t('permissionTest.videoInputTest.help.instructionsTitle')}</Typography>
            <Typography component='ol'>
              {t<any, string[]>('permissionTest.videoInputTest.help.instructions', { defaultValue: [], returnObjects: true }).map(
                (instruction, i) => (
                  <li key={i}>{instruction}</li>
                ),
              )}
            </Typography>
          </Box>
          <Button className={classes.button} color='inherit' onClick={onCancel} size='small' variant='contained'>
            {t('permissionTest.videoInputTest.cancelBtn')}
          </Button>
        </>
      )}
    </Box>
  )
}

export default VideoInputTest
