import ArrowBack from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton'
import { Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Box from '@base/Box'
import { useTranslation } from '@src/i18n'

type Props = {
  show: boolean
  onClick: () => void
  iconStyle?: string
}

const Button = ({ onClick, iconStyle }: { onClick: Props['onClick']; iconStyle: Props['iconStyle'] }) => {
  const { t } = useTranslation()

  return (
    <IconButton aria-label={t('base.back')} color='inherit' onClick={onClick}>
      <ArrowBack className={iconStyle} />
    </IconButton>
  )
}

const BackButton = ({ show, onClick, iconStyle }: Props) => {
  const theme: Theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (!show) return null

  if (isMobile) {
    return (
      <Box position='absolute' top={{ xs: 0, sm: 10 }} left={{ xs: 0, sm: 10 }} color={theme.palette.customCol.silver}>
        <Button onClick={onClick} iconStyle={iconStyle} />
      </Box>
    )
  }

  return (
    <Box
      mr={2.5}
      display='inline-block'
      borderRadius={4}
      border={`1px solid ${theme.palette.action.disabled}`}
      color='#7B8794;'
    >
      <Button onClick={onClick} iconStyle={iconStyle} />
    </Box>
  )
}

export default BackButton
