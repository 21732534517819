import gql from 'graphql-tag'

export default gql`
  fragment TutorPackages on Tutor {
    packages {
      id
      name
      lessons {
        id
      }
    }
  }
`
