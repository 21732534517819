import { ComponentProps } from 'react'

import Tooltip from '@base/Tooltip'
import Bubble from '@base/Bubble'
import Avatar from '@base/Avatar'
import Box from '@base/Box'

type Props = {
  src: string
  name: string
  avatarProps: ComponentProps<typeof Avatar>
}

const Logo = ({ src, name, avatarProps }: Props) => {
  return (
    <>
      <Tooltip title={(<Bubble>{name}</Bubble>) as any} placement='bottom'>
        <Box width='100%' height='100%' textAlign='center'>
          <Avatar variant='square' src={src} alt={name} {...avatarProps}>
            {name.substring(0, 1).toUpperCase()}
          </Avatar>
        </Box>
      </Tooltip>
    </>
  )
}

export default Logo
