import { useMutation } from '@apollo/react-hooks'
import { makeStyles, Theme } from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Stepper from '@material-ui/core/Stepper'
import Divider from '@material-ui/core/Divider'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { animateScroll } from 'react-scroll'
import cuid from 'cuid'
import throttle from 'lodash/throttle'

import Box from '@base/Box'
import Link from '@base/Link'
import Dialog from '@base/Dialog'
import Typography from '@base/Typography'
import { useTranslation, Trans } from '@src/i18n'
import getLogger from '@src/Logger'

import TestStep from './TestStep'
import AudioOutputTest from './AudioOutputTest'
import AudioInputTest from './AudioInputTest'
import VideoInputTest from './VideoInputTest'
import Help from './stepperDialog/Help'
import STORE_PERMISSION_MUTATION from './stepperDialog/StorePermissionCheckMutation.api'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  paper: {
    width: '100vh',
  },
  scrollPaper: {
    alignItems: 'start',
  },
  dialogContent: {
    paddingBottom: spacing(2),
  },
  stepper: {
    padding: 0,
  },
  button: {
    minWidth: 220,
  },
}))

type Props = {
  audio: MediaStream
  video: MediaStream
  onClose: () => void
  onError: (step: string) => void
  onPassed: () => void
}

export enum StepId {
  AudioOutput,
  AudioInput,
  VideoInput,
}

type Errors = { [step: string]: boolean }

type Passed = { [step: string]: boolean }

const steps = [StepId.AudioOutput, StepId.AudioInput, StepId.VideoInput]

const StepperDialog: React.FC<Props> = ({ audio, video, onClose, onError, onPassed }: Props) => {
  const classes = useStyles({})
  const { t } = useTranslation()
  const [activeStep, setActiveStep] = useState<StepId>(StepId.AudioOutput)
  const [passed, setPassed] = useState<Passed>({})
  const [errors, setErrors] = useState<Errors>({})
  const [showHelp, setShowHelp] = useState(false)
  const testId = useMemo(() => cuid(), [])
  const permissionData = useRef({})
  const contentRef = useRef(null)
  const audioOutputRef = useRef(null)
  const audioInputRef = useRef(null)
  const videoInputRef = useRef(null)
  const [storePermissionCheck] = useMutation(STORE_PERMISSION_MUTATION)

  const storePermissionFn = useCallback(
    throttle(() => {
      storePermissionCheck({
        variables: {
          data: permissionData.current,
          testId,
        },
      }).catch(err => {
        getLogger().error({ err, obj: { permissionData } }, 'store permission check failed')
      })
    }, 3000),
    [],
  )
  const stepRefs = [audioOutputRef, audioInputRef, videoInputRef]

  useEffect(() => {
    const stepRef = stepRefs[activeStep]

    if (!contentRef.current || !stepRef || !stepRef.current) {
      return
    }

    // Scroll to active step with some negative margin so previous step's
    // title is still visible
    animateScroll.scrollTo(stepRef.current.offsetTop - 75, {
      container: contentRef.current,
    })
  }, [activeStep])

  const getErrorStepsCount = () => {
    return Object.keys(errors).length
  }

  const getPassedStepsCount = (currentPassed: Passed) => {
    return Object.keys(currentPassed).length
  }

  const isLastStep = () => {
    return activeStep === steps.length - 1
  }

  const hasAllStepsPassed = (currentPassed: Passed) => {
    return getPassedStepsCount(currentPassed) === steps.length
  }

  const handleNext = (currentPassed: Passed = passed) => {
    const allPassed = hasAllStepsPassed(currentPassed)
    const newActiveStep =
      isLastStep() && !allPassed
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in passed))
        : activeStep + 1
    setActiveStep(newActiveStep)

    if (allPassed) {
      onPassed()
    }
  }

  const handleStep = step => {
    setActiveStep(step)
  }

  const handleStepPassed = (step: number, next = false) => {
    const newPassed = {
      ...passed,
      [step]: true,
    }
    setPassed(newPassed)

    if (errors[step]) {
      const newErrors = { ...errors }
      delete newErrors[step]
      setErrors(newErrors)
    }

    if (next) {
      handleNext(newPassed)
    }
  }

  const handleDataUpdate = (step: number, data?: unknown) => {
    permissionData.current[step] = data
    storePermissionFn()
  }

  const handleStepError = (step: number) => {
    setErrors({
      ...errors,
      [step]: true,
    })
    if (passed[step]) {
      const newPassed = { ...passed }
      delete newPassed[step]
      setPassed(newPassed)
    }
    onError(StepId[step])
  }

  const isError = useMemo(() => getErrorStepsCount() > 0, [errors])

  return (
    <Dialog
      open
      classes={{ paper: classes.paper, scrollPaper: classes.scrollPaper }}
      // User should click continue to pass permission test successfully
      // So prevent dialog to be closed by clicking on the backdrop
      disableBackdropClick={true}
      data-testid='stepperDialog'
      maxWidth='sm'
      onClose={onClose}
      aria-labelledby={t('permissionTest.steps.title')}
    >
      {showHelp ? (
        <>
          <DialogTitle>{t('permissionTest.help.title')}</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Help onClose={onClose} onRetry={() => setShowHelp(false)} />
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle>{t('permissionTest.steps.title')}</DialogTitle>
          <DialogContent className={classes.dialogContent} ref={contentRef}>
            <Box mb={2}>
              <Typography>{t('permissionTest.steps.subtitle')}</Typography>
            </Box>
            <Stepper className={classes.stepper} activeStep={activeStep} orientation='vertical'>
              <TestStep
                data-testid={`step-${StepId.AudioOutput}`}
                isCompleted={passed[StepId.AudioOutput]}
                hasError={errors[StepId.AudioOutput]}
                onActivate={() => handleStep(StepId.AudioOutput)}
                title={
                  passed[StepId.AudioOutput]
                    ? t('permissionTest.audioOutputTest.title.passed')
                    : errors[StepId.AudioOutput]
                    ? t('permissionTest.audioOutputTest.title.error')
                    : t('permissionTest.audioOutputTest.title.default')
                }
                stepRef={audioOutputRef}
              >
                <AudioOutputTest
                  onCancel={onClose}
                  onPassed={() => handleStepPassed(StepId.AudioOutput, true)}
                  onError={() => handleStepError(StepId.AudioOutput)}
                  onDataUpdate={(data: any) => handleDataUpdate(StepId.AudioOutput, data)}
                />
              </TestStep>
              <TestStep
                data-testid={`step-${StepId.AudioInput}`}
                isCompleted={passed[StepId.AudioInput]}
                hasError={errors[StepId.AudioInput]}
                onActivate={() => handleStep(StepId.AudioInput)}
                title={
                  passed[StepId.AudioInput]
                    ? t('permissionTest.microphoneTest.title.passed')
                    : errors[StepId.AudioInput]
                    ? t('permissionTest.microphoneTest.title.error')
                    : t('permissionTest.microphoneTest.title.default')
                }
                stepRef={audioInputRef}
              >
                <AudioInputTest
                  audio={audio}
                  onCancel={onClose}
                  onContinue={handleNext}
                  onPassed={() => handleStepPassed(StepId.AudioInput, undefined)}
                  onError={() => handleStepError(StepId.AudioInput)}
                  onDataUpdate={(data: unknown) => handleDataUpdate(StepId.AudioInput, data)}
                />
              </TestStep>
              <TestStep
                data-testid={`step-${StepId.VideoInput}`}
                isCompleted={passed[StepId.VideoInput]}
                hasError={errors[StepId.VideoInput]}
                onActivate={() => handleStep(StepId.VideoInput)}
                title={
                  passed[StepId.VideoInput]
                    ? t('permissionTest.videoInputTest.title.passed')
                    : errors[StepId.VideoInput]
                    ? t('permissionTest.videoInputTest.title.error')
                    : t('permissionTest.videoInputTest.title.default')
                }
                stepRef={videoInputRef}
              >
                <VideoInputTest
                  onCancel={onClose}
                  onPassed={() => handleStepPassed(StepId.VideoInput, true)}
                  onError={() => handleStepError(StepId.VideoInput)}
                  onDataUpdate={(data: unknown) => handleDataUpdate(StepId.VideoInput, data)}
                  video={video}
                />
              </TestStep>
            </Stepper>
            {isError && (
              <Box mb={2}>
                <Box my={2}>
                  <Divider />
                </Box>
                <Typography color='error' weight={600}>
                  <Trans
                    i18nKey='permissionTest.error.message'
                    components={[
                      <Link
                        key='help'
                        href='#'
                        variant='inherit'
                        underline
                        weight={600}
                        onClick={() => setShowHelp(true)}
                      />,
                    ]}
                  />
                </Typography>
              </Box>
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default StepperDialog
