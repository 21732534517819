import gql from 'graphql-tag'

export default gql`
  fragment TutorItem on Tutor {
    id
    user {
      id
      firstName
      lastName
      displayName
      countryCode
      image
      currentStatus {
        status
        text
      }
    }
    intros {
      language
      introduction
      motto
    }
    topics {
      name
    }
    slug
  }
`
