import { ReactNode, MutableRefObject } from 'react'

import Box from '@base/Box'

import { Step } from '../TutorModal'

type Props = {
  step: Step
  children: ReactNode
  containerRef: MutableRefObject<null>
}

const ModalInner = ({ step, children, containerRef }: Props) => {
  return (
    <Box
      id='invite'
      width={{ xs: '100vw', md: 'auto' }}
      maxWidth={{ xs: 450, md: 1180 }}
      minHeight={{ xs: '100%', md: 0 }}
      // Mui4 bug: leaving out xs will change order of media queries
      // https://github.com/mui-org/material-ui/issues/16528
      display={{ xs: 'static', md: 'flex' }}
      bgcolor={step > Step.Info ? '#F5F7FA' : '#fff'}
      data-testid='tutorModal'
      ref={containerRef}
    >
      {children}
    </Box>
  )
}

export default ModalInner
