import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useSelector } from 'react-redux'

import { TutorModalPreviewByIdQuery, ShiftDatesQuery, ShiftDatesQueryVariables } from '@gql/__generated__'
import ScheduleButton from '@tutor/ScheduleButton'
import { userSelectors } from '@store/modules/User'
import { AppState } from '@store/Types'

import QUERY from '../../scheduling/AvailableDatesQuery.api'

export type Props = {
  tutor: TutorModalPreviewByIdQuery['tutor']
}

const ScheduleCall = ({ tutor }: Props) => {
  const isStudent = useSelector<AppState, boolean>(state => userSelectors.isStudent(userSelectors.getSelf(state)))
  const { data } = useQuery<ShiftDatesQuery, ShiftDatesQueryVariables>(QUERY, {
    fetchPolicy: 'network-only',
    variables: { tutorId: tutor.id },
    skip: !isStudent,
  })
  const dates = data?.dates || []

  if (!dates.length || !isStudent) return null

  return <ScheduleButton tutor={tutor} />
}

export default ScheduleCall
