import React, { useState, useEffect } from 'react'
import Stepper from '@material-ui/core/Stepper'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useMutation } from '@apollo/react-hooks'
import { makeStyles, Theme } from '@material-ui/core'
import findLastIndex from 'lodash/findLastIndex'
import { useSelector } from 'react-redux'

import Dialog from '@base/Dialog'
import Typography from '@base/Typography'
import { useTranslation } from '@src/i18n'
import Box from '@base/Box'
import { TutorAvatarFragment } from '@gql/__generated__'
import { ScheduleCallMutation, ScheduleCallMutationVariables } from '@gql/__generated__'
import getLogger from '@src/Logger'
import REFETCH_QUERY from '@pages/dashboard/board/studentScheduledCalls/Query.api'
import { AppState } from '@store/Types'
import { userSelectors } from '@store/modules/User'

import PickType from './scheduling/PickType'
import PickDateAndTime from './scheduling/PickDateAndTime'
import PickTutor from './scheduling/PickTutor'
import Finish from './scheduling/Finish'
import Step from './scheduling/Step'
import Response from './scheduling/Response'
import MUTATION from './scheduling/Mutation.api'

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  stepper: {
    [breakpoints.down('xs')]: {
      padding: 8,
    },
  },
  dialogTitle: {
    [breakpoints.down('xs')]: {
      paddingLeft: 8,
    },
  },
}))

export type Props = {
  onClose: () => void
  lessonId: string
  tutor: TutorAvatarFragment
  date?: Date
  duration?: number
  shiftId?: string
  purchaseId?: string
}

export enum StepName {
  Tutor = 'Tutor',
  Type = 'Type',
  Date = 'Date',
  Length = 'Length',
  Complete = 'Complete',
}

type Passed = { [step in StepName]: boolean }

// order for tutor booking
const tutorBookingSteps = [StepName.Tutor, StepName.Type, StepName.Date, StepName.Complete]

// order for package booking
const packageBookingSteps = [StepName.Type, StepName.Tutor, StepName.Date, StepName.Complete]

const Scheduling = ({
  onClose,
  tutor: initialTutor,
  lessonId: initialLessonId,
  date: initialDate,
  duration: initialDuration,
  shiftId: initialShiftId,
  purchaseId,
}: Props) => {
  const classes = useStyles({})
  const { t } = useTranslation()
  const groupId = useSelector<AppState, string>(state => userSelectors.getGroupId(userSelectors.getSelf(state)))
  const initialState = {
    [StepName.Tutor]: !!initialTutor,
    [StepName.Type]: !!initialLessonId,
    [StepName.Date]: false,
    [StepName.Length]: false,
    [StepName.Complete]: false,
  }
  const steps = initialLessonId ? packageBookingSteps : tutorBookingSteps
  const [activeStepId, setActiveStepId] = useState<number>(() => {
    const lastPassedStepId = findLastIndex(steps, stepId => !!initialState[stepId])
    return lastPassedStepId > 0 ? lastPassedStepId + 1 : 0
  })
  const [lesson, setLesson] = useState<string>(initialLessonId)
  const [tutor, setTutor] = useState<TutorAvatarFragment>(initialTutor)
  const [date, setDate] = useState<Date>(() => (initialDate ? new Date(initialDate) : undefined))
  const [shiftId, setShiftId] = useState<string>(initialShiftId || null)
  const [selectedDateTime, setSelectedDateTime] = useState<Date>(null)
  const [duration, setDuration] = useState<number>(initialDuration)
  const [passed, setPassed] = useState<Passed>({ ...initialState })
  const [skipped, setSkipped] = useState<Passed>({ ...initialState })
  const [scheduleCall, { data, loading, error }] = useMutation<ScheduleCallMutation, ScheduleCallMutationVariables>(MUTATION, {
    refetchQueries: [
      {
        query: REFETCH_QUERY,
        variables: {
          isStudent: true,
          showBooked: true,
        },
      },
    ],
  })

  useEffect(() => {
    if (!date || !duration || !shiftId) return
    // if date and duration are set at the beginning make steps passed
    // user is coming with pre-filled data
    setPassed({
      ...passed,
      [StepName.Date]: true,
      [StepName.Length]: true,
      [StepName.Type]: true,
    })
    setActiveStepId(steps.indexOf(StepName.Complete))
  }, [])

  const goBack = (stepId: number) => {
    // only allow modification of passed steps, all subsequent steps must be passed again
    const stepName = steps[stepId]
    if (!passed[stepName] || skipped[stepName]) return
    const newState = { ...initialState }
    steps.map((step, i) => {
      newState[step] = i < stepId
    })
    setPassed(newState)
    setActiveStepId(stepId)
  }

  const handleStepPassed = (step: StepName) => {
    setPassed({
      ...passed,
      [step]: true,
    })
    activeStepId === steps.indexOf(step) && setActiveStepId(s => s + 1)
  }

  const handleStepSkipped = (step: StepName) => {
    setSkipped({
      ...skipped,
      [step]: true,
    })
    handleStepPassed(step)
  }

  const handleSetDate = (day: Date, step: StepName) => {
    setDate(day)
    handleStepPassed(step)
  }

  const handleSetTutor = (tut: TutorAvatarFragment) => {
    setTutor(tut)
    handleStepPassed(StepName.Tutor)
  }

  const bookCall = () => {
    scheduleCall({
      variables: {
        scheduleCallInput: {
          shiftId,
          start: selectedDateTime.toISOString(),
          duration,
          lessonId: lesson,
          tutorId: tutor.id,
          tutorUserId: tutor.user.id,
          purchaseId,
        },
      },
    }).catch(err => {
      getLogger().error({ err }, 'Call cannot be booked.')
    })
  }

  if (data || error) {
    // return <Response call={data?.call} error={error} onClose={onClose} />
    return <Response error={error} onClose={onClose} />
  }

  const componentByStep = {
    [StepName.Tutor]: (
      <PickTutor tutor={tutor} lessonId={lesson} selected={passed[StepName.Tutor]} onSelected={handleSetTutor} />
    ),
    [StepName.Type]: (
      <PickType
        selected={passed[StepName.Type]}
        lessonId={lesson}
        onActivate={() => goBack(steps.indexOf(StepName.Type))}
        onSelected={() => handleStepPassed(StepName.Type)}
        onSkip={() => handleStepSkipped(StepName.Type)}
        setLesson={less => setLesson(less)}
        setDuration={setDuration}
        tutorId={tutor?.id}
      />
    ),
    [StepName.Date]: (
      <PickDateAndTime
        selectedDateTime={selectedDateTime}
        onSetDateTime={setSelectedDateTime}
        shiftId={shiftId}
        onSetDate={day => handleSetDate(day, StepName.Date)}
        tutorId={tutor?.id}
        onSetShift={setShiftId}
        durationInSeconds={duration}
      />
    ),
    [StepName.Complete]: (
      <Finish
        loading={loading}
        onSchedule={bookCall}
        date={selectedDateTime}
        duration={duration}
        groupId={groupId}
        isPackage={!!lesson}
        tutorId={tutor?.id}
        shiftId={shiftId}
      />
    ),
  }

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth='sm'
      data-testid='scheduleDialog'
      onClose={onClose}
      aria-labelledby={t('callInvite.scheduling.title')}
    >
      <Box height={{ xs: '100vh', md: 'initial' }}>
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography weight={600} variant='subtitle1'>
            {t('callInvite.scheduling.title')}
          </Typography>
        </DialogTitle>
        <Stepper
          activeStep={activeStepId}
          nonLinear
          orientation='vertical'
          connector={null}
          className={classes.stepper}
        >
          {steps.map((stepName: StepName, stepId) => (
            <Step
              key={`step-${stepId}`}
              data-testid={`step-${stepId}`}
              isCompleted={passed[stepName]}
              onActivate={() => goBack(stepId)}
              title={t(`callInvite.scheduling.steps.${stepName}.title`)}
            >
              {componentByStep[stepName]}
            </Step>
          ))}
        </Stepper>
      </Box>
    </Dialog>
  )
}

export default Scheduling
