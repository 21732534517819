import getConfig from 'next/config'

import Typography from '@base/Typography'
import { getIntervalAsHumanString } from '@helpers/dateTime'
import ToggleButtons, { ToggleButton } from '@base/ToggleButtons'

const { CALL_DURATIONS, DISABLED_CALL_DURATIONS } = getConfig().publicRuntimeConfig

type DurationOption = {
  duration: number
  selected?: boolean
  schedulable?: boolean
  trial?: boolean
}

type Props = {
  duration: number
  onSetDuration: (duration: number) => void
  booking?: boolean
  durationOptions?: DurationOption[]
  isTrial?: boolean
}

const Duration = ({ duration, onSetDuration, booking, isTrial, durationOptions = CALL_DURATIONS }: Props) => {
  return (
    <ToggleButtons exclusive onChange={(_e, val) => onSetDuration(val)} size='medium'>
      {durationOptions.map(({ duration: callDuration, schedulable, trial }, index) => {
        if (isTrial && !trial) return // show only allowed durations for trial user
        if (booking && !isTrial && !schedulable) return // show only allowed durations for booking
        return (
          <ToggleButton
            key={index}
            value={callDuration}
            disabled={DISABLED_CALL_DURATIONS.includes(callDuration)}
            selected={duration === callDuration}
            data-testid={`duration-${callDuration / 60}`}
            {...(callDuration < 60 && { style: { flexShrink: 2 } })} // Prevent test call from breaking layout
          >
            <Typography component='span' variant='body1' weight={600} color='inherit'>
              {getIntervalAsHumanString(
                callDuration,
                callDuration < 60 ? 1 : 3, // Prevent test call from breaking layout
              )}
            </Typography>
          </ToggleButton>
        )
      })}
    </ToggleButtons>
  )
}

export default Duration
