import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import { Theme, useTheme } from '@material-ui/core/styles'
import Box from '@base/Box'
import { AppState } from '@store/Types'
import { userSelectors } from '@store/modules/User'
import BackButton from './BackButton'
import { Step } from '../TutorModal'

type Props = {
  minStep: Step
  step: Step
  goBack: () => void
  children: ReactNode
}

const Buttons = ({ minStep, step, goBack, children }: Props) => {
  const theme: Theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTutor = useSelector<AppState, boolean>(state => userSelectors.isTutor(userSelectors.getSelf(state)))

  if (isTutor) return null

  return (
    <Box
      key={step}
      position={{ xs: 'fixed', md: 'static' }}
      width='100%'
      maxWidth={450}
      bgcolor={{ xs: step > Step.Info ? '#F5F7FA' : '#fff', md: 'transparent' }}
      px={{ xs: 2.5, md: 4 }}
      py={{ xs: 2.5, md: 3 }}
      bottom={{ xs: 0, md: undefined }}
      zIndex={1}
    >
      <Grid container wrap='nowrap'>
        <BackButton show={step > minStep && !isMobile} onClick={goBack} />
        <Box width='100%'>{children}</Box>
      </Grid>
    </Box>
  )
}

export default Buttons
