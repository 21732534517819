import { ComponentProps } from 'react'

import Box from '@base/Box'
import Link from '@base/Link'
import Avatar from '@base/Avatar'
import Typography from '@base/Typography'
import getTutorLink from '@tutor/getTutorLink'
import { TutorAvatarFragment } from '@gql/__generated__'
import Tooltip from '@base/Tooltip'
import { Theme, useTheme } from '@material-ui/core/styles'
import { useTranslation } from '@src/i18n'
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  text: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '85px',
    [breakpoints.down('xs')]: {
      width: '75px',
    },
  },
}))

type Props = {
  tutor: TutorAvatarFragment
  href?: string
  as?: string
  showStatus: boolean
  boxProps?: ComponentProps<typeof Box>
}

const TutorAvatarComponent = ({ tutor, href, as, showStatus, boxProps }: Props) => {
  const classes = useStyles({})
  const theme: Theme = useTheme()
  const { t } = useTranslation()
  const isXs = useMediaQuery(theme.breakpoints.down('xs'))
  const tutorLink = href
    ? getTutorLink({
      baseUrl: href,
      asUrl: as,
      slug: tutor.slug,
      tutorId: tutor.id,
    })
    : null

  const content = (
    <>
      <Avatar alt={tutor.user.firstName} src={tutor.user.image} size={isXs ? 50 : 76} style={{ margin: '0 auto' }} />
      <Box mt={{ xs: 0.5, sm: 1 }}>
        <Typography component='div' variant={isXs ? 'caption' : 'body1'} className={classes.text}>
          {tutor.user.firstName}
        </Typography>
      </Box>
    </>
  )

  return (
    <Box textAlign='center' key={tutor.id} data-testid='tutor' position='relative' {...boxProps}>
      {showStatus && (
        <Tooltip
          variant='body1'
          weight={600}
          title={t('homepage.tutorsOnline.free')}
          boxStyle={{
            px: 3.5,
            py: 1.5,
            mb: -4,
            bgcolor: theme.palette.customBg['hintGreen'],
          }}
        >
          <Box
            width={15}
            height={15}
            borderRadius='100%'
            position='absolute'
            top={{ xs: 0, sm: 3 }}
            right={{ xs: 0, sm: 3 }}
            bgcolor={theme.palette.customCol['mountain']}
            zIndex={1}
          />
        </Tooltip>
      )}
      {tutorLink ? <Link hrefAs={tutorLink}>{content}</Link> : content}
    </Box>
  )
}

export default TutorAvatarComponent
