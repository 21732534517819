import { useMemo } from 'react'
import { Query } from '@apollo/react-components'
import { useTranslation } from '@src/i18n'
import getConfig from 'next/config'

import Button from '@base/Button'
import Typography from '@base/Typography'
import BoxContainer from '@base/BoxContainer'
import Box from '@base/Box'
import Link from '@base/Link'
import links from '@api/links'
import TutorItem, { Props as TutorItemProps } from '@tutor/TutorItem'
import TutorItemSkeleton from '@components/tutor/tutorItem/TutorItemSkeleton'
import {
  GetOnlineTutorsQuery,
  GetOnlineTutorsQueryVariables,
  GetFutureTutorsQuery,
  GetFutureTutorsQueryVariables,
  Tutor,
} from '@gql/__generated__'

import GET_ONLINE_TUTORS from './tutorsOnline/getOnlineTutorsQuery.api'
import GET_FUTURE_TUTORS from './tutorsOnline/getFutureTutorsQuery.api'

const { ONLINE_TUTORS_HP_COUNT } = getConfig().publicRuntimeConfig

type TutorsProps = {
  onlineTutors: TutorItemProps['tutor'][],
  offlineTutors: (Pick<Tutor, 'availability'> & TutorItemProps['tutor'])[],
  refetch: any
  loading: boolean
}
const Tutors = ({ onlineTutors, offlineTutors, refetch, loading }: TutorsProps) => {
  const tutors = useMemo(() => {
    const onlineIds = onlineTutors.map(({ id }) => id)
    const sortedOffline = offlineTutors.slice().sort((a, b) => {
      if (!a.availability || !b.availability) return // staging can return tutors without availablity, shouldn't happen on production
      return new Date(a.availability.start).getTime() - new Date(b.availability.start).getTime()
    })
    return onlineTutors
      .concat(sortedOffline.filter(({ id }) => !onlineIds.includes(id)))
      .slice(0, ONLINE_TUTORS_HP_COUNT)
  }, [onlineTutors, offlineTutors])

  if (tutors.length > 0) {
    return (
      <>
        {tutors.map(tutor => (
          <TutorItem key={tutor.id} {...{ tutor, refetch }} />
        ))}
      </>
    )
  }

  if (loading)
    return (
      <>
        {[...Array(ONLINE_TUTORS_HP_COUNT)].map((_, id) => (
          <TutorItemSkeleton key={id} />
        ))}
      </>
    )

  return null
}

type Props = {
  position?: 'index' | 'survey'
}

const TutorsOnline = ({ position = 'index' }: Props) => {
  const { t } = useTranslation()

  return (
    <Query<GetOnlineTutorsQuery, GetOnlineTutorsQueryVariables>
      query={GET_ONLINE_TUTORS}
      ssr={false}
      variables={{
        first: ONLINE_TUTORS_HP_COUNT,
      }}
    >
      {({ loading: loadingOnline, data: dataOnline, refetch }) => {
        const { getOnlineTutors: onlineTutors = [] } = dataOnline || {}

        return (
          <Query<GetFutureTutorsQuery, GetFutureTutorsQueryVariables>
            query={GET_FUTURE_TUTORS}
            ssr={false}
            variables={{
              first: ONLINE_TUTORS_HP_COUNT,
            }}
          >
            {({ loading: loadingOffline, data: dataOffline, error: errorOffline = null }) => {
              const loadedOffline = !loadingOffline && dataOffline && !errorOffline
              const { getFutureTutors: offlineTutors = [] } = dataOffline || {}

              if (loadedOffline && !offlineTutors.length && !onlineTutors.length) {
                return null
              }

              return (
                <BoxContainer
                  component='section'
                  textAlign='center'
                  py={position === 'survey' ? 0 : 6}
                  innerProps={{ maxWidth: 1920, flexDirection: 'column' }}
                >
                  {position !== 'survey' && (
                    <Typography variant='h3'>{t('terapio:homepage.therapists.title')}</Typography>
                  )}
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='stretch'
                    flexWrap='wrap'
                    px={0}
                    py={position === 'survey' ? 0 : 5}
                  >
                    <Tutors {...{ onlineTutors, offlineTutors, refetch }} loading={loadingOnline || loadingOffline} />
                  </Box>
                  {position !== 'survey' && (
                    <Box width='100%' maxWidth={280} mx='auto'>
                      <Link fullWidth href={links.tutorSearch}>
                        <Button fullWidth color='primary' variant='contained'>
                          {t('homepage.tutorsOnline.button')}
                        </Button>
                      </Link>
                    </Box>
                  )}
                </BoxContainer>
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
}
export default TutorsOnline
