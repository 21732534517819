import Router from 'next/router'

import Box from '@base/Box'
import Typography from '@base/Typography'
import Button from '@base/Button'
import Image from '@base/Image'
import Dialog from '@base/Dialog'
import links from '@api/links'
import securityBadge from '@static/icons/security-badge.svg'
import { useTranslation } from '@src/i18n'
import getLogger from '@src/Logger'

import watch from './sendInviteError/watch.svg'
import wallet from './sendInviteError/wallet.svg'

const image = {
  PENDING_INVITE: {
    src: watch,
    alt: 'watch',
  },
  TUTOR_BUSY: {
    src: watch,
    alt: 'watch',
  },
  INSUFFICIENT_FUNDS: {
    src: wallet,
    alt: 'wallet',
  },
  UNSUPPORTED_BROWSER: {
    src: securityBadge,
    alt: 'security badge',
  },
}

type Props = {
  open: boolean
  error: string
  onClose: () => void
  unsupported?: string
}

const SendInviteError = ({ open, onClose, error, unsupported }: Props) => {
  const { t } = useTranslation()
  const imageProps = image[error]

  const handleClose = () => {
    if (error === 'INSUFFICIENT_FUNDS') {
      Router.push(links.topUp).catch(err => getLogger().warn({ err }, 'Browser navigation failed'))
      return
    }
    onClose()
  }

  return (
    <Dialog
      {...{ open, onClose }}
      aria-labelledby={t([`callInvite.error.${error}.title`, 'callInvite.error.default.title'])}
    >
      <Box maxWidth={{ sm: 350 }} mx={{ xs: 2, sm: 6 }} my={{ xs: 3, sm: 6 }} textAlign='center'>
        <Box position='relative' display='inline-block'>
          {imageProps && <Image width={150} height={150} {...imageProps} />}
        </Box>
        <Box mt={3.5} mb={1}>
          <Typography variant='h5' weight={600}>
            {t([`callInvite.error.${error}.title`, 'callInvite.error.default.title'])}
          </Typography>
        </Box>
        <Box mb={3.5}>
          <Typography variant='body1'>
            {error === 'UNSUPPORTED_BROWSER'
              ? t([`callInvite.error.${error}.message.${unsupported}`, `callInvite.error.${error}.message.default`])
              : t([`callInvite.error.${error}.message`, 'callInvite.error.default.message'])}
          </Typography>
        </Box>
        <Box maxWidth={200} mx='auto'>
          <Button fullWidth color='primary' variant='contained' onClick={handleClose}>
            {t([`callInvite.error.${error}.button`, 'callInvite.error.default.button'])}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default SendInviteError
