import TutorItem, { Props as TutorItemProps } from '@tutor/TutorItem'
import Box from '@material-ui/core/Box'
import Typography from '@base/Typography'
import Container from '@base/Container'

import { useTranslation } from '@src/i18n'

import jan from './team/jan.svg'
import chadi from './team/chadi.svg'

const OurTeam = () => {
  const { t, i18n } = useTranslation()

  const team: TutorItemProps['tutor'][] = [
    {
      id: '1',
      intros: [{
        __typename: 'TutorIntro' as const,
        language: i18n.language,
        introduction: t('terapio:homepage.ourTeam.chadi.introduction'),
        motto: null
      }],
      user: {
        displayName: t('terapio:homepage.ourTeam.chadi.name'),
        firstName: t('terapio:homepage.ourTeam.chadi.name'),
        role: t('terapio:homepage.ourTeam.chadi.role'),
        image: chadi,
      },
      topics: [],
      slug: ''
    },
    {
      id: '3',
      intros: [{
        __typename: 'TutorIntro' as const,
        language: i18n.language,
        introduction: t('terapio:homepage.ourTeam.jan.introduction'),
        motto: null
      }],
      user: {
        displayName: t('terapio:homepage.ourTeam.jan.name'),
        firstName: t('terapio:homepage.ourTeam.jan.name'),
        role: t('terapio:homepage.ourTeam.jan.role'),
        image: jan,
      },
      topics: [],
      slug: ''
    },
  ]

  const h1 = t('terapio:homepage.ourTeam.title')

  return (
    <Container
      component='section'
      // Fit content to lower viewport on mobile
      py={6}
      innerProps={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1200,
        justifyContent: 'center',
        position: 'relative',
      }}
      position={'relative'}
    >
      <Box textAlign={'center'}>
        <Typography variant='h3' component='h2'>
          <div dangerouslySetInnerHTML={{ __html: h1 }} />
        </Typography>
      </Box>
      <Box display='flex' justifyContent='center' alignItems='stretch' flexWrap='wrap' textAlign={'center'} px={0}>
        {team.map(member => (
          <TutorItem key={member.id} tutor={member} localSrc={true} />
        ))}
      </Box>
    </Container>
  )
}

export default OurTeam
