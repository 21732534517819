import gql from 'graphql-tag'

const ScheduledCall = gql`
  fragment ScheduledCall on ScheduledCall {
    id
    lessonId
    end
    start
    participants {
      id
      profileId
    }
  }
`

export default ScheduledCall
