import React from 'react'
import { makeStyles } from '@material-ui/core'
import SuccessIcon from '@material-ui/icons/EventAvailable'
import ErrorIcon from '@material-ui/icons/EventBusy'
import { ApolloError } from 'apollo-boost'

import Dialog from '@base/Dialog'
import Typography from '@base/Typography'
import { useTranslation } from '@src/i18n'
import Box from '@base/Box'
import Button from '@base/Button'
import Link from '@base/Link'
import { apiErrorToCode } from '@helpers/api'
import links from '@api/links'
// import { ScheduleCall_call } from '@gql/__generated__'

const useStyles = makeStyles({
  icon: {
    fontSize: '5rem',
  },
})

type Props = {
  // call: ScheduleCall_call // TODO: show dashboard booking component as call summary
  error: ApolloError
  onClose: () => void
}

const Response = ({ error, onClose }: Props) => {
  const classes = useStyles({})
  const { t } = useTranslation()
  const title = error ? t('callInvite.scheduling.error.title') : t('callInvite.scheduling.success.title')

  return (
    <Dialog open closeBtn={!onClose} onClose={onClose} aria-labelledby={title}>
      <Box maxWidth={320} mx={{ xs: 2, sm: 6 }} my={{ xs: 3, sm: 6 }} textAlign='center'>
        <Box position='relative' display='inline-block'>
          {error ? (
            <ErrorIcon fontSize='large' color='error' className={classes.icon} data-testid='errorIcon' />
          ) : (
            <SuccessIcon fontSize='large' color='primary' className={classes.icon} data-testid='successIcon' />
          )}
        </Box>
        <Box mt={2} mb={1}>
          <Typography variant='h5' weight={600}>
            {title}
          </Typography>
        </Box>
        <Box mb={3.5}>
          <Typography>
            {error
              ? t([`callInvite.scheduling.error.${apiErrorToCode(error)}`, 'callInvite.scheduling.error.default'])
              : t('callInvite.scheduling.success.subtitle')}
          </Typography>
        </Box>
        <Box maxWidth={200} mx='auto'>
          {error ? (
            <Button variant='contained' onClick={onClose}>
              {t('callInvite.scheduling.error.button')}
            </Button>
          ) : (
            <Link href={links.dashboard} data-testid='successLink' onClick={onClose}>
              <Button variant='contained' color='primary'>
                {t('callInvite.scheduling.success.button')}
              </Button>
            </Link>
          )}
        </Box>
      </Box>
    </Dialog>
  )
}

export default Response
