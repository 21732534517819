import { DocumentNode } from 'graphql'
import { DataProxy } from 'apollo-cache'

import { CallInviteStatus, InviteUpdateFragment, AllInvitesQuery } from '@gql/__generated__'
import { readQueryIfAny } from '@helpers/apollo'

import QUERY from './invitesResolver/Query.api'
import INVITE_UPDATE from './InviteUpdateFragment.api'
import { addToArray, removeFromArray } from './utils'

/**
 * Invite client states:
 * pending – invites with CallInviteStatus = InviteSent
 * finished – invites with CallInviteStatus = InviteCanceled / InviteExpired / InviteRejected / InviteAccepted but not acknowledged by user
 * resolved – all finished invites that was acknowledged by user (user clicked on "close" button)
 */

const readQuery = (proxy: DataProxy): AllInvitesQuery => {
  let queryData = readQueryIfAny<AllInvitesQuery>(proxy, {
    query: QUERY,
  })
  if (queryData === undefined) {
    queryData = { pendingInvites: [], finishedInvites: [] }
  }

  return queryData
}

export const readInviteById = <FragmentType, TVariables = any>(
  proxy: DataProxy,
  roomId: string,
  fragment: DocumentNode,
  fragmentName?: string,
): FragmentType | null => {
  let invite
  try {
    invite = proxy.readFragment<FragmentType, TVariables>({
      fragment,
      fragmentName,
      id: `Invite:${roomId}`,
    })
  } catch {
    invite = null
  }

  return invite
}

export const setInviteStatus = (proxy: DataProxy, roomId: string, callInviteStatus: CallInviteStatus): void => {
  proxy.writeFragment<InviteUpdateFragment>({
    fragment: INVITE_UPDATE,
    data: {
      id: roomId,
      roomId,
      room: {
        id: roomId,
        callInviteStatus,
        __typename: 'Room',
      },
      __typename: 'Invite',
    },
    id: `Invite:${roomId}`,
  })
}

export const setInvitePending = (proxy: DataProxy, roomId: string) => {
  const queryData = readQuery(proxy)
  proxy.writeQuery<AllInvitesQuery>({
    query: QUERY,
    data: {
      ...queryData,
      pendingInvites: addToArray(queryData.pendingInvites, roomId),
      finishedInvites: removeFromArray(queryData.finishedInvites, roomId),
    },
  })
}

export const setInviteFinished = (proxy: DataProxy, roomId: string) => {
  const queryData = readQuery(proxy)

  proxy.writeQuery<AllInvitesQuery>({
    query: QUERY,
    data: {
      ...queryData,
      pendingInvites: removeFromArray(queryData.pendingInvites, roomId),
      finishedInvites: addToArray(queryData.finishedInvites, roomId),
    },
  })
}

export const setInviteResolved = (proxy: DataProxy, roomId: string) => {
  const queryData = readQuery(proxy)

  proxy.writeQuery<AllInvitesQuery>({
    query: QUERY,
    data: {
      ...queryData,
      pendingInvites: removeFromArray(queryData.pendingInvites, roomId),
      finishedInvites: removeFromArray(queryData.finishedInvites, roomId),
    },
  })
}
