import { useMutation } from '@apollo/react-hooks'
import CheckIcon from '@material-ui/icons/Check'

import Box from '@base/Box'
import Typography from '@base/Typography'
import ProgressButton from '@base/ProgressButton'
import { useTranslation } from '@src/i18n'
import { VerifyEmailMutation } from '@gql/__generated__'
import getLogger from '@src/Logger'

import MUTATION from './resendEmail/Mutation.api'

type Props = {
  refetch: () => void
}

const ResendEmail = ({ refetch }: Props) => {
  const { t } = useTranslation()
  const [verifyEmail, { data, loading, error }] = useMutation<VerifyEmailMutation>(MUTATION)
  const isEmailSent = !!data

  const handleVerify = () => {
    verifyEmail().catch(err => {
      getLogger().error({ err }, 'Resend email failed')
    })
  }

  return (
    <>
      {error ? (
        <Box mb={1}>
          <Typography variant='caption' color='error'>
            {t('updateProfile.modal.email.fail')}
          </Typography>
        </Box>
      ) : data ? (
        <Box display='flex' color='#2EE696' mb={1}>
          <Box component='span' display='flex' mr={1}>
            <CheckIcon fontSize='small' />
            <Typography variant='caption'>{t('updateProfile.modal.email.success')}</Typography>
          </Box>
        </Box>
      ) : null}
      <ProgressButton
        color='primary'
        variant='contained'
        size='small'
        onClick={isEmailSent ? refetch : handleVerify}
        inProgress={loading}
        disabled={loading}
      >
        {isEmailSent ? t('updateProfile.modal.email.refetchButton') : t('updateProfile.modal.email.button')}
      </ProgressButton>
    </>
  )
}

export default ResendEmail
