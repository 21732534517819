import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Router, { withRouter } from 'next/router'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { animateScroll } from 'react-scroll'
import { useQuery } from '@apollo/react-hooks'
import getConfig from 'next/config'
import links from '@api/links'
import Dialog from '@base/Dialog'
import {
  TutorModalDetailQuery,
  TutorModalDetailQueryVariables,
  TutorModalPreviewByIdQuery,
  TutorModalPreviewByIdQueryVariables,
  TutorModalPreviewBySlugQuery,
  TutorModalPreviewBySlugQueryVariables,
  TutorModalSentInvitesQuery,
  TutorModalSentInvitesQueryVariables,
} from '@gql/__generated__'
import analytics from '../../Analytics'
import impression from '@service/Impression'
import PermissionTest from '@common/PermissionTest'
import getLogger from '../../Logger'
import useBrowserSupport from '@hooks/useBrowserSupport'
import { AppState } from '@store/Types'
import { userSelectors } from '@store/modules/User'
import { WithRouterProps } from 'next/dist/client/with-router'
import { snackbarOperations } from '@store/modules/Snackbar'
import UpdateProfileModal from '@common/UpdateProfileModal'
import SendInviteError from '@components/invite/SendInviteError'
import usePrevious from '@hooks/usePrevious'
import { useTranslation } from '@src/i18n'
import Loading from './tutorModal/Loading'
import Error from './tutorModal/Error'
import TutorInfo from './tutorModal/TutorInfo'
import Order from './tutorModal/Order'
import ModalInner from './tutorModal/ModalInner'
import InfoWrapper from './tutorModal/InfoWrapper'
import BackButton from './tutorModal/BackButton'
import DETAIL from './tutorModal/detailQuery.api'
import SENT_INVITES from './tutorModal/SentInvitesQuery.api'
import PREVIEW_BY_ID from './tutorModal/previewByIdQuery.api'
import PREVIEW_BY_SLUG from './tutorModal/previewBySlugQuery.api'

const { CALL_DURATIONS } = getConfig().publicRuntimeConfig

const desktopBreakpoint = 'md'

export enum Step {
  Info = 0,
  Selection = 1,
  Details = 2,
  Summary = 3,
}

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  paper: {
    height: '100%',
    [breakpoints.up(desktopBreakpoint)]: {
      maxWidth: 'none',
      height: 'auto',
    },
  },
  container: {
    alignItems: 'start',
  },
  iconShadow: {
    [breakpoints.down('sm')]: {
      color: '#ff0000',
      filter: 'drop-shadow(0px 0px 2px #555)',
    },
  },
}))

type Props = {
  id?: string
  initialStep: Step
  slug: string
  initialLessonId: string
  scheduledCallId: string
  onClose: () => void
  onTherapistPicked?: (therapistId: string) => void
  pickTherapistText?: string
}

const TutorModal = ({
  onClose,
  onTherapistPicked,
  id,
  slug,
  initialLessonId,
  initialStep = Step.Info,
  scheduledCallId,
  pickTherapistText,
}: Props) => {
  const resultById = useQuery<TutorModalPreviewByIdQuery, TutorModalPreviewByIdQueryVariables>(PREVIEW_BY_ID, {
    skip: id === undefined,
    variables: {
      id,
    },
  })

  const resultBySlug = useQuery<TutorModalPreviewBySlugQuery, TutorModalPreviewBySlugQueryVariables>(PREVIEW_BY_SLUG, {
    skip: id !== undefined,
    variables: {
      slug,
    },
  })
  const { data: { tutor = null } = {}, error: previewError, loading: previewLoading, refetch } =
    id !== undefined ? resultById : resultBySlug
  const previewLoaded = !previewLoading && !previewError

  const isStudent = useSelector<AppState, boolean>(state => userSelectors.isStudent(userSelectors.getSelf(state)))
  const isLoggedIn = useSelector<AppState, boolean>(state => userSelectors.isLoggedIn(userSelectors.getSelf(state)))

  const {
    data: { tutor: tutorDetail = null, scheduledCall } = {},
    error: detailError,
    loading: detailLoading,
  } = useQuery<TutorModalDetailQuery, TutorModalDetailQueryVariables>(DETAIL, {
    fetchPolicy: 'network-only',
    variables: {
      slug,
      isScheduledCall: Boolean(scheduledCallId),
      scheduledCallId: scheduledCallId || '',
    },
    ssr: false,
  })

  // @TODO Move student's pendingInvites to @client cache so they are not synced with API, then remove fetch-policy
  const { data: { finishedInvites = [], pendingInvites = [] } = {} } = useQuery<
    TutorModalSentInvitesQuery,
    TutorModalSentInvitesQueryVariables
  >(SENT_INVITES, {
    fetchPolicy: 'cache-only',
    variables: {
      isStudent,
    },
  })

  useEffect(() => {
    if (!scheduledCall) {
      return
    }

    setLesson(scheduledCall.lessonId)
    setDuration((new Date(scheduledCall.end).getTime() - new Date(scheduledCall.start).getTime()) / 1000)
    goToStep(Step.Details)
  }, [scheduledCall])

  /**
   * Checks if user is allowed to visit the step
   * Returns error code if not allowed, null otherwise
   */
  const checkStep = (stepNumber: Step): string => {
    if (stepNumber > Step.Info && !isLoggedIn) {
      return 'UNAUTHORIZED_ERROR'
    }
    return null
  }

  const isOnline = tutor && tutor.user.currentStatus.status === 'Online'
  const classes = useStyles({})
  const theme: Theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const [inviteError, setInviteError] = useState(null)
  const minStep = scheduledCallId ? Step.Details : Step.Info
  const [step, setStep] = useState(() => (checkStep(initialStep) === null ? initialStep : Step.Info))
  const prevStep = usePrevious(step)
  const [duration, setDuration] = useState<number>(CALL_DURATIONS.find(({ selected }) => selected).duration)
  const [lessonId, setLesson] = useState(initialLessonId)
  const [stepError, setStepError] = useState<string>(() => checkStep(initialStep))
  const [permissionCheck, setPermissionCheck] = useState(null)
  const [profileCheck, setProfileCheck] = useState(null)
  const { t } = useTranslation()
  const logger = getLogger()

  const unsupported = useBrowserSupport()
  const inviteContainer = useRef(null)

  const goToStep = (stepNumber: Step) => {
    const stepErr = checkStep(stepNumber)
    if (stepErr) {
      return setStepError(stepErr)
    }

    setStep(stepNumber)
  }

  const goToNext = () => {
    // skip selection when:
    // - tutors are filtered by lesson
    // - (or) user is in trial call
    if (step === Step.Info && initialLessonId) {
      return goToStep(Step.Details)
    }
    // when user goes back to selection but doesn't have available lessons
    if (step === Step.Selection && prevStep === Step.Details && lessonId === null) {
      return goToStep(Step.Info)
    }
    goToStep(step + 1)
  }

  const goBack = () => {
    // skip selection when:
    // - tutors are filtered by lesson
    // - (or) user is in trial call
    if (step === Step.Details && initialLessonId) {
      return goToStep(Step.Info)
    }
    // reset lessonId to default when going back to selection
    if (lessonId && step === Step.Details) setLesson(initialLessonId)
    goToStep(step - 1)
  }

  useEffect(() => {
    if (stepError === 'UNAUTHORIZED_ERROR') {
      dispatch(snackbarOperations.open(t('callInvite.notLoggedIn'), 'error'))
      Router.push(links.login.href, links.login.as).catch(err => getLogger().warn({ err }, 'Browser navigation failed'))
    }
  }, [stepError])

  useEffect(() => {
    if (!previewLoaded) {
      return
    }
    // Ignore step set using `initialStep` prop when tutor is not online
    if (!isOnline) {
      goToStep(Step.Info)
    }
    // Push event after modal is loaded
    analytics.pushEvent('tutor/modal', {
      tutorName: tutor.user.firstName,
      ecommerce: {
        detail: {
          products: [{ name: tutor.user.firstName, id: tutor.id, category: 'call_EN' }],
        },
      },
    })
    impression.push('Tutor', tutor.user.id)
  }, [previewLoaded])

  const handleStepChange = () => {
    if (step === Step.Details && isMobile) {
      const inviteEl = inviteContainer.current
      if (!inviteEl) return
      animateScroll.scrollToBottom({
        container: inviteEl.parentElement,
      })
    }

    if (step >= Step.Selection) {
      // There is initialStep prop, so we cannot check for particular step number
      // Check only once
      if (permissionCheck === null) {
        setPermissionCheck(true)
      }
      // If user doesn't have profile information filled send them to update profile
      // Check only once
      if (profileCheck === null) {
        setProfileCheck(true)
      }
    }
  }

  useEffect(() => {
    if (!previewLoaded) {
      return
    }

    handleStepChange()
  }, [previewLoaded, step])

  useEffect(() => {
    if (permissionCheck || permissionCheck === null) return
    if (step === Step.Info || !isMobile) return

    // scroll after permission test passes
    setTimeout(() => {
      animateScroll.scrollToBottom({
        container: inviteContainer.current.parentElement,
      })
    }, 100)
  }, [permissionCheck])

  useEffect(() => {
    if (!unsupported || step === Step.Info) return
    setInviteError('UNSUPPORTED_BROWSER')
  }, [unsupported, step])

  useEffect(() => {
    inviteError && logger.warn({ obj: { inviteError } }, 'Error when sending invite')
  }, [inviteError])

  if (pendingInvites.length > 0 || finishedInvites.length > 0) {
    return <SendInviteError open={true} onClose={onClose} error='PENDING_INVITE' unsupported={unsupported} />
  }

  if (inviteError) {
    return <SendInviteError open={true} onClose={onClose} error={inviteError} unsupported={unsupported} />
  }

  const handleTherapistPicked = (therapistId: string) => {
    onClose()
    if (onTherapistPicked) {
      onTherapistPicked(therapistId)
      return
    }
  }

  return (
    <Dialog
      classes={{
        paper: classes.paper,
        container: classes.container,
      }}
      open={true}
      onClose={onClose}
      aria-labelledby={t('common.therapistProfile')}
    >
      <ModalInner step={step} containerRef={inviteContainer}>
        <Loading loading={previewLoading} />
        <Error error={Boolean(previewError || detailError)} />
        {previewLoaded && !detailError && (
          <>
            <InfoWrapper step={step}>
              <TutorInfo
                // Prevents loaded content to unexpectedly change height
                BoxProps={{ minHeight: step === Step.Info ? { sm: 490 } : 'auto' }}
                showDescription={!lessonId}
                tutor={{ ...tutor, ...tutorDetail }}
                assignButtonCaption={pickTherapistText}
                onTherapistPicked={handleTherapistPicked}
              />
              {lessonId && (
                <Order
                  minStep={minStep}
                  step={step}
                  goToNext={goToNext}
                  goBack={goBack}
                  tutor={tutor}
                  prices={tutorDetail?.prices || null}
                  isLoggedIn={isLoggedIn}
                  lessonId={lessonId}
                  loading={previewLoading || detailLoading}
                  setLesson={setLesson}
                  onClose={onClose}
                  scheduledCall={scheduledCall}
                  scheduledCallId={scheduledCallId}
                  setInviteError={setInviteError}
                  refetch={refetch}
                  duration={duration}
                  setDuration={setDuration}
                />
              )}
            </InfoWrapper>
          </>
        )}
      </ModalInner>
      <BackButton show={step > minStep && previewLoaded && isMobile} onClick={goBack} />
      {profileCheck ? (
        <UpdateProfileModal onClose={onClose} onResolve={() => setProfileCheck(false)} />
      ) : (
        permissionCheck && <PermissionTest onClose={onClose} onPassed={() => setPermissionCheck(false)} />
      )}
    </Dialog>
  )
}

type WrapperProps = WithRouterProps & {
  baseUrl: string
  asUrl?: string
  conversationId?: string
  onTherapistPicked?: (therapistId: string) => void
  pickTherapistText?: string
  onClose?: () => void
  therapistSlug?: string
}

const TutorModalWrapper = ({
  baseUrl,
  asUrl,
  router,
  conversationId,
  pickTherapistText,
  onTherapistPicked,
  onClose,
  therapistSlug,
}: WrapperProps) => {
  const {
    query: { scheduledCallId, slug, step, tutorId, lessonId, ...query },
  } = router

  const detailSlug = therapistSlug || slug

  if (!detailSlug) {
    return null
  }

  const asParams = lessonId ? { lessonId } : {} // show only selected params in asUrl

  const handleClose = () => {
    if (onClose) {
      onClose()
    } else {
      router
        .push(
          { pathname: baseUrl, query: { scroll: false, ...query, lessonId, conversationId } },
          conversationId ? `${baseUrl}/${conversationId}` : { pathname: asUrl || baseUrl, query: asParams },
        )
        .catch(err => getLogger().warn({ err }, 'Browser navigation failed'))
    }
  }

  const initialStep = scheduledCallId ? Step.Details : toStep(step as string)

  return (
    <TutorModal
      id={tutorId as string}
      slug={detailSlug as string}
      initialStep={initialStep}
      initialLessonId={lessonId as string}
      scheduledCallId={scheduledCallId as string}
      onClose={handleClose}
      onTherapistPicked={onTherapistPicked}
      pickTherapistText={pickTherapistText}
    />
  )
}

const toStep = (step: string): Step =>
  (step && typeof step === 'string' && !isNaN(parseInt(step)) ? parseInt(step) : undefined) as Step

export default withRouter<WrapperProps>(TutorModalWrapper)
