import { ComponentProps } from 'react'
import Box from '@base/Box'
import Medallion from '@common/Medallion'
import { TutorModalDetailQuery } from '@gql/__generated__'

type Props = {
  BoxProps?: ComponentProps<typeof Box>
  showDescription: boolean
  tutor: TutorModalDetailQuery['tutor']
  assignButtonCaption: string
  onTherapistPicked?: (therapistId: string) => void
}

const TutorInfo = ({ BoxProps, tutor, assignButtonCaption, onTherapistPicked, showDescription = true }: Props) => (
  <Box
    display='flex'
    flexDirection='column'
    flexShrink={0}
    width='100%'
    bgcolor='#fff'
    px={{ xs: 2.5, md: 4 }}
    py={4}
    textAlign={{ xs: 'center', md: 'left' }}
    {...BoxProps}
  >
    <Box flexGrow={1}>
      <Medallion
        tutor={tutor}
        withIntro={showDescription}
        withTopics={showDescription}
        withPackages={showDescription}
        withAvailability={showDescription}
        assignButtonCaption={assignButtonCaption}
        onTherapistPicked={onTherapistPicked}
      />
    </Box>
  </Box>
)

export default TutorInfo
