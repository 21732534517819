import { makeStyles, Theme, useTheme } from '@material-ui/core'
import PlayArrow from '@material-ui/icons/PlayArrow'
import Stop from '@material-ui/icons/Stop'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import { EventHandler } from 'react'

import Box from '@base/Box'

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
  },
})

type Props = {
  play: boolean
  onClick?: EventHandler<any>
}

const PlayButton = ({ onClick, play }: Props) => {
  const classes = useStyles({})
  const theme = useTheme<Theme>()

  return (
    <Box
      bgcolor={lighten(theme.palette.primary.main, 0.6)}
      borderRadius='50%'
      className={classes.root}
      display='inline-flex'
      p={0.5}
      onClick={onClick}
    >
      <Box bgcolor={theme.palette.common.white} borderRadius='50%' display='inline-flex' p={1}>
        <Box bgcolor={theme.palette.primary.main} borderRadius='50%' display='inline-flex' p={3}>
          <Box
            bgcolor={theme.palette.common.white}
            borderRadius='50%'
            color={theme.palette.primary.main}
            display='inline-flex'
            p={1}
          >
            {play ? <Stop /> : <PlayArrow />}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PlayButton
