import { useTranslation } from '@src/i18n'
import Box from '@base/Box'
import Typography from '@base/Typography'
import { ScheduledCall } from '@gql/__generated__'
import { Step } from '@tutor/TutorModal'
import Lesson from './Lesson'
import OpenConversation from './details/OpenConversation'

type Props = {
  tutorName: string
  duration: number
  durationOption: string
  setDuration: (duration: number) => void
  lessonId: string
  loading: boolean
  scheduledCall: Pick<ScheduledCall, 'start'> | null
  scheduledCallId: string | null
}

const Details = ({
  tutorName,
  duration,
  setDuration,
  durationOption,
  lessonId,
  loading,
  scheduledCall,
  scheduledCallId,
}: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <Box mb={2}>
        {scheduledCall && (
          <Typography variant='subtitle1' weight={600}>
            {t('callInvite.modalSteps.Details.scheduledCall.title')}
          </Typography>
        )}
        {!scheduledCall && !lessonId && (
          <Typography variant='subtitle1' weight={600}>
            {t('callInvite.modalSteps.Details.title')}
          </Typography>
        )}
      </Box>
      {lessonId ? (
        <Lesson step={Step.Details} tutorName={tutorName} setDuration={setDuration} lessonId={lessonId} />
      ) : (
        <OpenConversation
          duration={duration}
          durationOption={durationOption}
          loading={loading}
          tutorName={tutorName}
          setDuration={setDuration}
          scheduledCall={scheduledCall}
          scheduledCallId={scheduledCallId}
        />
      )}
    </>
  )
}

export default Details
