import gql from 'graphql-tag'

export default gql`
  fragment UserPackage on Purchase {
    id
    package {
      id
      name
      state
      lessons {
        id
        no
        units
        lessonUsage
      }
    }
  }
`
