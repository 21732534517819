import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CalendarIcon from '@material-ui/icons/Today'
import { useSelector } from 'react-redux'
import Router from 'next/router'

import Button from '@base/Button'
import Box from '@base/Box'
import { TutorModalPreviewByIdQuery } from '@gql/__generated__'
import { AppState } from '@store/Types'
import { userSelectors } from '@store/modules/User'
import Scheduling from '@components/tutor/Scheduling'
import links from '@api/links'
import getLogger from '@src/Logger'
import { RequireAtLeastOne } from '@lib/typeUtils'
import { parseDate } from '@helpers/dateTime'

type BaseProps = {
  tutor?: TutorModalPreviewByIdQuery['tutor']
  lessonId?: string
}

export type Props = RequireAtLeastOne<BaseProps, 'lessonId' | 'tutor'>

const ScheduleButton = ({ tutor, lessonId }: Props) => {
  const { t } = useTranslation()
  const [schedulingOpen, setSchedulingOpen] = useState(false)
  const isLoggedIn = useSelector<AppState, boolean>(state => userSelectors.isLoggedIn(userSelectors.getSelf(state)))
  const { schedule, date, duration, shiftId } = Router?.query || {}

  useEffect(() => {
    if (!schedule) return
    setSchedulingOpen(true) // user is coming back from payment with pre-filled data
  }, [])

  const handleClick = () => {
    if (!isLoggedIn) {
      Router.push(links.login.href, links.login.as).catch(err => getLogger().warn({ err }, 'Browser navigation failed'))
      return
    }
    setSchedulingOpen(true)
  }

  return (
    <Box px={{ xs: 2.5, md: 4 }} pt={{ xs: 2.5, md: 0 }} pb={{ xs: 15, md: 3 }}>
      <Button fullWidth color='primary' variant='outlined' onClick={handleClick} data-testid='scheduleBtn'>
        <CalendarIcon />
        <Box component='span' ml={1}>
          {t('callInvite.modalSteps.Info.book')}
        </Box>
      </Button>
      {schedulingOpen && (
        <Scheduling
          onClose={() => setSchedulingOpen(false)}
          tutor={tutor}
          lessonId={lessonId}
          date={schedule ? parseDate(date as string) : undefined}
          duration={(schedule && parseInt(duration as string)) || undefined}
          shiftId={schedule ? (shiftId as string) : undefined}
        />
      )}
    </Box>
  )
}

export default ScheduleButton
