import { useQuery } from '@apollo/react-hooks'
import CalendarIcon from '@material-ui/icons/Today'
import Router from 'next/router'

import Box from '@base/Box'
import { MissingCreditQuery, MissingCreditQueryVariables } from '@gql/__generated__'
import Error from '@base/NetworkError'
import { useTranslation } from '@src/i18n'
import ProgressButton from '@base/ProgressButton'
import links from '@api/links'
import getLogger from '@src/Logger'

import QUERY from './finish/Query.api'
import TopUpMessage from './finish/TopUpMessage'

type Props = {
  onSchedule: () => void
  loading: boolean
  date: Date
  duration: number
  groupId: string | null
  isPackage: boolean
  shiftId: string
  tutorId: string
}

const Finish = ({ onSchedule, loading: sending, date, duration, groupId, isPackage, shiftId, tutorId }: Props) => {
  const { t } = useTranslation()
  const { data, loading, error } = useQuery<MissingCreditQuery, MissingCreditQueryVariables>(QUERY, {
    variables: {
      date,
      durations: [duration],
      groupId,
      tutorId,
    },
    skip: isPackage, // package call is already paid for, no need to check credit
    fetchPolicy: 'network-only',
  })
  const { creditForCalls = null } = data || {}

  const getCreditForCall = (callDuration: number): MissingCreditQuery['creditForCalls'][number] | null => {
    // Loading data
    if (creditForCalls === null) {
      return null
    }

    return creditForCalls.find(creditForCall => creditForCall.duration === callDuration)
  }

  const getMissingCredit = (callDuration: number, isPackageCall: boolean): number | null => {
    if (isPackageCall) {
      return 0
    }

    return getCreditForCall(callDuration)?.missingCredit
  }

  const missingCredit = getMissingCredit(duration, isPackage)
  const status = getCreditForCall(duration)?.status || null

  const handleSubmit = () => {
    if (!missingCredit) {
      onSchedule()
      return
    }

    Router.push({
      pathname: links.topUp,
      query: {
        amount: missingCredit,
        redirectTo: `${Router.asPath.split('?')[0]
          }?schedule=true&date=${date.toISOString()}&duration=${duration}&shiftId=${shiftId}`,
      },
    }).catch(err => getLogger().warn({ err }, 'Browser navigation failed'))
  }

  if (error) return <Error />

  return (
    <>
      {!!missingCredit && <TopUpMessage amount={missingCredit} status={status} />}
      {date && (
        <ProgressButton
          fullWidth
          color='primary'
          variant='contained'
          onClick={handleSubmit}
          inProgress={loading || sending}
          data-testid='bookPaidSessionButton'
        >
          {!missingCredit && <CalendarIcon />}
          <Box component='span' ml={1}>
            {missingCredit
              ? t('callInvite.scheduling.steps.Complete.topUp')
              : t('callInvite.scheduling.steps.Complete.button')}
          </Box>
        </ProgressButton>
      )}
    </>
  )
}

export default Finish
