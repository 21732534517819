import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import React, { useEffect, useMemo, useState } from 'react'

import Box from '@base/Box'
import Button from '@base/Button'
import Typography from '@base/Typography'
import { detectBrowser } from '@helpers/browser'
import isMobile, { isTablet } from '@helpers/isMobile'
import startIntercom from '@helpers/startIntercom'
import Link from '@base/Link'
import { useTranslation, Trans } from '@src/i18n'

type Props = {
  onClose: () => void
  onRetry: () => void
}

const useStyles = makeStyles({
  button: {
    minWidth: 220,
  },
})

const Help = ({ onClose, onRetry }: Props) => {
  const classes = useStyles({})
  const { t } = useTranslation()
  const [browser, setBrowser] = useState(null)
  const mobile = useMemo(() => isMobile(), [])
  const tablet = useMemo(() => isTablet(), [])

  useEffect(() => {
    setBrowser(detectBrowser())
  }, [])

  const handleIntercom = ev => {
    onClose()
    startIntercom(ev)
  }

  if (browser === null) {
    return (
      <Box my={2} textAlign='center'>
        <CircularProgress color='inherit' size={20} thickness={5} />
      </Box>
    )
  }

  return (
    <>
      <Typography component='ul'>
        {mobile && <li>{t('permissionTest.help.instructions.mobile')}</li>}
        {tablet && <li>{t('permissionTest.help.instructions.tablet')}</li>}
        {!mobile && !tablet && <li>{t('permissionTest.help.instructions.desktop')}</li>}
        {browser && browser.name === 'chrome' && <li>{t('permissionTest.help.instructions.chrome')}</li>}
        {browser && browser.name === 'firefox' && <li>{t('permissionTest.help.instructions.firefox')}</li>}
        {browser && browser.name === 'safari' && <li>{t('permissionTest.help.instructions.safari')}</li>}
        <li>
          <Trans
            i18nKey='permissionTest.help.instructions.contactSupport'
            components={[
              <Link key='support' href='#' variant='inherit' underline weight={600} onClick={handleIntercom} />,
            ]}
          />
        </li>
      </Typography>
      <Box my={2}>
        <Divider />
      </Box>
      <Box mb={2} textAlign='center'>
        <Button className={classes.button} color='primary' onClick={onRetry} size='small' variant='contained'>
          {t('permissionTest.help.btnRetry')}
        </Button>
      </Box>
      <Box textAlign='center'>
        <Button className={classes.button} color='inherit' onClick={onClose} size='small' variant='contained'>
          {t('permissionTest.help.cancel')}
        </Button>
      </Box>
    </>
  )
}

export default Help
