import React, { useMemo } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { ShiftDatesAndSlotsQuery, ShiftDatesAndSlotsQueryVariables } from '@gql/__generated__'
import Box from '@base/Box'
import Loading from '@base/Loading'
import Error from '@base/NetworkError'
import QUERY from './AvailableShiftsAndSlotsQuery.api'
import PickTime from '@tutor/scheduling/PickTime'
import Typography from '@base/Typography'
import { useTranslation } from '@src/i18n'

type Props = {
  onSetDate?: (date: Date) => void
  onSetShift?: (shiftId: string) => void
  tutorId: string
  shiftId: string
  selectedDateTime: Date
  onSetDateTime: (Date) => void
  durationInSeconds: number
}

const PickDateAndTime: React.FC<Props> = ({
  tutorId,
  onSetDate,
  onSetShift,
  selectedDateTime,
  onSetDateTime,
  durationInSeconds,
}: Props) => {
  if (!durationInSeconds) return <Loading />
  const { t } = useTranslation()

  const { data, loading, error } = useQuery<ShiftDatesAndSlotsQuery, ShiftDatesAndSlotsQueryVariables>(QUERY, {
    variables: {
      tutorId,
      duration: durationInSeconds,
    },
    fetchPolicy: 'network-only',
  })

  const dates = useMemo(() => {
    const sortedDates = data?.dates.sort((a, b) => Date.parse(a.start) - Date.parse(b.start))

    return sortedDates?.map(dt => dt.slots).reduce((acc, val) => acc.concat(val), [])
  }, [data])

  if (loading) return <Loading />
  if (error) return <Error />

  const handleSetDateTime = (selectedDate: Date): void => {
    // TODO slots type
    onSetDate && onSetDate(selectedDate)
    onSetDateTime(selectedDate)
  }

  const hasDates = (dates?.length ?? 0) !== 0
  return (
    <>
      {hasDates && (
        <PickTime
          onSetDateTime={handleSetDateTime}
          selectedDateTime={selectedDateTime}
          onSetShift={onSetShift}
          times={dates}
        />
      )}
      {!hasDates && (
        <Box textAlign='center'>
          <Typography variant='body1'>{t('callInvite.scheduling.steps.Date.noDates')}</Typography>
        </Box>
      )}
    </>
  )
}

export default PickDateAndTime
