import gql from 'graphql-tag'

export default gql`
  query MissingCredit($tutorId: String!, $durations: [Int!]!, $date: DateTime!, $groupId: String) {
    creditForCalls: hasCreditForCalls(
      tutorId: $tutorId
      durations: $durations
      date: $date
      groupId: $groupId
      type: ScheduledTutorPrice
    ) {
      missingCredit
      duration
      status
    }
  }
`
