import cx from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import React, { ReactNode } from 'react'

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
    height: 0,
    overflow: 'hidden',
    // Element's ratio will be given by top padding and can be calculated as height / width * 100
    // See https://css-tricks.com/aspect-ratio-boxes/
    padding: 0,
    position: 'relative',
  },
  content: {
    bottom: 0,
    display: 'block',
    left: 0,
    margin: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
  },
}))

type Props = {
  className?: string
  children: ReactNode
  ratio?: number // height/width
}

const AspectRatioBox = ({ className, children, ratio = 1 }: Props) => {
  const classes = useStyles({})

  return (
    <div className={cx(classes.root, className)} style={{ paddingTop: `${ratio * 100}%` }}>
      <div className={classes.content}>{children}</div>
    </div>
  )
}

export default AspectRatioBox
