import gql from 'graphql-tag'

export default gql`
  fragment TutorAvatar on Tutor {
    id
    slug
    user {
      id
      firstName
      image
    }
  }
`
