type Props = {
  baseUrl: string
  asUrl?: string
  slug?: string
  tutorId?: string
  step?: number
  conversationId?: string
  scheduledCallId?: string
}

// CONSIDER use the `/tutors/t/${tutorSlug}` as default value for "asUrl"
const getTutorLink = ({ baseUrl, asUrl, scheduledCallId, slug, tutorId, step, conversationId }: Props) => ({
  href: { pathname: baseUrl, query: { scheduledCallId, slug, tutorId, step, conversationId, scroll: false } },
  as: asUrl || `${baseUrl === '/' ? '' : baseUrl}/t/${slug}`,
})

export default getTutorLink
