import gql from 'graphql-tag'

export default gql`
  query getUserName {
    user: getSelf {
      id
      firstName
      countryCode
      verified
    }
  }
`
