import links from '@api/links'
import { ParticipantDetailsQuery, PurchaseStatus, TutorItemFragment } from '@gql/__generated__'

type AssignTherapistActionType = {
  successful: boolean
  redirectTo?: string
}

export const assignTherapistAction = (
  isLoggedIn: boolean,
  therapistId: string,
  purchases: ParticipantDetailsQuery['packagePurchases'],
): AssignTherapistActionType => {
  let redirectTo
  let successful = false

  if (isLoggedIn) {
    // TODO refactor
    if (therapistId && !hasUnusedPurchase(purchases)) {
      // TODO mutate to change
      successful = true
      redirectTo = links.dashboard
    } else {
      // TODO mutate to assign
      successful = true
      redirectTo = links.dashboard
    }
  } else {
    successful = true
    redirectTo = links.onboarding
  }

  return {
    redirectTo,
    successful,
  }
}

export const hasUnusedPurchase = (purchases: ParticipantDetailsQuery['packagePurchases']): boolean =>
  purchases.some(purchase => purchase.status !== PurchaseStatus.Done)

type FilterableTherapist = {
  intros: Pick<TutorItemFragment['intros'][number], 'language'>[]
}

type TherapistFilter = {
  language?: string
}

export function filterTherapists<T extends FilterableTherapist>(tutors: T[], filter: TherapistFilter): T[] {
  if (filter.language) {
    tutors = tutors.filter(t => t.intros.some(i => i.language === filter.language))
  }

  return tutors
}

export function pickTutorIntro<T extends FilterableTherapist>(t: T, language?: string): TutorItemFragment['intros'][number] | undefined {
  let preferedIntro = undefined
  if (language != undefined) {
    preferedIntro = t.intros.find(i => i.language === language)
  }

  return preferedIntro ?? t.intros[0]
}
