import gql from 'graphql-tag'

export default gql`
  query ShiftDatesAndSlots($tutorId: String!, $duration: Int!) {
    dates: getAvailableSlots(tutorId: $tutorId, duration: $duration) {
      start
      slots {
        start
        shiftId
      }
    }
  }
`
