import React from 'react'

import Dialog from '@base/Dialog'
import Box from '@base/Box'
import capitalize from '@helpers/capitalize'
import NotGranted from '@components/permissions/NotGranted'
import { MediaStreamError } from '@lib/media/MediaStream'
import { PermissionsState } from '@store/modules/calling/Types'
import { useTranslation } from '@src/i18n'

type Props = {
  // Render this component until permissions granted
  error: MediaStreamError
  permissions: Omit<PermissionsState, 'granted'>
  onClose: () => void
}

const NotGrantedDialog = ({ error, onClose, permissions }: Props) => {
  const { t } = useTranslation()

  return (
    // User should click continue to pass permission test successfully
    // So prevent dialog to be closed by clicking on the backdrop
    <Dialog
      disableBackdropClick={permissions === 'waiting'}
      open
      onClose={onClose}
      aria-labelledby={t('permissionTest.dialogTitle')}
    >
      <Box
        maxWidth={360}
        mx={{ xs: 2, sm: 6 }}
        my={{ xs: 3, sm: 9 }}
        textAlign='center'
        data-testid={`permission${capitalize(permissions as string)}`}
      >
        <NotGranted kind={permissions} error={error} />
      </Box>
    </Dialog>
  )
}

export default NotGrantedDialog
