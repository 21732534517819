import gql from 'graphql-tag'
import TutorAvatar from '@common/tutorAvatar/Fragment.api'

export default gql`
  query SchedulePackageTutors($lessonId: String!) {
    tutors: getPackageTutorsWithAvailableShifts(lessonId: $lessonId) {
      ...TutorAvatar
    }
  }
  ${TutorAvatar}
`
