import { ReactNode } from 'react'

import Box from '@base/Box'
import Typography from '@base/Typography'
import { ToggleButton } from '@base/ToggleButtons'

type Props = {
  value: string
  title: string
  children: ReactNode
  selected?: boolean
  highlighted?: boolean
  onClick?: () => void
}

const Option = ({ selected, value, highlighted, onClick, title, children, ...props }: Props) => {
  return (
    <ToggleButton selected={selected} value={value} highlighted={highlighted} onClick={onClick} {...props}>
      <Typography component='span' variant={highlighted ? 'subtitle1' : 'body1'} weight={600}>
        <Box display='flex' flexDirection='column' py={highlighted ? 0 : 1}>
          <Box component='span' display='block'>
            {title}
          </Box>
          <Box component='span' display='block'>
            {children}
          </Box>
        </Box>
      </Typography>
    </ToggleButton>
  )
}

export default Option
