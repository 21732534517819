import { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'

import ToggleButtons from '@base/ToggleButtons'
import {
  GetAvailableLessonsQuery,
  GetAvailableLessonsQueryVariables,
  PackageStatus,
  TutorPriceType,
} from '@gql/__generated__'
import Loading from '@base/Loading'

import QUERY from './selection/Query.api'
import Course from './selection/Course'
import OpenCall from './selection/OpenCall'

type Props = {
  tutorId: string
  tutorPriceType: TutorPriceType
  onSelected: (lessonId: string, duration: number) => void
  onSkip: () => void
}

const Selection = ({ onSelected, onSkip, tutorId, tutorPriceType }: Props) => {
  const { data, loading, error } = useQuery<GetAvailableLessonsQuery, GetAvailableLessonsQueryVariables>(QUERY, {
    variables: { tutorId },
  })
  const unfinishedPackages = (data?.userPackages ?? [])
    .filter(
      ({ package: { state, lessons } }) =>
        state === PackageStatus.ForSale && lessons.find(({ lessonUsage }) => !lessonUsage),
    )
    .map(({ package: pkg }) => pkg)
  const tutorPackages = data?.tutor?.packages ?? []
  const availablePackages = unfinishedPackages.filter(p1 => tutorPackages.some(p2 => p1.id === p2.id))
  // student has no unfinished packages taught by this tutor - skip selection
  const goToNextStep = !!(!availablePackages.length || error)

  useEffect(() => {
    if (loading || !goToNextStep) return
    onSkip()
  }, [loading, goToNextStep])

  if (loading) {
    return <Loading BoxProps={{ mt: 3 }} />
  }

  if (goToNextStep) {
    return null
  }

  return (
    <ToggleButtons exclusive vertical>
      <OpenCall onSelected={onSelected} tutorId={tutorId} tutorPriceType={tutorPriceType} />
      {availablePackages.map(course => (
        <Course key={course.name} course={course} onSelected={onSelected} />
      ))}
    </ToggleButtons>
  )
}

export default Selection
