import { useEffect, ReactNode } from 'react'
import { useQuery } from '@apollo/react-hooks'
import Box from '@base/Box'
import { GetPackageLessonQuery } from '@gql/__generated__'
import Loading from '@base/Loading'
import Error from '@base/NetworkError'
import { Step } from '@tutor/TutorModal'
import QUERY from './lesson/Query.api'
import Summary from './lesson/Summary'
import Details from './lesson/Details'

type Props = {
  step: Step
  tutorName: string
  lessonId: string
  setDuration: (duration: number) => void
}

const Wrapper = ({ children }: { children: ReactNode }) => (
  <Box data-testid='lesson' width='80%' display='flex' flexDirection='column' flex={1}>
    {children}
  </Box>
)

const Lesson = ({ tutorName, lessonId, setDuration, step }: Props) => {
  const { data, loading, error } = useQuery<GetPackageLessonQuery>(QUERY)
  const purchasedPackage = (data?.userPackages || []).find(({ package: { lessons: less } }) =>
    less.find(({ id }) => id === lessonId),
  )
  const { package: { name: packageName = '', lessons = [] } = {} } = purchasedPackage || {}
  const thisLesson = purchasedPackage && lessons.find(({ id }) => id === lessonId)

  useEffect(() => {
    if (!purchasedPackage) return
    setDuration(thisLesson.units * 15 * 60)
  }, [purchasedPackage])

  if (loading) return <Loading />
  if (error) return <Error />

  // FIXME - free packages
  if (!thisLesson) {
    return <></>
  }

  return (
    <Wrapper>
      {step === Step.Details && (
        <Details packageName={packageName} thisLesson={thisLesson} lessonsCount={lessons.length} />
      )}
      {step === Step.Summary && (
        <Summary
          tutorName={tutorName}
          packageName={packageName}
          thisLesson={thisLesson}
          lessonsCount={lessons.length}
        />
      )}
    </Wrapper>
  )
}

export default Lesson
