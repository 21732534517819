import mitt from 'mitt'

type ImpressionType = 'Tutor'

class Impression {
  private impressions: { [type in ImpressionType]?: string[] } = {}

  public events = mitt()

  public getByType(type: ImpressionType): string[] {
    return this.impressions[type] || []
  }

  /**
   * Track new impression of entity with given type and ID
   */
  public push(type: ImpressionType, id: string): void {
    this.impressions[type] = this.impressions[type] || []
    this.impressions[type].push(id)
    this.events.emit(type, id)
  }
}

const impression = new Impression()

export default impression
