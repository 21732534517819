import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import React, { memo } from 'react'
import { UrlObject } from 'url'
import { useTranslation } from '@src/i18n'
import Avatar from '@base/Avatar'
import Box from '@base/Box'
import Typography from '@base/Typography'
import Link from '@base/Link'
import { TutorItemFragment, User } from '@gql/__generated__'
import Motto from '@components/therapist/Motto'
import Topics from '@components/therapist/Topics'
import { pickTutorIntro } from '@service/Therapist'

type StyleProps = {
  isMember: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(({ breakpoints }) => ({
  icon: {
    color: '#EDBB2F',
    marginRight: 5,
    marginTop: -3,
  },
  check: {
    color: '#20A964',
    fontSize: 16,
    marginLeft: 10,
    [breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
  block: {
    color: '#9AA5B1',
    fontSize: 16,
    marginLeft: 10,
    [breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
  link: {
    fontSize: 14,
  },
  role: {
    fontSize: '12px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
}))


export type Props = {
  tutor: Pick<TutorItemFragment, 'id' | 'topics' | 'intros'> & {
    user: Pick<User, 'firstName' | 'displayName' | 'image' | 'role'>
  }
  link?: { href: string | UrlObject; as: string } | null
  localSrc?: boolean
}

const Medallion = memo(({ tutor, link, localSrc }: Props) => {
  const { t, i18n } = useTranslation()
  const { user, topics } = tutor
  const tutorIntro = pickTutorIntro(tutor, i18n.language)
  const { firstName, displayName, image, role } = user

  const classes = useStyles({ isMember: !!role })


  return (
    <Box display='flex' flexDirection='column' height='100%' data-testid='medallion'>
      <Box flexGrow={1}>
        <Box display='inline-block' position='relative' mb={1.875}>
          <Box width={104} height={104}>
            <Avatar alt={firstName || displayName} src={image} localSrc={localSrc} size='100%' />
          </Box>
        </Box>
        <Box color='#1F2933' mt={1.25}>
          <Typography variant='subtitle1' weight={600}>
            {firstName || displayName}
          </Typography>
        </Box>
        {role && (
          <Box color='#1F2933' mt={1.25}>
            <Typography variant='body1' className={classes.role} color='textSecondary'>
              {role}
            </Typography>
          </Box>
        )}
        {topics?.length > 0 && (
          <Box marginLeft={{ xs: -0.375, sm: -0.625 }} my={1.25}>
            <Topics topics={topics} />
          </Box>
        )}
        <Motto>{tutorIntro?.motto}</Motto>
        {link && (
          <Box mt={1.25}>
            <Link fullWidth href={link.href} className={classes.link} underline data-testid='tutor-show-more'>
              {t('terapio:homepage.therapists.more', { therapist: firstName || displayName })}
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  )
})

Medallion.displayName = 'Medallion'

export default Medallion
