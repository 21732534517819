import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import cuid from 'cuid'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from '@src/i18n'
import Grid from '@material-ui/core/Grid'
import Typography from '@base/Typography'
import Box from '@base/Box'
import Container from '@base/Container'
import quotes from './clientFeedback/quotes.svg'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

const useStyles = makeStyles(({ breakpoints, palette }: Theme) => ({
  container: {
    padding: '1rem',
    '& button': {
      [breakpoints.up('sm')]: {
        margin: '0 1rem',
      },
    },
    [breakpoints.up('md')]: {
      margin: '1rem auto',
    },
  },
  carousel: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  slider: {
    width: '100%',
    display: 'flex',
    height: '350px',
    [breakpoints.up(400)]: {
      height: '250px',
    },
    flexDirection: 'column',
    justifyContent: 'center',
  },
  slide: {
    '& div': {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  button: {
    height: '2rem',
    border: 'none',
    backgroundColor: 'transparent',
    color: palette.primary.main,
    '&:disabled': {
      color: palette.action.disabled,
    },
  },
  title: {
    marginTop: '1rem',
    fontWeight: 'bold',
  },
  content: {
    marginTop: '2rem',
  },
}))

const ClientFeedback = () => {
  const classes = useStyles({})
  const { t } = useTranslation()

  return (
    <Container
      component='section'
      // Fit content to lower viewport on mobile
      py={6}
      innerProps={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1200,
        justifyContent: 'center',
        position: 'relative',
      }}
      position={'relative'}
    >
      <Box textAlign={'center'}>
        <Typography variant='h3' component='h2'>
          <div dangerouslySetInnerHTML={{ __html: t('terapio:homepage.clientFeedback.title') }} />
        </Typography>
      </Box>
      <Grid container className={classes.container}>
        <CarouselProvider naturalSlideWidth={200} naturalSlideHeight={80} totalSlides={3} className={classes.carousel}>
          <ButtonBack className={classes.button}>
            <ArrowBackIcon />
          </ButtonBack>
          <Slider className={classes.slider}>
            {[1, 2, 3].map((key, index) => (
              <Slide key={cuid()} index={index} className={classes.slide}>
                <img src={quotes} alt={t('terapio:homepage.clientFeedback.alt')} />
                <Typography variant={'body1'} className={classes.content}>
                  {t(`terapio:homepage.clientFeedback.feedback${key}.description`)}
                </Typography>
              </Slide>
            ))}
          </Slider>
          <ButtonNext className={classes.button}>
            <ArrowForwardIcon />
          </ButtonNext>
        </CarouselProvider>
      </Grid>
    </Container>
  )
}

export default ClientFeedback
