import { NextPage } from 'next'
import React, { useEffect } from 'react'

import links from '@api/links'
import Layout from '@layouts/Layout'
import Cover from '@src/pages/index/Cover'
import TutorsOnline from '@tutor/TutorsOnline'
import NeedTherapy from '@components/common/NeedTherapy'
import HowDoesItWork from '@components/common/HowDoesItWork'
import WhyOnline from '@components/common/WhyOnline'
import SignupAction from '@common/SignupAction'
import ClientFeedback from '@common/ClientFeedback'
import OurTeam from '@common/OurTeam'
import OurVision from '@common/OurVision'
import analytics from '@src/Analytics'
import TutorModal from '@tutor/TutorModal'
import withAuth from '@lib/withAuth'
import { Role } from '@store/modules/User'
import Head from '@common/Head'
import { useTranslation } from '@src/i18n'
import { setItem } from '@helpers/localStorage'
import storageKey from '@api/storageKeys'
import redirect from '@helpers/redirect'

const Index: NextPage = () => {
  const { t } = useTranslation()
  useEffect(() => analytics.pushEvent('home'), [])

  const onTherapistPicked = (therapistId: string) => {
    setItem(storageKey.THERAPIST_ID, therapistId)
    redirect(null, links.assignTherapist)
  }

  return (
    <Layout headerProps={{ borderBottom: 'none' }}>
      <Head title={t('meta.homepage.title')} description={t('meta.homepage.description')} asPath={links.home} />
      <Cover />
      <NeedTherapy />
      <HowDoesItWork />
      <WhyOnline />
      <TutorsOnline />
      <SignupAction />
      <ClientFeedback />
      <OurTeam />
      <OurVision />
      <TutorModal baseUrl={links.home} onTherapistPicked={onTherapistPicked} />
    </Layout>
  )
}

Index.getInitialProps = () => ({
  namespacesRequired: ['web', 'terapio'],
})

export default withAuth({
  isAllowed: userRoles => !userRoles.includes(Role.User),
  redirectUrl: links.dashboard,
})(Index)
