import gql from 'graphql-tag'

export default gql`
  fragment Tutor on Tutor {
    id
    user {
      id
      firstName
      displayName
      countryCode
      image
      currentStatus {
        status
        text
      }
      role
    }
    intros {
      language
      introduction
      motto
    }
    topics {
      name
    }
    rating
    slug
    prices {
      id
      type
      price
    }
    busy {
      isBusy
      until
      flag
    }
    availability {
      start
      end
    }
  }
`
