import gql from 'graphql-tag'

const InviteParticipant = gql`
  fragment InviteParticipant on Participant {
    id
    profile: profileFixed {
      ... on Student {
        id
        user {
          id
          countryCode
          displayName
          firstName
          image
        }
        rating
      }
      ... on Tutor {
        id
        user {
          id
          countryCode
          displayName
          firstName
          image
        }
        rating
      }
    }
  }
`

export default InviteParticipant
