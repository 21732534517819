import gql from 'graphql-tag'

import SentInvite from '@components/invite/sentInvites/SentInviteFragment.api'

const TutorModalSendInvite = gql`
  mutation TutorModalSendInvite($tutorId: String!, $duration: Int!, $lessonId: String) {
    invite: sendInvite(tutorId: $tutorId, duration: $duration, lessonId: $lessonId) {
      ...SentInvite
    }
  }

  ${SentInvite}
`

export default TutorModalSendInvite
