import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import { format } from 'date-fns'
import Box from '@base/Box'
import HorizontalScroll from '@base/HorizontalScroll'
import { useTranslation } from '@src/i18n'
import Typography from '@base/Typography'

type Props = {
  selectedDateTime: Date
  onSetDateTime: (Date) => void
  onSetShift?: (shiftId: string) => void
  times: any[]
}

const Times = ({ selectedDateTime, onSetShift, times, onSetDateTime }: Props) => {
  const { t } = useTranslation()
  const sortedTimes = times.sort((a, b) => Date.parse(a.start) - Date.parse(b.start))

  const handleSelect = (shiftId: string, dateTime: Date) => {
    onSetShift(shiftId)
    onSetDateTime(dateTime)
  }

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return (
    <Box>
      <HorizontalScroll>
        <ToggleButtonGroup exclusive>
          {sortedTimes.map(({ start, shiftId }, i) => {
            const availableDate = new Date(start)
            return (
              <ToggleButton
                key={i}
                value={availableDate}
                selected={selectedDateTime && availableDate.toISOString() === selectedDateTime.toISOString()}
                onClick={() => handleSelect(shiftId, availableDate)}
                data-testid={`timeOption-${i}`}
              >
                <Typography component='span' variant='body1' weight={600}>
                  {format(availableDate, 'd.L.Y HH:mm')}
                </Typography>
              </ToggleButton>
            )
          })}
        </ToggleButtonGroup>
      </HorizontalScroll>
      <Typography variant='body2'>
        {t('common.timezone')}: {timezone}
      </Typography>
    </Box>
  )
}

export default Times
