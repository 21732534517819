import getLogger from '@src/Logger'

type DrawFn = (level: number) => void
type CleanupFn = () => void
type NotSupportedFn = () => void
type NoInputFn = () => void

export type Analyzer = (
  stream: MediaStream,
  onDraw: DrawFn,
  onNotSupported: NotSupportedFn,
  onNoInput: NoInputFn,
) => CleanupFn

/**
 * Analyze given audio stream
 * @param stream Stream to analyze
 * @param onDraw Callback to run during visualisation
 * @param onNotSupported Callback to run when visualisation is not supported
 * @param onNoInput Callback to run when there is not audio to visualize
 *
 * @return Cleanup function to stop visualisation
 */
const audioAnalyzer: Analyzer = (stream, onDraw, onNotSupported, onNoInput): CleanupFn => {
  let frequencyTimer
  let supported = true

  const AudioContext =
    window.AudioContext || // Default
    window.webkitAudioContext || // Safari and old versions of Chrome
    false

  if (!AudioContext) {
    getLogger().warn('AudioContext not supported. Audio analyzer cancelled.')
    supported = false
  }

  if (!window.AnalyserNode || !window.AnalyserNode.prototype.getByteFrequencyData) {
    getLogger().warn('AnalyserNode not supported. Audio analyzer cancelled.')
    supported = false
  }

  // Cannot perform test. Trigger error.
  if (!supported) {
    onNotSupported()
    return () => {}
  }

  // MediaStream has no audio track
  if (!stream.getAudioTracks() || !stream.getAudioTracks().length) {
    getLogger().warn('Stream has no audio track')
    onNoInput()
    return () => {}
  }

  const audioCtx: AudioContext = new AudioContext()
  const audioSrc = audioCtx.createMediaStreamSource(stream)
  const analyser = audioCtx.createAnalyser()
  audioSrc.connect(analyser)
  // FFT window size
  analyser.fftSize = 2048
  // Data vector size equals to half of FFT window size
  const frequencyArray = new Uint8Array(analyser.frequencyBinCount)
  const doDraw = () => {
    frequencyTimer = requestAnimationFrame(doDraw)
    analyser.getByteFrequencyData(frequencyArray)
    // FrequencyArray has a length longer than 255, but there seems to be no significant data after this point.
    // Taking 300 to get a resulting range of 0-100.
    const avgFrequency = frequencyArray.slice(0, 300).reduce((p, c) => p + c, 0) / 300
    onDraw(avgFrequency)
  }
  doDraw()

  return () => {
    audioSrc.disconnect(analyser)
    audioCtx
      .close()
      .then(() => {
        getLogger().info('Closing AudioContext was successful')
      })
      .catch(err => {
        getLogger().error({ err }, 'Closing AudioContext failed')
      })
    cancelAnimationFrame(frequencyTimer)
  }
}

export default audioAnalyzer
