import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'

import { useTranslation } from '@src/i18n'
import Typography from '@base/Typography'
import Box from '@base/Box'
import Container from '@base/Container'

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  container: {
    margin: '0 auto',
    flexWrap: 'wrap',
    maxWidth: '800px',
    marginTop: '5rem',
    [breakpoints.up('sm')]: {
      marginTop: '15rem',
    },
    [breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
  item: {
    width: '100%',
    position: 'relative',
    '& p': {
      width: '90%',
      margin: '1rem auto',
      fontSize: '24px',
      fontWeight: 300,
    },
  },
}))

const OurVision = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isBiggerSm = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Container
      component='section'
      // Fit content to lower viewport on mobile
      py={6}
      innerProps={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1200,
        justifyContent: 'center',
        position: 'relative',
      }}
      position={'relative'}
      background={isBiggerSm ? 'fourth' : undefined}
    >
      <Hidden smUp>
        <Divider />
      </Hidden>
      <Grid container alignItems='flex-start' justifyContent='space-between' className={classes.container}>
        <Box className={classes.item} textAlign={'center'}>
          <Typography variant='body1' color='inherit'>
            {t('terapio:homepage.ourVision.first')}
          </Typography>
        </Box>
        <Box className={classes.item} textAlign={'center'}>
          <Typography variant='body1' color='inherit'>
            {t('terapio:homepage.ourVision.second')}
          </Typography>
        </Box>
        <Box className={classes.item} textAlign={'center'}>
          <Typography variant='body1' color='inherit'>
            {t('terapio:homepage.ourVision.third')}
          </Typography>
        </Box>
      </Grid>
    </Container>
  )
}
export default OurVision
