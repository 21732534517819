import gql from 'graphql-tag'

export default gql`
  query CallRate($tutorId: String!) {
    tutor: getTutor(id: $tutorId) {
      id
      prices {
        id
        type
        price
      }
    }
  }
`
