import { useTranslation } from '@src/i18n'
import Typography from '@base/Typography'
import Box from '@base/Box'
import { GetAvailableLessonsQuery } from '@gql/__generated__'
import GDPRCheck from '@common/GDPRCheck'

type Props = {
  tutorName: string
  packageName: string
  thisLesson: GetAvailableLessonsQuery['userPackages'][number]['package']['lessons'][number]
  lessonsCount: number
}

const Summary = ({ tutorName, packageName, thisLesson, lessonsCount }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <Box width='100%' display='flex' justifyContent='space-between' mt={3}>
        <Typography variant='body1' align='justify' noWrap>
          {t('callInvite.modalSteps.package.course')}:
        </Typography>
        <Typography variant='body1' align='right' weight={600}>
          {t(`packages:list.${packageName}.name`)}
        </Typography>
      </Box>
      <Box width='100%' display='flex' justifyContent='space-between'>
        <Typography variant='body1' align='justify'>
          {t('callInvite.modalSteps.package.lesson')}:
        </Typography>
        <Typography variant='body1' align='justify' weight={600}>
          {`${thisLesson.no}  ${t('callInvite.modalSteps.package.from')} ${lessonsCount}`}
        </Typography>
      </Box>
      <Box width='100%' display='flex' flexGrow={1} justifyContent='space-between'>
        <Typography variant='body1' align='justify'>
          {t('callInvite.modalSteps.Summary.length')}:
        </Typography>
        <Typography variant='body1' align='justify' weight={600}>
          {t(`packages:list.${packageName}.sessionLength`)}
        </Typography>
      </Box>
      <Box mt={5} flexGrow={1}>
        <Typography variant='body1'>{t('callInvite.modalSteps.Summary.info', { name: tutorName })}</Typography>
      </Box>
      <GDPRCheck invite />
    </>
  )
}

export default Summary
