import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from '@src/i18n'

import Link from '@base/Link'
import links from '@api/links'
import Typography from '@base/Typography'
import Box from '@base/Box'
import Button from '@components/base/Button'
import Container from '@base/Container'

const useStyles = makeStyles<Theme>(() => ({
  container: {
    margin: '1rem',
  },
}))

const SignupAction = () => {
  const classes = useStyles({})
  const { t } = useTranslation()

  return (
    <Container
      component='section'
      // Fit content to lower viewport on mobile
      py={6}
      innerProps={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1200,
        justifyContent: 'center',
        position: 'relative',
      }}
      position={'relative'}
    >
      <Box
        maxWidth={1200}
        py={6}
        px={{ xs: 2.5, md: 5, lg: 11 }}
        color='#323F4B'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        borderRadius={{ md: 10 }}
        border={'2px solid #FFD980'}
        className={classes.container}
      >
        <Box display={{ md: 'flex' }} justifyContent='center' alignItems='center'>
          <Box
            width={'100%'}
            mx={{ xs: 'auto', md: 0 }}
            mr={{ md: 8.75 }}
            mb={{ xs: 3.5, md: 0 }}
            textAlign={{ xs: 'center', md: 'left' }}
          >
            <Typography component='h2' variant='inherit' weight={500}>
              {t('terapio:homepage.action.title')}
            </Typography>
            <Typography component='p' variant='body1' weight={400} color='textSecondary'>
              {t('terapio:homepage.action.description')}
            </Typography>
          </Box>
          <Box width='100%' mx={{ xs: 'auto', md: 0 }} maxWidth={250}>
            <Link fullWidth href={links.onboarding}>
              <Button fullWidth variant='contained' color='primary'>
                {t('terapio:homepage.action.button')}
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default SignupAction
