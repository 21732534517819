import { useMemo } from 'react'

import { GetAvailableLessonsQuery } from '@gql/__generated__'

import Lesson from './Lesson'

type Props = {
  course: GetAvailableLessonsQuery['userPackages'][number]['package']
  onSelected: (lessonId: string, duration: number) => void
  selected?: boolean
  highlighted?: boolean
}

const Course = ({ course, onSelected, selected, highlighted }: Props) => {
  const { lessons } = course
  const nextLesson = useMemo(
    () => [...lessons].filter(({ lessonUsage }) => !lessonUsage).sort((a, b) => a.no - b.no)[0],
    [lessons],
  )

  return <Lesson lessonId={nextLesson.id} selected={selected} highlighted={highlighted} onSelected={onSelected} />
}

export default Course
