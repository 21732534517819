import { useTranslation } from '@src/i18n'
import Typography from '@base/Typography'
import Box from '@base/Box'
import { formatLocalInterval } from '@helpers/dateTime'
import { Step } from '@tutor/TutorModal'
import GDPRCheck from '@common/GDPRCheck'

import Lesson from './Lesson'

type Props = {
  tutorName: string
  duration: number
  durationOption: string
  lessonId: string
  setDuration: (duration: number) => void
}

const Summary = ({ tutorName, duration, lessonId, setDuration }: Props) => {
  const { t, i18n } = useTranslation()

  return (
    <>
      {lessonId && <Lesson step={Step.Summary} tutorName={tutorName} setDuration={setDuration} lessonId={lessonId} />}
      {!lessonId && (
        <>
          <Box width='100%' display='flex' justifyContent='space-between' mt={3} data-testid='callLength'>
            <Typography variant='body1' align='justify'>
              {t('callInvite.modalSteps.Summary.length')}:
            </Typography>
            <Typography variant='body1' align='justify' weight={600}>
              {formatLocalInterval(i18n, duration, { unit: 'minute' })}
            </Typography>
          </Box>
          <Box mt={5} flexGrow={1}>
            <Typography variant='body1'>{t('callInvite.modalSteps.Summary.info', { name: tutorName })}</Typography>
          </Box>
          <GDPRCheck invite />
        </>
      )}
    </>
  )
}

export default Summary
