import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'

import Box from '@base/Box'

import MedallionSkeleton from './MedallionSkeleton'

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    [breakpoints.down('xs')]: {
      '&:nth-child(n+2)': {
        display: 'none',
      },
    },
    [breakpoints.down('md')]: {
      '&:nth-child(n+3)': {
        display: 'none',
      },
    },
    [breakpoints.down('lg')]: {
      '&:nth-child(n+4)': {
        display: 'none',
      },
    },
    [breakpoints.down('xl')]: {
      '&:nth-child(n+5)': {
        display: 'none',
      },
    },
  },
}))

const TutorItemSkeleton = () => {
  const classes = useStyles({})

  return (
    <Box
      bgcolor='#fff'
      m={1}
      px={{ xs: 2.5, sm: 3.75 }}
      py={3.75}
      minWidth={240}
      width='100%'
      maxWidth={360}
      position='relative'
      display='flex'
      flexDirection='column'
      border='1px solid #E4E7EB'
      className={classes.root}
    >
      <Box flex={1}>
        <MedallionSkeleton />
      </Box>
      <Box position='absolute' top={0} right={0} padding={1.5}>
        <Skeleton variant='circle' width={22} height={22} />
      </Box>
      <Box mt={2.5} display='flex' justifyContent='space-between' alignItems='center' height={{ xs: 50, md: 60 }}>
        <Skeleton variant='rect' width={140} height='100%' />
        <Skeleton variant='rect' width={140} height='100%' />
      </Box>
    </Box>
  )
}

export default TutorItemSkeleton
