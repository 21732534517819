import { useQuery } from '@apollo/react-hooks'
import getConfig from 'next/config'
import { useMemo } from 'react'

import { useTranslation } from '@src/i18n'
import useExchangeRate from '@hooks/useExchangeRate'
import { CallRateQuery, CallRateQueryVariables, TutorPriceType } from '@gql/__generated__'
import { exchangeMakeNiceFormat } from '@helpers/price'
import Loading from '@base/Loading'
import Error from '@base/NetworkError'
import { formatLocalInterval } from '@helpers/dateTime'
import { getRateByType } from '@service/Tutor'

import Option from './Option'
import QUERY from './openCall/Query.api'

const { CALL_DURATIONS } = getConfig().publicRuntimeConfig

type Props = {
  tutorId: string
  tutorPriceType: TutorPriceType
  onSelected: (lessonId: string, duration: number) => void
  selected?: boolean
  highlighted?: boolean
}

const OpenCall = ({ tutorId, tutorPriceType, onSelected, selected = false, highlighted = false }: Props) => {
  const { i18n, t } = useTranslation()
  const [exRate, currency] = useExchangeRate()
  const { data: { tutor = null } = {}, loading, error } = useQuery<CallRateQuery, CallRateQueryVariables>(QUERY, {
    variables: {
      tutorId,
    },
  })
  // open conversation has default pre-selected duration
  const preSelectedDuration = CALL_DURATIONS.find(({ selected: selectedDur }) => selectedDur).duration
  const rate = useMemo(() => (tutor ? getRateByType(tutor.prices, tutorPriceType) : null), [tutor?.prices])

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <Option
      value='openCall'
      title={t('callInvite.modalSteps.Selection.call')}
      selected={selected}
      highlighted={highlighted}
      onClick={() => onSelected(null, preSelectedDuration)}
      data-testid='openCall'
    >
      {exchangeMakeNiceFormat(rate.price, exRate, currency)}&nbsp;/&nbsp;
      {formatLocalInterval(i18n, rate.duration / 1000, {
        unit: 'minute',
        roundingMethod: 'ceil',
        wordLength: 3,
      })}
    </Option>
  )
}

export default OpenCall
