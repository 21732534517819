import gql from 'graphql-tag'

const StudentOrTutorScheduledCall = gql`
  fragment StudentOrTutorScheduledCall on ScheduledCall {
    id
    cancelledAt
    expectedPrice @include(if: $isStudent)
    expectedRevenue @skip(if: $isStudent)
    status
    lesson {
      id
      no
      package {
        id
        name
        lessons {
          id
        }
      }
    }
    participants {
      profileId
      profile {
        ... on Student {
          id
          user {
            ...StudentOrTutorScheduledCallUser
          }
          rating
        }
        ... on Tutor {
          id
          user {
            ...StudentOrTutorScheduledCallUser
          }
          rating
          slug
        }
      }
    }
    rooms {
      id
      status
    }
    start
    end
  }

  fragment StudentOrTutorScheduledCallUser on User {
    id
    firstName
    displayName
    countryCode
    image
    credentials {
      identifier @skip(if: $isStudent)
    }
  }
`

export default StudentOrTutorScheduledCall
