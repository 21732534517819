import gql from 'graphql-tag'
import TutorDetail from '@gql/fragments/TutorDetailFragment.api'
import ScheduledCall from './ScheduledCallFragment.api'

export default gql`
  query tutorModalDetail($slug: String!, $isScheduledCall: Boolean!, $scheduledCallId: String!) {
    tutor: getTutorBySlug(slug: $slug) {
      ...TutorDetail
    }
    scheduledCall: getScheduledCallById(id: $scheduledCallId) @include(if: $isScheduledCall) {
      ...ScheduledCall
    }
  }

  ${TutorDetail}
  ${ScheduledCall}
`
