import Grid from '@material-ui/core/Grid'

import { useTranslation } from '@src/i18n'
import Typography from '@base/Typography'
import Box from '@base/Box'
import Container from '@base/Container'

const NeedTherapy = () => {
  const { t } = useTranslation()

  const h1 = t('terapio:homepage.needTherapy.title')

  return (
    <Container
      component='section'
      // Fit content to lower viewport on mobile
      py={6}
      minHeight={{ xs: 400, md: 'auto' }}
      textAlign={{ xs: 'center', md: 'left' }}
      innerProps={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1200,
        justifyContent: 'center',
        position: 'relative',
      }}
      background={'second'}
      position={'relative'}
    >
      <Grid container alignItems='center' justifyContent='space-between' spacing={2}>
        <Grid item xs={12} md={6}>
          <Box textAlign={{ xs: 'center', md: 'left' }}>
            <Box maxWidth={{ xs: 'none', md: 543 }}>
              <Typography variant='h3' component='h2'>
                <div dangerouslySetInnerHTML={{ __html: h1 }} />
              </Typography>
            </Box>
            <Box my={3} maxWidth={500} mx={{ xs: 'auto', md: 0 }} color='#665E52' mt={{ sm: '2rem' }}>
              <Typography variant='subtitle2' color='inherit'>
                {t('terapio:homepage.needTherapy.description')}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box display={{ xs: 'none', md: 'block' }}></Box>
        </Grid>
      </Grid>
    </Container>
  )
}
export default NeedTherapy
