import { ReactNode } from 'react'

import Box from '@base/Box'

import { Step } from '../TutorModal'

type Props = {
  step: Step
  children: ReactNode
}

const InfoWrapper = ({ step, children }: Props) => {
  return (
    <Box
      minWidth={{ xs: 0, md: 450 }}
      maxHeight={{ md: 728 }}
      bgcolor={step > Step.Info ? '#F5F7FA' : '#fff'}
      overflow='auto'
    >
      <Box
        key={step}
        position='relative'
        display='flex'
        flexDirection='column'
        width={450}
        maxWidth='100%'
        height={{ md: 'auto' }}
        overflow='auto'
      >
        {children}
      </Box>
    </Box>
  )
}

export default InfoWrapper
