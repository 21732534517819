// https://github.com/juliangruber/is-mobile/

import { IncomingMessage } from 'http'

const mobileRE = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i
const tabletRE = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino|android|ipad|playbook|silk/i

const isMobile = (opts?: { req?: IncomingMessage; includeTablets?: boolean }): boolean => {
  if (!opts) opts = {}
  const req = opts.req
  let ua: string
  if (!req && typeof navigator !== 'undefined') ua = navigator.userAgent
  if (req && req.headers && typeof req.headers['user-agent'] === 'string') {
    ua = req.headers['user-agent']
  }
  if (typeof ua !== 'string') return false

  return opts.includeTablets ? tabletRE.test(ua) : mobileRE.test(ua)
}

export const isTablet = (opts?: { req?: IncomingMessage }): boolean => {
  const req = opts ? opts.req : undefined
  return isMobile({ req, includeTablets: true }) && !isMobile({ req })
}

export default isMobile
