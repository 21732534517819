import StepButton from '@material-ui/core/StepButton'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Step, { StepProps } from '@material-ui/core/Step'
import { makeStyles, Theme } from '@material-ui/core'
import { MutableRefObject, ReactNode } from 'react'

type Props = StepProps & {
  isCompleted: boolean
  hasError: boolean
  children: ReactNode
  onActivate: () => void
  title: ReactNode
  stepRef?: MutableRefObject<null>
}

const useStyles = makeStyles(({ palette, typography }: Theme) => ({
  label: {
    fontSize: typography.body1.fontSize,
    fontWeight: 600,
    '&$labelCompleted': {
      color: palette.customCol.jungle,
      fontWeight: 600,
    },
    '&$labelActive': {
      fontWeight: 600,
    },
  },
  labelCompleted: {},
  labelActive: {},
  icon: {
    '&$iconCompleted': {
      color: palette.customCol.jungle,
    },
  },
  iconCompleted: {},
}))

const TestStep = ({ hasError, isCompleted, children, onActivate, title, stepRef, ...props }: Props) => {
  const classes = useStyles({})

  return (
    <Step ref={stepRef} {...props}>
      <StepButton onClick={onActivate} completed={isCompleted}>
        <StepLabel
          classes={{ active: classes.labelActive, label: classes.label, completed: classes.labelCompleted }}
          error={hasError}
          StepIconProps={{ classes: { root: classes.icon, completed: classes.iconCompleted } }}
        >
          {title}
        </StepLabel>
      </StepButton>
      <StepContent>{children}</StepContent>
    </Step>
  )
}

export default TestStep
