import { useEffect, useState } from 'react'
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { connect, useSelector } from 'react-redux'
import getConfig from 'next/config'
import { bindActionCreators } from 'redux'
import Dialog from '@base/Dialog'
import NetworkError from '@base/NetworkError'
import Loading from '@base/Loading'
import Box from '@base/Box'
import Typography from '@base/Typography'
import createBroadcastChannel from '@helpers/broadcastChannel'
import { useTranslation } from '@src/i18n'
import { AppState } from '@store/Types'
import { userOperations, userSelectors } from '@store/modules/User'
import { GetUserNameQuery } from '@gql/__generated__'
import TodoList, { TodoItem } from '@base/TodoList'
import getLogger from '@src/Logger'
import ResendEmail from './updateProfileModal/ResendEmail'
import Button from '@base/Button'
import { snackbarOperations } from '@store/modules/Snackbar'
import links from '@api/links'

import QUERY from './updateProfileModal/Query.api'

const { VERIFICATION } = getConfig().publicRuntimeConfig

type Props = {
  onClose?: () => void
  onResolve?: () => void
}

type ReduxDispatchProps = ReturnType<typeof mapDispatchToProps>

const UpdateProfileModal = ({ onClose, onResolve, onLogoutUser }: Props & ReduxDispatchProps) => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const id = useSelector<AppState, string>(state => userSelectors.getUserId(userSelectors.getSelf(state)))
  const { data, networkStatus, error, refetch } = useQuery<GetUserNameQuery>(QUERY, {
    skip: !id,
    fetchPolicy: 'cache-and-network',
  })
  const [isRefetched, setRefetched] = useState(false)
  const isVerified = VERIFICATION ? data?.user?.verified : true
  // do not let user through if network error, but alow them through if gqlError
  const isNetworkError = !!error?.networkError
  const shouldResolve =
    isVerified || (isVerified === undefined && networkStatus !== 1 && !isNetworkError) || (error && !isNetworkError)
  useEffect(() => {
    if (!shouldResolve) return
    onResolve?.()
  }, [shouldResolve])

  const handleUpdate = () => {
    getLogger().info('Email verification - Broadcast message update received')
    refetch().catch(err => {
      getLogger().error({ err }, 'Email verification refetch failed')
      window.location.reload() // hard reload if refetch fails
    })
  }

  useEffect(() => {
    const channel = createBroadcastChannel<string>('confirmEmail')
    channel.addEventListener('message', handleUpdate)
    return () => {
      channel.removeEventListener('message', handleUpdate)
      channel.close().catch(err => getLogger().error({ err }, 'Closing confirmEmail channel failed'))
    }
  }, [])

  const handleRefetch = () => {
    refetch()
      .then(() => {
        setRefetched(true)
        return
      })
      .catch(err => {
        getLogger().error({ err }, 'Refetch user verified failed')
      })
  }

  const handleLogout = () => {
    const onReload = () => {
      const signUpLink = links.signup
      window.location.href = signUpLink.as.pathname
    }
    onLogoutUser(client, onReload).catch(err => {
      getLogger().error({ err }, 'Logout error on UpdateProfileModal')
    })
  }

  if (shouldResolve) return null

  return (
    !isVerified && (
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onClose={onClose}
        open={true}
        data-testid='updateProfileModal'
        closeBtn={false}
        aria-labelledby={t('updateProfile.modal.email.title')}
        aria-describedby={t('updateProfile.modal.email.info')}
      >
        <Box
          px={{ xs: 2, sm: 4 }}
          py={{ xs: 4, sm: 6 }}
          display='flex'
          flexDirection='column'
          maxWidth={500}
          justifyContent='center'
          textAlign='center'
        >
          {networkStatus === 1 ? (
            <Loading />
          ) : isNetworkError ? (
            <NetworkError />
          ) : (
            <>
              <Box mt={1} textAlign={{ xs: 'center', md: 'left' }}>
                <TodoList>
                  {VERIFICATION && (
                    <TodoItem
                      completed={isVerified}
                      boxProps={{ mb: 3, color: isVerified ? '#bbb' : 'inherit' }}
                      size='small'
                    >
                      <Typography variant='h6'>{t('updateProfile.modal.email.title')}</Typography>
                      <Box mb={1}>
                        <Typography>{t('updateProfile.modal.email.info')}</Typography>
                      </Box>
                      {!isVerified && <ResendEmail refetch={handleRefetch} />}
                      {isRefetched && !isVerified && networkStatus !== 4 && (
                        <Typography component='div' variant='caption' weight={600}>
                          {t('updateProfile.modal.email.refetchMessage')}
                        </Typography>
                      )}
                      {onLogoutUser && (
                        <Box mt={2}>
                          <Button color='primary' variant='contained' size='small' onClick={handleLogout}>
                            {t('updateProfile.modal.email.logout')}
                          </Button>
                        </Box>
                      )}
                    </TodoItem>
                  )}
                </TodoList>
              </Box>
            </>
          )}
        </Box>
      </Dialog>
    )
  )
}

const mapStateToProps = (state, ownProps): Props => ({
  onClose: ownProps.onClose,
  onResolve: ownProps.onResolve,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onLogoutUser: userOperations.logout,
      openSnackbar: snackbarOperations.open,
    },
    dispatch,
  )

const UpdateProfileModalMapped = connect(mapStateToProps, mapDispatchToProps)(UpdateProfileModal)

export default UpdateProfileModalMapped
