import React, { memo, useMemo } from 'react'
import getConfig from 'next/config'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from '@src/i18n'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Avatar from '@base/Avatar'
import Box from '@base/Box'
import Typography from '@base/Typography'
import { getDayName } from '@helpers/dateTime'
import { ShiftDatesAndSlotsQuery, ShiftDatesAndSlotsQueryVariables, TutorDetailFragment } from '@gql/__generated__'
import startIntercom from '@helpers/startIntercom'
import { useQuery } from '@apollo/react-hooks'
import QUERY_SHIFTS from '@tutor/scheduling/AvailableShiftsAndSlotsQuery.api'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import Topics from '@components/therapist/Topics'
import Button from '@base/Button'
import { canReassignTherapist } from '@components/therapist/assign/utils'
import { AppState } from '@store/Types'
import { userSelectors } from '@store/modules/User'
import Loading from '@base/Loading'
import { getSlotsByLocalTimezone } from '@lib/timezoneDate'
import { pickTutorIntro } from '@service/Therapist'

const { CALL_UNIT_LENGTH } = getConfig().publicRuntimeConfig

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  icon: {
    color: '#EDBB2F',
    marginRight: 5,
    marginTop: -3,
  },
  introduction: {
    whiteSpace: 'normal',
    '& p': {
      textAlign: 'justify',
      [breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    '& ul.chips': {
      listStyleType: 'none',
      paddingLeft: '0',

      '& li': {
        display: 'inline-block',
        margin: '5px 5px 5px 0',
        whiteSpace: 'normal',
        padding: '5px 12px',
        borderRadius: '20px',
        backgroundColor: '#FFFAEF',
        color: '#FFB400',
      },
    },
  },
  link: {
    color: palette.text.primary,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  button: {
    margin: '32px 0',
    width: '100%',
  },
}))

type Props = {
  tutor: TutorDetailFragment
  withIntro?: boolean
  withTopics?: boolean
  withPackages?: boolean
  withAvailability?: boolean
  assignButtonCaption: string
  onTherapistPicked?: (therapistId: string) => void
}

const Medallion = memo(
  ({ tutor, withIntro, withTopics, withAvailability, assignButtonCaption, onTherapistPicked }: Props) => {
    const { t, i18n } = useTranslation()

    const classes = useStyles({})
    const { user, topics, id } = tutor
    const tutorIntro = pickTutorIntro(tutor, i18n.language)

    const isLoggedIn = useSelector<AppState, boolean>(state => userSelectors.isLoggedIn(userSelectors.getSelf(state)))
    const isStudent = useSelector<AppState, boolean>(state => userSelectors.isStudent(userSelectors.getSelf(state)))
    const { data, loading } = useQuery<ShiftDatesAndSlotsQuery, ShiftDatesAndSlotsQueryVariables>(QUERY_SHIFTS, {
      variables: {
        tutorId: id,
        duration: CALL_UNIT_LENGTH * 60,
      },
      fetchPolicy: 'network-only',
    })

    const shifts = data?.dates || []
    const sortedShifts = useMemo(() => {
      const sorted = shifts.sort((a, b) => Date.parse(a.start) - Date.parse(b.start))

      return getSlotsByLocalTimezone(sorted)
    }, [shifts])
    const { firstName, lastName, image } = user
    const fullName = `${firstName} ${lastName}`
    const { canReassign, canAssign } = isStudent && canReassignTherapist(id, isLoggedIn)
    const defaultButtonCaption = canReassign
      ? t('dashboard.overlay.assignTherapist.reassign')
      : t('dashboard.overlay.assignTherapist.assign')
    const handleTherapistPicked = (therapistId: string) => {
      if (onTherapistPicked) {
        onTherapistPicked(therapistId)
        return
      }
    }
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return (
      <>
        <Box display='inline-flex' alignItems='center' textAlign='left' flexWrap='nowrap'>
          <Box mr={2} position='relative'>
            <Box width={{ xs: 80, sm: 126 }} height={{ xs: 80, sm: 126 }}>
              <Avatar alt={fullName} src={image} size='100%' />
            </Box>
          </Box>
          <div>
            <Grid container alignItems='center' wrap='nowrap'>
              <Typography variant='subtitle1' color='textSecondary' weight={600}>
                {fullName}
              </Typography>
            </Grid>
          </div>
        </Box>
        {withTopics && topics && topics.length > 0 && (
          <Box maxWidth={360} mt={0.5} display='inline-block'>
            <Box marginLeft={-0.625} mt={0.5}>
              <Topics topics={topics} />
            </Box>
          </Box>
        )}
        {tutorIntro.motto && (
          <Box mt={2} width='100%' maxWidth={360} display='inline-block'>
            <Typography className={classes.introduction} component='div' variant='body2'>
              {tutorIntro.motto}
            </Typography>
          </Box>
        )}
        {withIntro && (
          <Box mt={2} width='100%' maxWidth={360} display='inline-block'>
            <Typography className={classes.introduction} component='div' variant='body2'>
              <div dangerouslySetInnerHTML={{ __html: tutorIntro.introduction }} />
            </Typography>
          </Box>
        )}
        {loading && <Loading />}
        {withAvailability && !loading && (
          <Box mt={2}>
            {sortedShifts.size > 0 ? (
              <>
                <Typography variant='body2' color='textSecondary' weight={600}>
                  {t('homepage.tutorsOnline.status.onlineOn', { name: firstName })}
                  <br />
                  {t('common.timezone')}: {timezone}
                </Typography>
                <TableContainer>
                  <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('homepage.tutorsOnline.status.dates')}</TableCell>
                        <TableCell>{t('homepage.tutorsOnline.status.sessions')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[...sortedShifts.keys()].map((key, index) => {
                        const slots = sortedShifts.get(key)

                        return (
                          <TableRow key={`shift-${index}`}>
                            <TableCell component='th' scope='row'>
                              {t('homepage.tutorsOnline.status.dayAndTime', {
                                dayNameWithPreposition: getDayName(
                                  i18n,
                                  new Date(`${key} ${slots[0]?.tm || '12:00'}`),
                                  {
                                    preposition: false,
                                    dayDate: true,
                                    dayFormat: 'do MMMM',
                                  },
                                ),
                              })}
                            </TableCell>
                            <TableCell>
                              {slots
                                .sort((a, b) => Date.parse(a.tm) - Date.parse(b.tm))
                                .map(({ tm, id }, i) => (
                                  <Chip key={`${id}-${i}`} variant='outlined' size='small' label={tm} />
                                ))}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <>
                {t('homepage.tutorsOnline.noShifts')}{' '}
                <a className={classes.link} onClick={e => startIntercom(e)}>
                  {t('homepage.tutorsOnline.noShiftsIntercom')}
                </a>
                .
              </>
            )}
          </Box>
        )}
        {tutor && canAssign && (
          <Button
            color='primary'
            onClick={() => {
              handleTherapistPicked(tutor.id)
            }}
            size='medium'
            variant='contained'
            className={classes.button}
          >
            {assignButtonCaption ? assignButtonCaption : defaultButtonCaption}
          </Button>
        )}
      </>
    )
  },
)

Medallion.displayName = 'Medallion'

export default Medallion
