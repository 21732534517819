import Box from '@base/Box'
import NetworkError from '@base/NetworkError'

type Props = {
  error: boolean
}

const Error = ({ error }: Props) => {
  if (!error) return null

  return (
    <Box p={2} pr={6}>
      <NetworkError />
    </Box>
  )
}

export default Error
