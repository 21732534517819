import gql from 'graphql-tag'
import Tutor from './TutorFragment.api'

export default gql`
  query getFutureTutors($first: Int = 3) {
    getFutureTutors(pageInfo: { first: $first }) {
      ...Tutor
    }
  }
  ${Tutor}
`
