import { enUS as en, es, cs, pl } from 'date-fns/locale'

import { useTranslation } from '@src/i18n'

const locales = { en, es, cs, pl }

const useDateFnsLocale = () => {
  const { i18n } = useTranslation()
  return locales[i18n.languages[0]] || en
}

export default useDateFnsLocale
