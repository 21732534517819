import { useState, useEffect } from 'react'

import { detectBrowser, isVideoCallSupported } from '@helpers/browser'

const useBrowserSupport = () => {
  const [browser, setBrowser] = useState(null)
  const [isSupported, setSupported] = useState(null)

  useEffect(() => {
    setSupported(isVideoCallSupported())
    setBrowser(detectBrowser())
  }, [])

  if (isSupported === null || isSupported === true) {
    return null
  }

  return browser && browser.name
}

export default useBrowserSupport
