import { DataProxy } from 'apollo-cache'

/**
 * Read query defensively from Apollo cache. Return undefined if such query doesn't exist
 * (https://github.com/apollographql/apollo-feature-requests/issues/1)
 */
export const readQueryIfAny = <QueryType, TVariables = any>(
  proxy: DataProxy,
  options: DataProxy.Query<TVariables>,
  optimistic?: boolean,
): QueryType | null | undefined => {
  try {
    return proxy.readQuery<QueryType, TVariables>(options, optimistic)
  } catch {
    return undefined
  }
}
