import cx from 'classnames'
import Grid from '@material-ui/core/Grid'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { useTranslation } from '@src/i18n'
import Typography from '@base/Typography'
import Box from '@base/Box'
import Container from '@base/Container'
import hearth from './whyOnline/hearth.svg'
import home from './whyOnline/home.svg'
import mail from './whyOnline/mail.svg'
import online from './whyOnline/online.svg'

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  container: {
    flexWrap: 'wrap',
    [breakpoints.up('md')]: {
      flexWrap: 'no-wrap',
    },
  },
  item: {
    width: '100%',
    marginTop: '4rem',
    position: 'relative',
    '& h6': {
      marginLeft: 0,
      marginTop: '1rem',
    },
    '& p': {
      width: '90%',
      margin: '1rem auto',
    },
    [breakpoints.up('md')]: {
      maxWidth: '25%',
    },
  },
  background: {
    height: '6rem',
    backgroundImage: `url('${hearth}')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundSize: 'contain',
  },
  hearth: {
    backgroundImage: `url('${hearth}')`,
  },
  home: {
    backgroundImage: `url('${home}')`,
  },
  mail: {
    backgroundImage: `url('${mail}')`,
  },
  online: {
    backgroundImage: `url('${online}')`,
  },
}))

const WhyOnline = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const h1 = t('terapio:homepage.whyOnline.title')

  return (
    <Container
      component='section'
      // Fit content to lower viewport on mobile
      py={6}
      innerProps={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1200,
        justifyContent: 'center',
        position: 'relative',
      }}
      position={'relative'}
    >
      <Box textAlign={'center'}>
        <Typography variant='h3' component='h2'>
          <div dangerouslySetInnerHTML={{ __html: h1 }} />
        </Typography>
      </Box>
      <Grid container alignItems='flex-start' justifyContent='space-between' className={classes.container}>
        <Box className={classes.item} textAlign={'center'}>
          <div className={cx(classes.background, classes.hearth)} />
          <Typography variant='h6' color='inherit'>
            {t('terapio:homepage.whyOnline.box1.title')}
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            {t('terapio:homepage.whyOnline.box1.description')}
          </Typography>
        </Box>
        <Box className={classes.item} textAlign={'center'}>
          <div className={cx(classes.background, classes.home)} />
          <Typography variant='h6' color='inherit'>
            {t('terapio:homepage.whyOnline.box2.title')}
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            {t('terapio:homepage.whyOnline.box2.description')}
          </Typography>
        </Box>
        <Box className={classes.item} textAlign={'center'}>
          <div className={cx(classes.background, classes.mail)} />
          <Typography variant='h6' color='inherit'>
            {t('terapio:homepage.whyOnline.box3.title')}
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            {t('terapio:homepage.whyOnline.box3.description')}
          </Typography>
        </Box>
        <Box className={classes.item} textAlign={'center'}>
          <div className={cx(classes.background, classes.online)} />
          <Typography variant='h6' color='inherit'>
            {t('terapio:homepage.whyOnline.box4.title')}
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            {t('terapio:homepage.whyOnline.box4.description')}
          </Typography>
        </Box>
      </Grid>
    </Container>
  )
}
export default WhyOnline
