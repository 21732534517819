import ProgressButton from '@base/ProgressButton'
import { useTranslation } from '@src/i18n'

type Props = {
  goToNextStep: () => void
}

const ContinueButton = ({ goToNextStep }: Props) => {
  const { t } = useTranslation()

  return (
    <ProgressButton
      inProgress={false}
      fullWidth
      color='primary'
      variant='contained'
      onClick={goToNextStep}
      data-testid='continue'
    >
      {t('callInvite.modalSteps.Details.button')}
    </ProgressButton>
  )
}

export default ContinueButton
