import gql from 'graphql-tag'

export default gql`
  query CompanyCoverage($groupId: String!, $userId: String!) {
    group: getGroup(groupId: $groupId) {
      id
      name
      logo
    }
    groupStudent: getGroupStudent(userId: $userId, groupId: $groupId) {
      id
      wallet {
        id
        options {
          coverage
        }
      }
    }
  }
`
