import gql from 'graphql-tag'

import SentInvite from '@components/invite/sentInvites/SentInviteFragment.api'

const TutorModalSendScheduledInvite = gql`
  mutation TutorModalSendScheduledInvite($scheduledCallId: String!) {
    invite: sendInviteToScheduledCall(scheduledCallId: $scheduledCallId) {
      ...SentInvite
    }
  }

  ${SentInvite}
`

export default TutorModalSendScheduledInvite
