import { ComponentProps } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'

import Box from '@base/Box'
import Typography from '@base/Typography'
import { useTranslation } from '@src/i18n'
import { CompanyCoverageQuery, CompanyCoverageQueryVariables } from '@gql/__generated__'
import { AppState } from '@store/Types'
import { userSelectors } from '@store/modules/User'

import QUERY from './coverage/Query.api'
import Logo from './Logo'

type Props = {
  boxProps?: ComponentProps<typeof Box>
}

const Coverage = ({ boxProps }: Props) => {
  const { t } = useTranslation()
  const userId = useSelector<AppState, string>(state => userSelectors.getUserId(userSelectors.getSelf(state)))
  const groupId = useSelector<AppState, string>(state => userSelectors.getGroupId(userSelectors.getSelf(state)))

  const { data: { group = null, groupStudent = null } = {} } = useQuery<CompanyCoverageQuery, CompanyCoverageQueryVariables>(
    QUERY,
    {
      fetchPolicy: 'cache-and-network',
      skip: !userId || !groupId,
      variables: {
        groupId,
        userId,
      },
    },
  )
  const { name, logo } = group || {}
  const { coverage = null } = groupStudent?.wallet?.options || {}

  if (!userId || !groupId || coverage == null) return null

  return (
    <Box display='flex' alignItems='center' justifyContent='center' {...boxProps}>
      <Typography weight={600}>{t('userCompany.coverage', { percent: coverage })}</Typography>
      <Box ml={2}>
        <Logo src={logo} name={name} avatarProps={{ size: 40 }} />
      </Box>
    </Box>
  )
}

export default Coverage
