import { TutorAvatarFragment } from '@gql/__generated__'
import Box from '@base/Box'
import TutorAvatar from '@common/TutorAvatar'
import { RequireAtLeastOne } from '@lib/typeUtils'

import TutorSelect from './pickTutor/TutorSelect'

type BaseProps = {
  tutor?: TutorAvatarFragment
  selected?: boolean
  lessonId?: string
  onSelected?: (tutor: TutorAvatarFragment) => void
}

type Props = RequireAtLeastOne<BaseProps, 'lessonId' | 'tutor'>

const PickTutor = ({ tutor, selected, onSelected, lessonId }: Props) => {
  if (selected) {
    return (
      <Box display='flex'>
        <TutorAvatar tutor={tutor} showStatus={false} />
      </Box>
    )
  }

  return <TutorSelect lessonId={lessonId} onSelected={onSelected} />
}

export default PickTutor
