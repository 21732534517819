import gql from 'graphql-tag'

import TutorItem from '@gql/fragments/TutorItemFragment.api'
import TutorPackages from '@gql/fragments/TutorPackagesFragment.api'

const TutorDetail = gql`
  fragment TutorDetail on Tutor {
    ...TutorItem
    rating
    prices {
      id
      type
      price
    }
    shifts {
      start
      end
    }
    ...TutorPackages
  }

  ${TutorItem}
  ${TutorPackages}
`

export default TutorDetail
