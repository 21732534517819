import { useState } from 'react'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import getConfig from 'next/config'
import ProgressButton from '@base/ProgressButton'
import { useTranslation } from '@src/i18n'
import {
  TutorModalSendInviteMutation,
  TutorModalSendInviteMutationVariables,
  TutorModalSendScheduledInviteMutation,
  TutorModalSendScheduledInviteMutationVariables,
} from '@gql/__generated__'
import { ScheduledCall, TutorModalPreviewByIdQuery } from '@gql/__generated__'
import { setInvitePending } from '@components/invite/InvitesResolver'
import analytics from '@src/Analytics'
import { apiErrorToCode } from '@helpers/api'
import SEND_INVITE from './sendinviteButton/SendInviteMutation.api'
import SEND_SCHEDULED_INVITE from './sendinviteButton/SendScheduledInviteMutation.api'
const { CALL_UNIT_LENGTH } = getConfig().publicRuntimeConfig

type Props = {
  tutor: TutorModalPreviewByIdQuery['tutor']
  duration: number
  lessonId: string
  onClose: () => void
  setInviteError: (e: string) => void
  refetch: () => void
  scheduledCall: Pick<ScheduledCall, 'id'> | null
}

const SendInviteButton = ({ tutor, duration, lessonId, onClose, setInviteError, scheduledCall, refetch }: Props) => {
  const { t } = useTranslation()
  const [sending, setSending] = useState(false)
  const client = useApolloClient()
  const [sendInviteMutation] = useMutation<TutorModalSendInviteMutation, TutorModalSendInviteMutationVariables>(SEND_INVITE)
  const [sendScheduledInviteMutation] = useMutation<
    TutorModalSendScheduledInviteMutation,
    TutorModalSendScheduledInviteMutationVariables
  >(SEND_SCHEDULED_INVITE)

  const sendInvite = async () => {
    if (scheduledCall) {
      return sendScheduledInviteMutation({
        variables: {
          scheduledCallId: scheduledCall.id,
        },
      })
    }

    return sendInviteMutation({
      variables: {
        tutorId: tutor.id,
        duration: duration / CALL_UNIT_LENGTH, // TODO duration wrong units
        lessonId,
      },
    })
  }

  const handleInvite = () => {
    if (sending) return
    setSending(true)
    sendInvite()
      // eslint-disable-next-line promise/always-return
      .then(mutationResult => {
        const {
          data: { invite: sentInvite },
        } = mutationResult

        setInvitePending(client, sentInvite.roomId)
        analytics.pushEvent('invite/sent', { tutorName: tutor.user.firstName })
        // We "should" call setSending(false) here, but it isn't necessary, because modal is closing in next step
        onClose()
      })
      .catch(err => {
        const errorCode = apiErrorToCode(err) || 'NETWORK_ERROR'
        setInviteError(errorCode)
        setSending(false)
        // Refetch tutor item to update tutor's state (refetching tutor modal not needed, because it's closed on error)
        if (errorCode === 'TUTOR_BUSY') {
          refetch()
        }
      })
  }

  return (
    <ProgressButton
      inProgress={sending}
      fullWidth
      color='primary'
      variant='contained'
      onClick={handleInvite}
      data-testid='sentInvite'
    >
      {t('callInvite.modalSteps.Summary.button')}
    </ProgressButton>
  )
}

export default SendInviteButton
