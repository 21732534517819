import gql from 'graphql-tag'

import StudentOrTutorScheduledCall from '../scheduledCall/ScheduledCallFragment.api'

const StudentScheduledCalls = gql`
  query StudentScheduledCalls($isStudent: Boolean!, $showBooked: Boolean!) {
    scheduledCalls: getScheduledCalls(showBooked: $showBooked) {
      ...StudentOrTutorScheduledCall
    }
  }

  ${StudentOrTutorScheduledCall}
`

export default StudentScheduledCalls
